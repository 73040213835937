import { memoize } from "@ctra/utils";
import { Ajinomoto } from "@ctra/components";

import { GenericInsightBase } from "./Base";
import { AjinomotoHighRiskCowsInsightBody } from "../insight-body";
import { AjinomotoHighRiskCowsInsightValidation } from "../insight-validation";

/**
 * High risk cows insight
 */
class AjinomotoHighRiskCowsInsight extends GenericInsightBase {
  @memoize
  getComponents(): ReturnType<typeof GenericInsightBase.prototype.getComponents> {
    return {
      ...super.getComponents(),
      Icon: Ajinomoto,
      ListIcon: Ajinomoto,
      Body: AjinomotoHighRiskCowsInsightBody,
      Validation: AjinomotoHighRiskCowsInsightValidation
    };
  }
}

export { AjinomotoHighRiskCowsInsight };
