import { FC } from "react";

import { Typography, Row, Col, DownloadOutlined, Button } from "@ctra/components";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { Enterprise, EnterpriseAppState, GenericInsightEntity, Insights } from "@ctra/api";
import { AjinomotoHighRiskCowsInsight, InsightListContext, testIds, useInsight } from "@insights";

import { HighRiskCowTable } from "./components";
import styles from "./AjinomotoHighRiskCowList.module.less";
import { useSelector } from "react-redux";
import { Nullable } from "@ctra/utils";
import { CSVLink } from "react-csv";
import * as _ from "lodash";

const { Paragraph, Text } = Typography;

/**
 * high risk cow list
 * @return {React.ReactNode}
 * @constructor
 */
export const AjinomotoHighRiskCowList: FC = () => {
  const { t } = useTranslation();

  const {
    insight: { id }
  } = useInsight<AjinomotoHighRiskCowsInsight>();

  const {
    insights: {
      extensions: {
        highRiskCows: { title, description, exportLabel }
      }
    }
  } = Content;

  /**
   * Query for pulling child insights
   * @type {{filter: {and: {parentGenericInsightID: number}}, expand: Array<string>, orderBy: string}}
   */
  const query = {
    orderBy: "endEpoch desc",
    expand: Insights.presets.expandDefaults(),
    filter: {
      and: {
        parentGenericInsightID: id
      }
    }
  };

  /**
   * Pick the insight list (may be null until is is loaded from the server)
   * @type {Nullable<Array<GenericInsightEntity>>}
   */
  const insightList = useSelector<EnterpriseAppState, Nullable<Array<GenericInsightEntity>>>((state) =>
    Enterprise.entities.getInsightList(state, { list: `highRiskCows-${id}` })
  );

  return (
    <div data-testid={testIds.extensions.highRiskCowList.wrapper}>
      <Row justify="space-between">
        <Col>
          <Paragraph className={styles.NoMargin}>
            <Text strong>{t<string>(title)}</Text>
            <br />
            <Text>
              {t<string>(description, {
                cowCount: _.defaultTo(insightList?.length, null)
              })}
            </Text>
          </Paragraph>
        </Col>
        <Col>
          {insightList && insightList.length ? (
            <CSVLink
              separator=";"
              className="ant-btn"
              role="button"
              filename={`highRiskCows-${id}.csv`}
              data={_.map(insightList as NonNullable<Array<GenericInsightEntity>>, ({ metadata }) =>
                _.mapValues(metadata, (v) => (_.isPlainObject(v) ? JSON.stringify(v) : v))
              )}
            >
              <DownloadOutlined />
              <span>{t<string>(exportLabel)}</span>
            </CSVLink>
          ) : (
            <Button disabled icon={<DownloadOutlined />}>
              {t<string>(exportLabel)}
            </Button>
          )}
        </Col>
      </Row>
      <InsightListContext.Provider query={query} list={`highRiskCows-${id}`}>
        <HighRiskCowTable />
      </InsightListContext.Provider>
    </div>
  );
};
