import * as _ from "lodash";

import { memoize, Nullable } from "@ctra/utils";

import { LineSource, TableData } from "../typings";
import { Series } from "../";
import { XAxisType } from "../xAxis";
import { DataPointsBase } from "./DataPointsBase";

/**
 * Normalizer class for data table
 */
class TableNormalizer extends DataPointsBase {
  protected entity: LineSource;

  constructor(source: LineSource) {
    super(source);
    this.entity = source;
  }
  /**
   * Process the incoming table data
   */
  @memoize
  formatData(): TableData["data"] {
    const {
      meta: { xType }
    } = this.entity;

    const groupByKey = `${xType}`;

    /**
     * Group the data by date and set each column data as a key in each group
     * // { "22-10-2022": {date: "22-10-2022", series-a: 4.2, series-b: 5.1 },
     *      "23-10-2022": {date: "23-10-2022", series-a: 5.2, series-b: 5.7 }
     *    }
     *
     * and then covert it to an array
     *
     * // [
     *      { date: "22-10-2022", farm-a: 4.2, farm-b: 5.1 },
     *      { date: "23-12-2022", farm-a: 5.2, farm-b: 5.7 }
     *    ]
     */
    const data = _.toArray(
      _.reduce<LineSource["data"], Record<string, Record<string, Nullable<string | number | string[]>>>>(
        this.entity.data,
        (acc, item, key) => {
          _.forEach(item.points, (point) => {
            const { x, y } = point;
            const series = Series.create(key, this.entity.meta.seriesType);
            const seriesKey = series.getField();
            const value = _.isNumber(y) ? _.round(y, 2) : y;

            if (_.isString(seriesKey)) {
              acc[x] = acc[x]
                ? { ...acc[x], [seriesKey]: value }
                : { [seriesKey]: value, [groupByKey]: [x], key: x };
            }
          });

          return acc;
        },
        {}
      )
    );

    const axisType = XAxisType.create(this.entity.meta.xType);

    return axisType.formatTableData(data);
  }

  /**
   * Process the incoming table metadata
   */
  @memoize
  formatMetadata(): TableData["meta"] {
    const {
      meta: { xType }
    } = this.entity;

    const firstColumn = { key: xType, title: _.capitalize(xType), dataIndex: xType };

    /**
     * Setup the table columns
     * // [
     *      {key: date, title: date, dataIndex: date },
     *      { key: farm-a, title: farm-a, dataIndex: farm-a }
     *    ...]
     */
    const columns = _.reduce<LineSource["data"], Array<Record<string, unknown>>>(
      this.entity.data,
      (acc, item, itemKey) => {
        const series = Series.create(itemKey, this.entity.meta.seriesType);
        const key = series.getField();

        return [
          ...acc,
          {
            key,
            title: series.getField(),
            dataIndex: key
          }
        ];
      },
      [firstColumn]
    );

    /**
     * Setup the table key rows. This will contain a key map to be translated at the app level
     * // { "2022-10-10": "2022-10-10", "2022-10-11": "2022-10-11" }
     */
    const keyRows = _.reduce<LineSource["data"], Record<string, string>>(
      this.entity.data,
      (acc, item) => {
        _.forEach(item.points, (point) => {
          _.setWith(acc, [point.x], point.x, Object);
        });

        return acc;
      },
      {}
    );

    return { ...super.formatMetadata(), columns, keyRows };
  }
}

export { TableNormalizer };
