import * as _ from "lodash";
import { combineEpics, ofType, Epic } from "redux-observable";
import { takeUntil } from "rxjs/operators";

/**
 * @todo import new epics here
 */
import { epics as authEpics } from "../modules/auth";
import { epics as farmEpics } from "../modules/farms";
import { epics as userEpics } from "../modules/user";
import { epics as dataDictionaryEpics } from "../modules/data-dictionary";
import { epics as layoutEpics } from "../modules/layouts";
import { epics as chartEpics } from "../modules/chart";
import { epics as scorecardEpics } from "../modules/scorecard";
import { epics as insightEpics } from "../modules/insights";
import { epics as preferencesEpics } from "../modules/preferences";
import { epics as sessionEpics } from "../modules/session";
import { epics as eventsEpics } from "../modules/events";
import { epics as labelsEpics } from "../modules/labels";
import { epics as savedChartEpics } from "../modules/saved-cards";
import { epics as impactTrackingEpics } from "../modules/impact-tracking";
import { epics as invitationEpics } from "../modules/invitation";
import { epics as signupEpics } from "../modules/signup";
import { epics as farmSummaryEpics } from "../modules/farm-summaries";
import { epics as iofcEpics } from "../modules/iofc";
import { epics as genericEpics } from "../modules/generic";

/**
 * @todo add new epics here
 */
const epics = {
  ...authEpics,
  ...farmEpics,
  ...userEpics,
  ...dataDictionaryEpics,
  ...layoutEpics,
  ...chartEpics,
  ...scorecardEpics,
  ...insightEpics,
  ...preferencesEpics,
  ...sessionEpics,
  ...eventsEpics,
  ...labelsEpics,
  ...savedChartEpics,
  ...impactTrackingEpics,
  ...invitationEpics,
  ...signupEpics,
  ...farmSummaryEpics,
  ...iofcEpics,
  ...genericEpics
};

/**
 * Combine all the imported epics into one observable stream
 */
const combinedEpics: Epic = combineEpics(..._.values(epics));

/**
 * @param action$
 * @param state$
 * @param rest
 */
const rootEpic: Epic = (action$, state$, ...rest) =>
  combinedEpics(action$, state$, ...rest).pipe(takeUntil(action$.pipe(ofType("END"))));

export default rootEpic;
