import { FC } from "react";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { XYChart, Axis, Grid, LineSeries } from "@visx/xychart";
import { scaleOrdinal } from "@visx/scale";
import { Legend, LegendItem, LegendLabel } from "@visx/legend";
import { MarkerCircle } from "@visx/marker";
import { Group } from "@visx/group";

import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { Charts as ChartUI, Row, Col } from "@ctra/components";
import { TS, Units } from "@ctra/utils";
import { DairyCowEntity, Enterprise, EnterpriseAppState } from "@ctra/api";

import { LelyMilkDropInsight, useInsight, testIds } from "@insights";
import styles from "./LelyMilkDropChart.module.less";

const { Chart } = ChartUI;

/**
 * Custom chart for Lely milk drop insights
 * @todo clean this up as this is (according to agreement) an alpha components
 * @constructor
 */
export const LelyMilkDropChart: FC = () => {
  const { t } = useTranslation();

  const {
    chart: { title: chartTitle, subtitle, description: chartDescription },
    unitSystem: { unit: tUnit }
  } = Content;

  const {
    getMetadata,
    insight: { insightType, dairyCow: dairyCowID }
  } = useInsight<LelyMilkDropInsight>();

  const {
    x,
    y: [line]
  } = getMetadata();

  const { cowTagNumber } = useSelector<EnterpriseAppState, DairyCowEntity>(
    (state) => Enterprise.entities.getDairyCow(state, { id: dairyCowID }) as DairyCowEntity
  );

  /**
   * Data accessors for the line series
   */
  // istanbul ignore next - used by the graph, not to be tested
  const accessors = {
    xAccessor: ([x]: [string, number]) => TS.asMoment(x).format("D-MMM-YYYY"),
    yAccessor: ([, y]: [string, number]) => y
  };

  /**
   * Zip the X and Y data points together
   */
  const data = _.filter(_.zip(x, line.values) as Array<[string, number]>, ([, value]) => !_.isNull(value));

  /**
   * Mongo charts blue
   */
  const fillColor = "#196EE5";

  /**
   * Grid stroke color
   */
  const gridColor = "#DDDDDD";

  /**
   * Legend data
   */
  const legendScale = scaleOrdinal<string, string>({
    domain: [`#${cowTagNumber}`],
    range: [`#${cowTagNumber}`]
  });

  return (
    <Chart
      title={t<string>(chartTitle(insightType), { makeDefaultValue: true })}
      subtitle={t<string>(subtitle(insightType), { cowTagNumber })}
      description={t<string>(chartDescription(insightType))}
      data-testid={testIds.extensions.milkDropChart}
    >
      <Row className={styles.Chart} wrap={false} gutter={[16, 0]}>
        <Col flex={1}>
          <XYChart
            margin={{
              top: 16,
              right: 0,
              bottom: 32,
              left: 48
            }}
            height={300}
            xScale={{ type: "band" }}
            yScale={{
              type: "linear"
            }}
          >
            <Axis
              hideTicks
              stroke={gridColor}
              tickValues={[]}
              tickClassName={styles.Tick}
              orientation="top"
              strokeWidth={1}
            />
            <Axis
              hideTicks
              stroke={gridColor}
              tickValues={[]}
              tickClassName={styles.Tick}
              orientation="right"
            />
            <Axis
              stroke={gridColor}
              tickClassName={styles.Tick}
              orientation="bottom"
              strokeWidth={1}
              tickFormat={
                // istanbul ignore next - used by the graph, not to be tested
                (label, idx) => (idx % 2 ? "" : label)
              }
            />
            <Axis
              stroke={gridColor}
              tickClassName={styles.Tick}
              orientation="left"
              tickFormat={
                // istanbul ignore next - used by the graph, not to be tested
                (label, idx) => (idx % 2 ? "" : Units.format(label, "kg", t<string>(tUnit("kg"))))
              }
            />
            <Grid lineStyle={{ stroke: gridColor }} strokeWidth={1} numTicks={6} />
            <Group key="line-series">
              <MarkerCircle id="marker-circle" fill={fillColor} size={2} refX={2} />
              <LineSeries
                markerStart="url(#marker-circle)"
                markerMid="url(#marker-circle)"
                markerEnd="url(#marker-circle)"
                dataKey={line.lineName}
                data={data}
                stroke={fillColor}
                {...accessors}
              />
            </Group>
          </XYChart>
        </Col>
        <Col>
          <Legend scale={legendScale}>
            {(labels) =>
              labels.map(({ value }) => (
                <LegendItem key={value} className={styles.LegendItem}>
                  <svg width={8} height={8}>
                    <circle fill={fillColor} r={4} cx={4} cy={4} />
                  </svg>
                  <LegendLabel>{value}</LegendLabel>
                </LegendItem>
              ))
            }
          </Legend>
        </Col>
      </Row>
    </Chart>
  );
};
