import { AsyncType, createAsyncTypes } from "@ctra/utils";

const ns = "app/farms";

/**
 * Fetch farm list (for Ida user or Ida Enterprise user)
 * @type {AsyncType}
 * @category Type
 */
const FETCH_FARM_LIST: AsyncType = createAsyncTypes(ns, "FETCH_FARM_LIST");

/**
 * Fetch the farm the user is registered with
 * @type {AsyncType}
 */
const FETCH_USER_FARM: AsyncType = createAsyncTypes(ns, "FETCH_USER_FARM");

/**
 * Update farm details
 * @type {AsyncType}
 * @category Type
 */
const UPDATE_USER_FARM: AsyncType = createAsyncTypes(ns, "UPDATE_USER_FARM");

export default {
  FETCH_FARM_LIST,
  FETCH_USER_FARM,
  UPDATE_USER_FARM
};
