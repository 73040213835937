import GA from "react-ga4";
import * as _ from "lodash";

type IntercomType = (
  action: string,
  options?: string | Record<string, unknown> | (() => void),
  meta?: Record<string, unknown>
) => void;

declare global {
  interface Window {
    Intercom: IntercomType;
  }
}

/**
 * HOF for tracking events
 * @param {string} category
 * @return {(action: string, eventOptions?: {label?: string, value?: number, nonInteraction?: boolean}, eventParams?: Record<string, unknown>) => void}
 */
export const makeTrackEvent =
  (
    category: string
  ): ((
    action: string,
    eventOptions?: { label?: string; value?: number; nonInteraction?: boolean; category?: string },
    eventParams?: Record<string, unknown>
  ) => void) =>
  (action: string, eventOptions = {}, eventParams = {}) => {
    const cat = _.defaultTo(category || eventOptions.category, "Uncategorized");

    if (_.isFunction(_.get(window, ["Intercom"]))) {
      window.Intercom("trackEvent", `${cat} / ${action}`, {
        ..._.omit(eventOptions, "category"),
        ...eventParams
      });
    }

    GA.event(
      { category: cat, action, ..._.omit(eventOptions, "category") },
      { event_category: cat, eventParams }
    );
  };
