import { FC, lazy, Suspense } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";

import { Enterprise, useTranslation } from "@ctra/i18n";
import { GAContext } from "@ctra/analytics";
import { Breadcrumbs } from "@ctra/api";

import { CtraLayout, Row, Col, Grid, Spin, LoadingOutlined } from "@ctra/components";

import { Routes } from "@routes";
import { UserPreferences, UserPreferencesVariant } from "@base";
import { GACategories as InsightsGACategories } from "@insights";
import { InsightNotificationContext, GACategories } from "@settings";

import { SettingsMenu } from "../SettingsMenu";
import * as Sentry from "@sentry/react";

const Insights = lazy(() => import("../Insights"));
const Notifications = lazy(() => import("../Notifications"));

const { WidgetWrapper } = CtraLayout;
const SentryRoute = Sentry.withSentryRouting(Route);

/**
 * Settings page
 * @constructor
 */
const SettingsPage: FC = () => {
  const { t } = useTranslation();
  const { md } = Grid.useBreakpoint();
  const location = useLocation();
  const routes = Routes.app.settings;

  /**
   * The settings menu should be visible on every settings page for large screens
   * but only on the settings index page for mobile screens
   */
  const showMenu = md || location.pathname === routes.index;

  return (
    <>
      <Breadcrumbs.components.Breadcrumb
        path={routes.index}
        title={t<string>(Enterprise.navigation.main.settings)}
      />
      <WidgetWrapper omitHeader style={{ paddingTop: "16px" }}>
        <Suspense
          fallback={
            <Row justify="center" align="middle" style={{ height: "50vh" }}>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
            </Row>
          }
        >
          <Row>
            {showMenu && (
              <Col sm={24} md={4} span={24}>
                <SettingsMenu />
              </Col>
            )}
            <Col xs={24} sm={24} md={20}>
              <InsightNotificationContext.Provider>
                <Switch>
                  <SentryRoute path={Routes.app.settings.insights.index}>
                    <GAContext.Provider
                      value={{ category: `${GACategories.settings} - ${InsightsGACategories.kpiInsights}` }}
                    >
                      <Insights />
                    </GAContext.Provider>
                  </SentryRoute>
                  <SentryRoute path={Routes.app.settings.notifications}>
                    <GAContext.Provider
                      value={{ category: `${GACategories.settings} - ${GACategories.notifications}` }}
                    >
                      <Notifications />
                    </GAContext.Provider>
                  </SentryRoute>
                  {!md && (
                    <SentryRoute
                      path={Routes.app.settings.preferences}
                      render={() => <UserPreferences variant={UserPreferencesVariant.settings} />}
                    />
                  )}
                  {md && (
                    <SentryRoute path={Routes.app.settings.index}>
                      <Redirect to={Routes.app.settings.insights.index} />
                    </SentryRoute>
                  )}
                </Switch>
              </InsightNotificationContext.Provider>
            </Col>
          </Row>
        </Suspense>
      </WidgetWrapper>
    </>
  );
};

export default SettingsPage;
