import { Observable, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";

import { Action } from "@ctra/utils";
import { LayoutEntity } from "./typings";

import { makeAzureApiURL } from "../../utils/ajax";
import types from "./types";
import actions from "./actions";

type FetchLayoutResponse = Array<LayoutEntity>;

/**
 * Fetch dashboard layout
 * @param action$
 * @param state$
 * @param Request
 */
const fetchDashboardLayout: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_DASHBOARD_LAYOUT.pending),
    mergeMap(() =>
      Request.GET(makeAzureApiURL("layouts", "/api/layout")()).pipe(
        map<{ response: FetchLayoutResponse }, Action>(({ response }) =>
          actions.fetchDashboardLayout.fulfill(response)
        ),
        catchError<unknown, Observable<Action>>((error) => of(actions.fetchDashboardLayout.reject(error)))
      )
    )
  );

/**
 * Fetch farm layout
 * @param action$
 * @param state$
 * @param Request
 */
const fetchFarmLayout: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_FARM_LAYOUT.pending),
    mergeMap(({ payload: { farmID } }) =>
      Request.GET(makeAzureApiURL("layouts", "/api/layout/Farm/<%= farmID %>")({ farmID })).pipe(
        map<{ response: FetchLayoutResponse }, Action>(({ response }) =>
          actions.fetchFarmLayout.fulfill(farmID, response)
        ),
        catchError<unknown, Observable<Action>>((error) => of(actions.fetchFarmLayout.reject(farmID, error)))
      )
    )
  );

export default {
  fetchDashboardLayout,
  fetchFarmLayout
};
