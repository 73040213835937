import React, { ComponentProps, FC } from "react";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";

import { ChartEntity, ChartFilterType, Enterprise } from "@ctra/api";
import { Col, Row, Switch, Space, Typography, Button, Grid } from "@ctra/components";
import { useChartContext } from "@ctra/charts";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { useGoogleAnalytics } from "@ctra/analytics";

import { CompiledRoutes, URIState } from "@routes";
import { useSection } from "@analytics";

import {
  ChartViewFilter,
  DIMGroupFilter,
  DateMethod,
  GAActions,
  HerdGroupFilter,
  ISODurationFilter,
  useChartAPI,
  useChartFilters,
  PenFilter
} from "@chart";

import styles from "./Toolbar.module.less";
import { useDataDictionary } from "@base";

const { Text } = Typography;

/**
 * Chart filtering toolbar
 * @note This toolbar only displays when the chart
 * is not under a section context or the chart is zoomed
 * @param {boolean | undefined} withEventsToggle
 * @returns {JSX.Element}
 * @constructor
 */
export const Toolbar: FC = () => {
  const { t } = useTranslation();
  const { section } = useSection();
  const { state } = useLocation<URIState>();
  const searchParams = new URLSearchParams();
  const { trackEvent, label } = useGoogleAnalytics<{ label: string }>();
  const { md } = Grid.useBreakpoint();
  const {
    api: { extractSource }
  } = useDataDictionary();

  const {
    chart,
    api: { setChart },
    meta: { supportedViews }
  } = useChartAPI();

  const { filters, timePeriod, isoDuration, api, metadata } = useChartFilters();

  const {
    viewOptions: { showEvents, showEventToggle },
    api: { toggleEvents }
  } = useChartContext();

  const { setISODuration, setFilter, setTimePeriod, setCurrentDateMethod } = api;
  const { herdGroupID, dimGroupKey, penName } = filters;
  const { supportedISODurations, supportedfarmIDs } = metadata;

  const {
    view,
    flags: { isMultiIntervalSupported },
    filters: filterDefinitions
  } = chart as ChartEntity;

  const {
    chart: {
      filters: { eventToggle }
    },
    navigation: {
      fab: { compareCharts }
    }
  } = Content;

  /**
   * Handle herd group changes
   * @param herdGroupIDList
   */
  const onHerdGroupChange: ComponentProps<typeof HerdGroupFilter>["onHerdGroupChange"] = (herdGroupIDList) =>
    setFilter(ChartFilterType.herdGroup, herdGroupIDList);

  /**
   * Handle DIM group changes
   * @param {Array<DIMGroupEntity["id"]>} dimGroupIDList
   */
  const onDIMGroupChange: ComponentProps<typeof DIMGroupFilter>["onDIMGroupChange"] = (dimGroupIDList) =>
    setFilter(ChartFilterType.dimGroup, dimGroupIDList);

  /**
   * Handle pen changes
   * @param {Array<PenEntity["id"]>} penNameList
   */
  const onPenChange: ComponentProps<typeof PenFilter>["onPenChange"] = (penNameList) =>
    setFilter(ChartFilterType.penName, penNameList);

  /**
   * Handle ISO duration changes
   * @param {<ISODuration>} duration
   */
  const onDurationChange: ComponentProps<typeof ISODurationFilter>["onDurationChange"] = (duration) => {
    setCurrentDateMethod(DateMethod.iso);
    setISODuration(duration);
  };

  /**
   * Handle custom date range changes
   * @param {ChartTimePeriod} timePeriod
   */
  const onTimePeriodChange: ComponentProps<typeof ISODurationFilter>["onTimePeriodChange"] = (timePeriod) => {
    setCurrentDateMethod(DateMethod.custom);
    setTimePeriod(timePeriod);
  };

  /**
   * Handle chart view changes
   * @param {ChartView} view
   */
  const onViewChange: ComponentProps<typeof ChartViewFilter>["onViewChange"] = (view) =>
    setChart(supportedViews[view]);

  /**
   * Handler to toggle events on the chart
   * @param {boolean} checked
   */
  const onToggleEvents: ComponentProps<typeof Switch>["onChange"] = (checked) => {
    trackEvent(GAActions.toggleEventAnnotations, { label: `${label} - ${checked ? "on" : "off"}` });
    toggleEvents(_.mapValues(showEvents, () => checked));
  };

  /**
   * Tell if a filter is multi-selectable
   * @param {ChartFilterType} filterType
   * @return {boolean}
   */
  const isMultiSelectable = (filterType: ChartFilterType): boolean => {
    const definition = _.find(filterDefinitions, ["name", filterType]);

    return !!definition && definition.isMultiSelectable;
  };

  /**
   * Tell if the chart is zoomed
   * @type {boolean}
   */
  const isZoomed = !_.isEmpty(state?.zoom);

  const zoom = state?.zoom;
  const variantID = zoom?.dataDescriptorID;
  // const extractedChart = extractSource(variantID!);

  /**
   * Route to configurator with the chart
   */
  // const handleClick = () => {
  //   if (extractedChart) {
  //     extractedChart?.source && searchParams.set("source", extractedChart.source);
  //     searchParams.set("variant", extractedChart.variant);

  //     Enterprise.history.push({
  //       pathname: CompiledRoutes.app.analytics.dashboard.addChart.metric({
  //         dashboard: zoom?.dashboard,
  //         metricID: extractedChart.metric
  //       }),
  //       search: searchParams.toString(),
  //       state: {
  //         savedChart: {
  //           view,
  //           displayFilters: zoom?.displayFilters,
  //           isoDuration: zoom?.isoDuration,
  //           timePeriod: !zoom?.isoDuration
  //             ? { startDate: zoom?.timePeriod?.startDate, endDate: zoom?.timePeriod?.endDate }
  //             : void 0
  //         }
  //       }
  //     });
  //   }
  // };

  return (
    <Row
      gutter={[16, 8]}
      justify="end"
      align="middle"
      className={styles.Wrapper}
      onClick={(e) => e.stopPropagation()}
    >
      {(_.isNull(section) || isZoomed) && (
        <>
          {showEventToggle && (
            <Col style={{ lineHeight: 0 }}>
              <Space align="center">
                <Switch size="small" checked={_.every(showEvents)} onChange={onToggleEvents} />
                <Text>{t(eventToggle)}</Text>
              </Space>
            </Col>
          )}
          {!!dimGroupKey && (
            <Col>
              <DIMGroupFilter
                multiple={isMultiSelectable(ChartFilterType.dimGroup)}
                farmIDList={supportedfarmIDs}
                selection={dimGroupKey}
                onDIMGroupChange={onDIMGroupChange}
                data-testid="dim-group-filter"
              />
            </Col>
          )}
          {!!herdGroupID && (
            <Col>
              <HerdGroupFilter
                farmIDList={supportedfarmIDs}
                selection={herdGroupID}
                multiple={isMultiSelectable(ChartFilterType.herdGroup)}
                onHerdGroupChange={onHerdGroupChange}
                data-testid="herd-group-filter"
              />
            </Col>
          )}
          {!!penName && (
            <Col>
              <PenFilter
                farmIDList={supportedfarmIDs}
                selection={penName}
                multiple={isMultiSelectable(ChartFilterType.penName)}
                onPenChange={onPenChange}
                data-testid="pen-filter"
              />
            </Col>
          )}
          {_.keys(supportedViews).length > 1 && (
            <Col>
              <ChartViewFilter views={_.keys(supportedViews)} view={view} onViewChange={onViewChange} />
            </Col>
          )}
          {isMultiIntervalSupported && (
            <Col>
              <ISODurationFilter
                durations={supportedISODurations}
                duration={isoDuration}
                timePeriod={timePeriod}
                onDurationChange={onDurationChange}
                onTimePeriodChange={onTimePeriodChange}
              />
            </Col>
          )}
          {/* {isZoomed && (
            <Col>
              <Button className={styles.Analysis} onClick={() => handleClick()}>
                {md && t<string>(compareCharts)}
              </Button>
            </Col>
          )} */}
        </>
      )}
    </Row>
  );
};
