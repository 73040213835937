export enum GACategories {
  settings = "Settings",
  referral = "Referral",
  notifications = "Notifications"
}

export enum GAActions {
  cancelEnableInsight = "Cancel enable insight",
  cancelUpdateInsight = "Cancel update insight",
  disableInsight = "Disable insight",
  enableInsight = "Enable insight",
  goToEnableInsight = "Go to enable insight",
  goToFarm = "Go to farm",
  goToInsights = "Go to Insights",
  openKPISelector = "Open KPI selector",
  selectKPI = "Select KPI",
  setThreshold = "Set threshold",
  toggleThresholdInsightOff = "Toggle threshold insight off",
  toggleThresholdInsightOn = "Toggle threshold insight on",
  updateInsight = "Update insight",
  editReferral = "Edit referral",
  deleteReferral = "Delete referral",
  updateReferral = "Update referral",
  goToReferral = "Go to referral"
}

export enum ReferralGAActions {
  submitReferral = "Submit referral",
  changeField = "Change field"
}
