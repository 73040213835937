import { FC } from "react";
import { useSelector } from "react-redux";

import { Epoch } from "@ctra/utils";
import { Enterprise, useTranslation } from "@ctra/i18n";
import { Cow, Row, Col, Typography, Icon, Space } from "@ctra/components";

import {
  Enterprise as App,
  EnterpriseAppState,
  DairyCowEntity,
  HerdGroupEntity,
  GenericInsightEntity
} from "@ctra/api";

import styles from "./CowDetailsBanner.module.less";

const { Text } = Typography;

type CowDetailsBannerProps = {
  dairyCowID: GenericInsightEntity["dairyCow"];
};

/**
 * Cow details component
 * @constructor
 */
export const CowDetailsBanner: FC<CowDetailsBannerProps> = ({ dairyCowID }) => {
  const { t } = useTranslation();

  /**
   * Get cow
   */
  const dairyCow = useSelector<EnterpriseAppState, DairyCowEntity>(
    (state) => App.entities.getDairyCow(state, { id: dairyCowID }) as DairyCowEntity
  );

  /**
   * Get cow herd group
   */
  const herdGroup = useSelector<EnterpriseAppState, HerdGroupEntity>((state) =>
    App.entities.getHerdGroup(state, { id: dairyCow.herdGroupID })
  );

  const dim = Epoch.daysBetween(dairyCow.lastCalvingEpoch, Epoch.now());

  return (
    <Row gutter={[0, 8]} className={styles.Profile}>
      <Col span={24}>
        <Text>{t<string>(Enterprise.insights.extensions.cowProfile.title)}</Text>
      </Col>
      <Col span={24}>
        <Row gutter={[8, 0]} wrap={false}>
          <Col>
            <Icon component={Cow} className={styles.Cow} />
          </Col>
          <Col flex={1}>
            <Row gutter={[0, 1]}>
              <Col span={24} className={styles.Tag}>
                <Space size={8}>
                  <Text strong>{dairyCow.cowTagNumber}</Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space size={16}>
                  <span>{t<string>(Enterprise.cows.entity.status(dairyCow.status))}</span>
                  <span>
                    {t<string>(Enterprise.cows.entity.lactationNumber, {
                      lactationNumber: dairyCow.lactationNumber
                    })}
                  </span>
                  <span>{t<string>(Enterprise.cows.entity.dim, { dim })}</span>
                  <span>{herdGroup?.name}</span>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
