import * as _ from "lodash";
import { ReactElement, FC, useEffect, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isDispatched } from "@ctra/utils";

import {
  Enterprise,
  Layouts,
  EnterpriseAppState,
  SectionList,
  ChartContainerList,
  SectionEntity,
  LayoutEntity
} from "@ctra/api";

interface LayoutProviderProps {
  farmID?: number;
  children: (
    layout: {
      entity: LayoutEntity;
      supportedSections: SectionList;
      unsupportedSections: SectionList;
      chartContainers: ChartContainerList;
    },
    meta: { isLoading: boolean }
  ) => ReactElement | null;
}

/**
 * Fetch layout for the given farm or the dashboard
 * @param farmID
 * @param children
 * @constructor
 */
export const LayoutProvider: FC<LayoutProviderProps> = ({ farmID, children }) => {
  const dispatch = useDispatch();

  /**
   * Attempt to get the farm or dashboard layout
   */
  const layout = useSelector<EnterpriseAppState, LayoutEntity>(
    (state) => Enterprise.entities.getLayout(state, { farmID }) as LayoutEntity
  );

  /**
   * Pick the section Ids
   */
  const sectionIDs = layout ? _.map(layout.children, "id") : [];

  /**
   * Get the sections from the layout
   */
  const sections = useSelector<EnterpriseAppState, SectionList>((state) =>
    Enterprise.entities.getSectionsByID(state, { sectionIDs })
  );

  /**
   * Pick the chart container ids
   */
  const chartContainerIDs = _.map(_.flatMap(sections, "children"), "id");

  /**
   * Get the components from the sections
   */
  const chartContainers = useSelector<EnterpriseAppState, ChartContainerList>((state) =>
    Enterprise.entities.getChartContainersByID(state, { chartContainerIDs })
  );

  /**
   * Attempt to get all the non supported sections if any
   */
  const unsupportedSections = useSelector<EnterpriseAppState, Record<SectionEntity["id"], SectionEntity>>(
    (state) =>
      !_.isEmpty(layout) ? Enterprise.entities.getSupportedSections(state, { farmID, invert: true }) : {}
  );

  /**
   * Tell whether the fetching action has been dispatched
   */
  const dispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, Layouts.types.FETCH_DASHBOARD_LAYOUT, farmID ? { primaryValue: farmID } : {})
  );

  useEffect(() => {
    if (!(dispatched || _.negate(_.isEmpty)(layout))) {
      if (farmID) {
        dispatch(Layouts.actions.fetchFarmLayout.start(farmID));
      } else {
        dispatch(Layouts.actions.fetchDashboardLayout.start());
      }
    }
  }, [dispatch, dispatched, layout, farmID]);

  return children(
    {
      entity: layout,
      supportedSections: _.omit(sections, _.keys(unsupportedSections)),
      unsupportedSections,
      chartContainers
    },
    { isLoading: !layout }
  );
};

/**
 * Define responsive gutters for the dashboard and the farm layouts
 */
export const LayoutContext = createContext([{ xs: 0, sm: 0, md: 24, lg: 24 }, 24]);
