import { createContext, FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import {
  Enterprise,
  EnterpriseAppState,
  NotificationMappingsState,
  Insights,
  MappingGroups,
  NotificationAction,
  NotificationMappingEntity,
  NotificationType
} from "@ctra/api";
import { isPending } from "@ctra/utils";

interface ContextType {
  allMappings: NotificationMappingsState;
  individualMappings: NotificationMappingsState;
  groupMappingIDs: MappingGroups;
  updateNotificationSettings(
    channel: NotificationType,
    mappingId: NotificationMappingEntity["id"],
    isChecked: boolean
  ): void;
  meta: {
    isLoading: boolean;
    updationID: NotificationMappingEntity["id"];
  };
}

const DefaultContext = createContext<ContextType>({
  allMappings: {},
  individualMappings: {},
  groupMappingIDs: {},
  updateNotificationSettings: _.noop,
  meta: {
    isLoading: true,
    updationID: ""
  }
});

/**
 * Fetch insight mappings from the server
 * @param insightTypeID
 * @param children
 * @private
 */
const _InsightNotificationContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const [updationID, setUpdationID] = useState<NotificationMappingEntity["id"]>("");

  /**
   * Get the mapping(s)
   */
  const allNotificationMappings = useSelector<EnterpriseAppState, NotificationMappingsState>(
    Enterprise.entities.getNotificationMappings
  );

  /**
   * get all the group based mapping id's
   */
  const groupMappingIDs = useSelector<EnterpriseAppState, MappingGroups>(
    Enterprise.entities.getGroupMappings
  );

  /**
   * collect all the individual, non group mappings
   */
  const individualMappings = useSelector<EnterpriseAppState, NotificationMappingsState>(
    Enterprise.entities.getIndividualMappings
  );

  const isFetching = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Insights.types.FETCH_INSIGHT_NOTIFICATION_MAPPINGS)
  );

  const isUpdating = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Insights.types.UPDATE_INSIGHT_NOTIFICATION_SETTINGS)
  );

  useEffect(() => {
    if (_.isEmpty(allNotificationMappings)) {
      dispatch(Insights.actions.fetchInsightNotificationMappings.start());
    }
  }, [dispatch, allNotificationMappings]);

  /**
   * Update notification settings for the channels email & push
   * @param channel
   * @param mappingId
   * @param isChecked
   */
  const updateNotificationSettings = (
    channel: NotificationType,
    mappingId: NotificationMappingEntity["id"],
    isChecked: boolean
  ): void => {
    /**
     * Decides whether to enable or disable a notification type based on
     * whether the switch is checked or unchecked
     */
    const notificationAction = isChecked ? NotificationAction.enable : NotificationAction.disable;
    setUpdationID(mappingId);
    dispatch(
      Insights.actions.updateInsightNotificationSettings.start(channel, mappingId, notificationAction)
    );
  };

  return (
    <DefaultContext.Provider
      value={{
        allMappings: allNotificationMappings,
        individualMappings,
        groupMappingIDs,
        updateNotificationSettings,
        meta: { isLoading: isFetching || isUpdating, updationID }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const InsightNotificationContext = {
  Provider: _InsightNotificationContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Grab the insight mappings from the context
 */
export const useInsightNotifications = (): ContextType => useContext(DefaultContext);
