import { FC, useMemo, useState } from "react";
import * as _ from "lodash";

import type { ColumnsType } from "@ctra/components";
import { Row, Col, Table, Typography, Skeleton, Button } from "@ctra/components";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { GenericInsightEntity } from "@ctra/api";

import { AjinomotoHighRiskCowsInsight, GenericInsight, useInsightlist, testIds } from "@insights";
import styles from "./HighRiskCowTable.module.less";

const { Text } = Typography;

/**
 * @todo move to either to the api or to processing
 */
enum RiskSeverity {
  low = "low",
  medium = "medium",
  high = "high"
}

/**
 * Map string-based results to a proper structure
 * @type {{"High risk": BloodScreeningResult, "Middle risk": BloodScreeningResult, "Low risk": BloodScreeningResult, "No result yet": null}}
 */
const riskLevelMap: Record<string, string | null> = {
  Low: RiskSeverity.low,
  Middle: RiskSeverity.medium,
  High: RiskSeverity.high
};

/**
 * @todo move to either to the api or to processing
 */
interface DataType {
  key: number;
  cowTagNumber: number | string;
  risk: {
    severity: RiskSeverity;
    type: string;
    reason: string;
  };
}

/**
 * Table of cows to perform blood test for
 * @return {React.ReactNode}
 * @constructor
 */
export const HighRiskCowTable: FC = () => {
  const [showingAll, setShowingAll] = useState<boolean>(false);
  const showAllThreshold = 10;

  const {
    insights: {
      extensions: {
        highRiskCows: {
          table: { columns: columnHeaders, risk, showMore }
        }
      }
    }
  } = Content;

  const { t } = useTranslation();

  const {
    insights,
    meta: { isLoading }
  } = useInsightlist();

  /**
   * Set up the column structure which matches the data
   * @type {({dataIndex: string, title: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined | ((props: ColumnTitleProps<DataType>) => React.ReactNode), render: (text) => JSX.Element, key: string} | {dataIndex: string, title: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined | ((props: ColumnTitleProps<DataType>) => React.ReactNode), render: (severity) => JSX.Element, key: string} | {dataIndex: string, align: string, title: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined | ((props: ColumnTitleProps<DataType>) => React.ReactNode), render: (details) => JSX.Element, key: string})[]}
   */
  const columns: ColumnsType<DataType> = [
    {
      title: t<string>(columnHeaders.cowTagNumber),
      dataIndex: "cowTagNumber",
      key: "cowTagNumber",
      render: (text) => <Text className={styles.CowTagNumber}>{text}</Text>
    },
    {
      title: t<string>(columnHeaders.riskSeverity),
      dataIndex: "risk",
      key: "severity",
      render: ({ severity }) => (
        <Text className={styles[`${_.upperFirst(severity)}Risk`]}>
          {t<string>(risk[severity as RiskSeverity])}
        </Text>
      )
    },
    {
      align: "right",
      title: t<string>(columnHeaders.riskType),
      dataIndex: "risk",
      key: "type/reason",
      render: ({ type, reason }) => {
        return (
          <>
            <Text>{type}</Text>
            <br />
            <Text type="secondary" className={styles.Small}>
              {reason}
            </Text>
          </>
        );
      }
    }
  ];

  /**
   * Map the insight list to table data
   * @type {DataType[]}
   */
  const data = useMemo(() => {
    return _.orderBy(
      _.map<GenericInsightEntity, DataType>(insights, (insight) => {
        const { id } = insight;
        const api = GenericInsight.create<typeof AjinomotoHighRiskCowsInsight>(insight);
        const { tagNumber, riskSeverity, riskType, riskReason } = api.getMetadata();

        return {
          key: id,
          cowTagNumber: tagNumber,
          risk: {
            severity: riskLevelMap[riskSeverity] as RiskSeverity,
            type: riskType,
            reason: riskReason
          }
        };
      }),
      ({ cowTagNumber }) => {
        return _.isString(cowTagNumber)
          ? isNaN(parseFloat(cowTagNumber))
            ? cowTagNumber
            : parseFloat(cowTagNumber)
          : cowTagNumber;
      }
    );
  }, [insights]);

  return (
    <Skeleton active loading={isLoading}>
      <Row gutter={[16, 16]} justify="center" align="middle">
        <Col span={24}>
          <Table
            pagination={false}
            data-testid={testIds.extensions.highRiskCowList.table}
            size="small"
            columns={columns}
            dataSource={showingAll ? data : _.slice(data, 0, showAllThreshold)}
            className={styles.Table}
          />
        </Col>
        {!showingAll && data.length > showAllThreshold && (
          <Col span={24} style={{ textAlign: "center" }}>
            <Button onClick={() => setShowingAll(true)}>{t<string>(showMore)}</Button>
          </Col>
        )}
      </Row>
    </Skeleton>
  );
};
