// istanbul ignore file - I'd have a hell of a hard time testing this

import { G2 } from "@ant-design/plots";
import * as _ from "lodash";

import { EventEntity, EventSourceType, ExtendedEventEntity, Line } from "@ctra/api";
import { Events } from "@ctra/components";

import { AnomalyPoint, makeLine, partition, simplify, findOrphans, makeOrphanLine, refill } from "./utils";
const { eventCategoryMap } = Events;

// const factories = G2.getShapeFactory("point");
// const defaultPoint: G2.Types.Shape = _.get(factories, factories.defaultShapeType);
//
// /**
//  * Register a point with inheritance
//  */
// G2.registerShape(
//   "point",
//   "with-inheritance",
//   _.extend({}, defaultPoint, {
//     draw(cfg, container) {
//       defaultPoint.draw.apply(this, [cfg, container]);
//     }
//   } as G2.Types.Shape)
// );

/**
 * Draw anomaly lines
 * @param {Record<string, unknown>} attrs
 * @param {{showMissingData: boolean}} options
 * @return {(cfg, container) => IGroup}
 */
const makeDrawAnomalyLine: (
  attrs: Record<string, unknown>,
  options?: {
    showMissingData?: boolean;
    simplify?: boolean;
  }
) => G2.Types.RegisterShape["draw"] =
  (
    attrs,
    options = {
      showMissingData: false,
      simplify: false
    }
  ) =>
  (cfg, container) => {
    const group = container.addGroup();
    const points = _.get(cfg, ["points"], []);
    const lineData = _.get(cfg, ["data"], []) as Line;

    /**
     * Partition the data into missing and existing
     */
    const [missingData, existingData] = partition(
      points,
      lineData,
      (point) => !_.isNumber(_.get(point, ["y"], null))
    );

    /**
     * Partition the existing data into anomalies and ordinaries
     */
    const [anomalies, ordinaries] = partition(
      // todo use existingData instead of points
      points,
      lineData,
      (point) =>
        // todo remove this
        !_.isNumber(_.get(point, ["y"], null)) ||
        // todo keep this
        !_.isEmpty(_.get(point, ["anomaly"], null))
    );

    /**
     * Add the ordinary path to the chart
     */
    group.addShape("path", {
      attrs: {
        path: makeLine(ordinaries),
        ...cfg.defaultStyle,
        stroke: cfg.color
      },
      name: "ordinaries"
    });

    /**
     * Add the special points to the chart
     */
    group.addShape("path", {
      attrs: {
        path: makeLine(refill(options.simplify ? simplify(anomalies) : anomalies), (prev, current) => {
          return _.get(current, ["type"]) === AnomalyPoint.lead ? "M" : "L";
        }),
        ...cfg.defaultStyle,
        stroke: cfg.color,
        ...attrs
      },
      name: "anomalies"
    });

    /**
     * Make the orphan line
     */
    group.addShape("path", {
      attrs: {
        path: makeOrphanLine(findOrphans(points, lineData)),
        ...cfg.defaultStyle,
        stroke: cfg.color
      },
      name: "orphans"
    });

    if (options.showMissingData) {
      _.forEach(_.chunk(_.compact(simplify(missingData)), 2), ([start, end]) => {
        if (start.type !== AnomalyPoint.lead && end.type !== AnomalyPoint.trail) {
          console.error("Your start and end points are not lead and trail points.", start, end);
        } else {
          const { x: x1 } = start as G2.Types.Point;
          const { x: x2 } = end as G2.Types.Point;

          group.addShape("rect", {
            attrs: {
              width: x2 - x1,
              height: 1000, // a big enough number to cover the whole chart
              x: x1,
              y: 0,
              fill: "rgba(0, 0 ,0 ,0.15)",
              lineWidth: 0
            },
            name: "rect-shape"
          });
        }
      });
    }

    return group;
  };

G2.registerShape("line", "hidden-anomaly-line", {
  draw: makeDrawAnomalyLine(
    {
      lineDash: [4, 4]
    },
    {
      showMissingData: false,
      simplify: true
    }
  )
});

/**
 * Rewrite the line constructor to enable split-lines for missing data and anomalies
 */
G2.registerShape("line", "anomaly-line", {
  draw: makeDrawAnomalyLine(
    {
      stroke: "#CF1322"
    },
    {
      showMissingData: true,
      simplify: false
    }
  )
});

/**
 * Register a point for anomalies
 */
G2.registerShape("point", "anomaly-point", {
  draw(cfg, container) {
    const { x, y, data } = cfg;
    const anomaly = _.get(data, ["anomaly"], null);

    if (anomaly) {
      const { type } = anomaly;
      const group = container.addGroup();
      const isExplained = _.startsWith(type, "explained");

      group.addShape("circle", {
        attrs: {
          x: x as number,
          y: y as number,
          r: 4,
          fill: isExplained ? "#389e0d" : "#CF1322",
          opacity: 1
        }
      });

      return group;
    }
  }
});

/**
 * Make an interactive farm-event point
 */
G2.registerShape("point", "farm-event-point", {
  draw({ x, y, data, ...rest }, container) {
    const radius = 16;
    const top = 32;
    const events = _.get(data, ["events"], []);

    if (events.length) {
      const { category } = _.head(events) as ExtendedEventEntity;
      const [, , icon] = eventCategoryMap[category];

      const hasAiModel = _.some(
        events,
        (event: EventEntity) => event.source.type === EventSourceType.aiModel
      );

      const group: G2.IGroup = container.addGroup();

      group.addShape("rect", {
        name: "click-area",
        attrs: {
          x: Number(x) - radius,
          y: 0,
          width: radius * 2,
          height: Number(y),
          fill: "#ccc",
          opacity: 0
        }
      });

      group.addShape("path", {
        name: "line",
        attrs: {
          filter: hasAiModel ? "hue-rotate(180deg)" : void 0,
          path: [
            ["M", x, 0],
            ["L", x, Number(y)]
          ],
          stroke: "#d9d9d9",
          lineWidth: 1,
          lineDash: [4, 4]
        }
      });

      const img = group.addShape("image", {
        name: "icon",
        attrs: {
          filter: hasAiModel ? "hue-rotate(180deg)" : void 0,
          x: Number(x) - radius / 2,
          y: top - radius / 2,
          img: icon,
          width: radius,
          height: radius
        }
      });

      group.addShape("circle", {
        name: "circle",
        attrs: {
          filter: hasAiModel ? "hue-rotate(180deg)" : void 0,
          x: Number(x),
          y: top,
          r: radius,
          stroke: "#007180",
          fill: "#ffffff"
        }
      });

      img.toFront();

      return group;
    }
  }
});
