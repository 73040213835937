import { memoize } from "@ctra/utils";
import { Ajinomoto } from "@ctra/components";

import { GenericInsightBase } from "./Base";
import { AjinomotoBloodTestInsightBody } from "../insight-body";
import { AjinomotoBloodTestInsightValidation } from "../insight-validation";

/**
 * Blood test insight for Ajinomoto
 */
class AjinomotoBloodTestInsight extends GenericInsightBase {
  /**
   * Use the "blood tests to perform" widget as insight body
   */
  @memoize
  getComponents(): ReturnType<typeof GenericInsightBase.prototype.getComponents> {
    return {
      ...super.getComponents(),
      Icon: Ajinomoto,
      ListIcon: Ajinomoto,
      Body: AjinomotoBloodTestInsightBody,
      Validation: AjinomotoBloodTestInsightValidation
    };
  }
}

export { AjinomotoBloodTestInsight };
