import { FC, ReactNode, ComponentProps } from "react";
import { PageHeader, Space } from "antd";

import { WithSuperScript } from "../../generic";
import styles from "./ChartHeader.module.less";

export type ChartHeaderProps = {
  /**
   * chart title
   */
  title?: string | ReactNode;
  /**
   * chart subtitle
   */
  subtitle?: string;
  /**
   * chart description; usually a KPI description
   */
  description?: string;
  /**
   * custom chart tag
   */
  tag?: JSX.Element;
  /**
   * extra content to render in the header
   */
  extra?: ComponentProps<typeof PageHeader>["extra"];
};

/**
 * Chart header with optional filter components
 * @param tag
 * @param title
 * @param subtitle
 * @param description
 * @param children
 * @constructor
 */
const ChartHeader: FC<ChartHeaderProps> = ({ tag, title, subtitle, description, children, extra }) => {
  /**
   * Add a question mark icon next to the title
   * using a Popover when the description is present.
   */
  const titleComponent = description ? (
    <Space>
      <WithSuperScript content={description}>{title}</WithSuperScript>
      {tag}
    </Space>
  ) : (
    title
  );

  return (
    <PageHeader className={styles.ChartHeader} title={titleComponent} subTitle={subtitle} extra={extra}>
      {children}
    </PageHeader>
  );
};

export default ChartHeader;
