import { FC } from "react";
import moment from "moment";
import * as _ from "lodash";

import { Calendar, Typography, Badge } from "@ctra/components";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { AjinomotoFeedingScheduleInsight, testIds, useInsight } from "@insights";

import styles from "./AjinomotoFeedingSchedule.module.less";
import { TS } from "@ctra/utils";

const { Paragraph } = Typography;

/**
 * Feeding schedule with calendar
 * @return {React.ReactNode}
 * @constructor
 */
export const AjinomotoFeedingSchedule: FC = () => {
  const { t } = useTranslation();
  const { getMetadata } = useInsight<AjinomotoFeedingScheduleInsight>();

  const {
    insights: {
      extensions: {
        additiveFeedingSchedule: {
          title,
          description,
          calendar: {
            entry: { title: calendarEntryTitle }
          }
        }
      }
    }
  } = Content;

  /**
   * Get list data from the metadata for the given day
   * @param moment
   */
  const getListData = (moment: moment.Moment): ReturnType<typeof getMetadata>["feedingSchedule"][string] => {
    const { feedingSchedule } = getMetadata();
    const key = TS.serialize(moment.startOf("day")) as string;

    return _.get(feedingSchedule, key, []);
  };

  /**
   * Render the date cell
   * @param {moment.Moment} value
   * @return {JSX.Element}
   */
  const renderDateCell = (value: moment.Moment) => {
    const listData = getListData(value);

    /**
     * @todo find a way to store colors in a better way
     * @type {{Lysine: string, Choline: string}}
     */
    const supplementColorMap: Record<string, string> = {
      Lysine: "#009EB3",
      Choline: "#C41D7F"
    };

    return listData.map(({ supplementId }) => (
      <Badge
        key={supplementId}
        color={supplementColorMap[supplementId]}
        text={t<string>(calendarEntryTitle(supplementId))}
      />
    ));
  };

  return (
    <div data-testid={testIds.extensions.feedingSchedule}>
      <Paragraph strong className={styles.Title}>
        {t<string>(title)}
      </Paragraph>
      <Paragraph type="secondary" className={styles.Description}>
        {t<string>(description)}
      </Paragraph>
      <Calendar dateCellRender={renderDateCell} className={styles.Calendar} />
    </div>
  );
};
