import { Reducer } from "@ctra/utils";
import { combineReducers } from "redux";
import * as _ from "lodash";

import { cleanup } from "../../utils/reducer";
import types from "./types";
import { LayoutList, SectionList, ChartContainerList, LayoutState, SectionFilterList } from "./typings";
import { NormalizedLayout } from "./schemas";

export const initialState: LayoutState = {
  entities: {},
  sections: {},
  filters: {},
  chartContainers: {}
};

/**
 * Reducer of layout sections
 * @param {ChartContainerList} state
 * @param {Action} action
 * @return {ChartContainerList | {[p: string]: ChartContainerEntity}}
 * @category Reducer
 */
export const chartContainerReducer: Reducer<ChartContainerList> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_FARM_LAYOUT.fulfilled:
    case types.FETCH_DASHBOARD_LAYOUT.fulfilled: {
      const {
        entities: { chartContainer }
      } = payload as NormalizedLayout;

      return {
        ...state,
        ...chartContainer
      };
    }
  }

  return state;
};

/**
 * Reducer of layout sections
 * @param {SectionList} state
 * @param {Action} action
 * @return {{[p: string]: SectionEntity} | SectionList}
 * @category Reducer
 */
export const layoutSectionReducer: Reducer<SectionList> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_FARM_LAYOUT.fulfilled:
    case types.FETCH_DASHBOARD_LAYOUT.fulfilled: {
      const {
        entities: { section }
      } = payload as NormalizedLayout;

      return {
        ...state,
        ...section
      };
    }
  }

  return state;
};

/**
 * Reducer for chart layouts
 * @param {LayoutList} state
 * @param {Action} action
 * @return {LayoutList | {[p: string]: LayoutEntity, [p: number]: LayoutEntity}}
 * @category Reducer
 */
export const layoutReducer: Reducer<LayoutList> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_FARM_LAYOUT.fulfilled:
    case types.FETCH_DASHBOARD_LAYOUT.fulfilled: {
      const {
        entities: { layout }
      } = payload as NormalizedLayout;

      return {
        ...state,
        ...layout
      };
    }
  }

  return state;
};

/**
 * Reducer for section filters
 * @param {SectionFilterList} state
 * @param {Action} action
 * @return {{[p: string]: ChartFilters & {timePeriod?: ChartTimePeriod, isoDuration?: ISODuration}} | SectionFilterList}
 */
export const filterReducer: Reducer<SectionFilterList> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_SECTION_FILTER: {
      const { sectionID, farmID, timePeriod, isoDuration, ...filters } = payload;

      return {
        ...state,
        [sectionID]: {
          ...state[sectionID],
          timePeriod,
          isoDuration,
          [_.defaultTo(farmID, 0)]: {
            ..._.get(state, [sectionID, _.defaultTo(farmID, 0)], {}),
            ...filters
          }
        }
      };
    }
  }

  return state;
};

/**
 * @type {Reducer<CombinedState<{entities: LayoutList, filters: SectionFilterList, chartContainers: ChartContainerList, sections: SectionList}>>}
 * @category Reducer
 */
export const reducer = combineReducers({
  entities: cleanup<LayoutList>({})(layoutReducer),
  sections: cleanup<SectionList>({})(layoutSectionReducer),
  chartContainers: cleanup<ChartContainerList>({})(chartContainerReducer),
  filters: cleanup<SectionFilterList>({})(filterReducer)
});

export default cleanup<LayoutState>(initialState)(reducer);
