import { LelyMilkDropDetail, LelyMilkDropList } from "@ctra/components";
import { memoize } from "@ctra/utils";

import { LelyMilkDropInsightBody, RegularInsightValidation } from "@insights";

import { LelyInsightBase } from "./LelyInsightBase";

/**
 * Lely milk drop insight
 */
class LelyMilkDropInsight extends LelyInsightBase {
  /**
   * Set it's own icons, validation and body
   */
  @memoize
  getComponents(): ReturnType<typeof LelyInsightBase.prototype.getComponents> {
    return {
      Icon: LelyMilkDropDetail,
      ListIcon: LelyMilkDropList,
      Validation: RegularInsightValidation,
      Body: LelyMilkDropInsightBody
    };
  }
}

export { LelyMilkDropInsight };
