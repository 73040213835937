import { FC } from "react";
import * as _ from "lodash/fp";

import { Column as AntBar } from "@ant-design/charts";

import { useBarConfig } from "./useBarConfig";

import { withChartWrapper, withSeriesColor } from "../../../hocs";

/**
 * Bar Component
 * @returns
 */
const BarComponent: FC = () => {
  const { config } = useBarConfig();

  return <AntBar {...config} />;
};

/**
 * Full Bar chart
 */
export const Bar: FC = _.compose(withChartWrapper, withSeriesColor)(BarComponent);
