import { FC } from "react";

import { Col, Row, Skeleton } from "@ctra/components";
import { classname } from "@ctra/utils";

import {
  useInsight,
  AjinomotoFeedingScheduleInsight,
  AjinomotoFeedingSchedule,
  InsightValidation
} from "@insights";

import baseStyles from "../InsightBody/InsightBody.module.less";

/**
 * Ajinomoto feeding schedule
 * @return {React.ReactNode}
 * @constructor
 */
export const AjinomotoFeedingScheduleInsightBody: FC = () => {
  const {
    meta: { isLoading }
  } = useInsight<AjinomotoFeedingScheduleInsight>();

  return (
    <Skeleton loading={isLoading}>
      <Row className={classname(baseStyles.Row, baseStyles.Padded)}>
        <Col flex={1}>
          <InsightValidation />
        </Col>
      </Row>
      <Row className={classname(baseStyles.Row, baseStyles.Padded, baseStyles.Gray, baseStyles.LastRow)}>
        <Col flex={1}>
          <AjinomotoFeedingSchedule />
        </Col>
      </Row>
    </Skeleton>
  );
};
