import { FC, ReactNode } from "react";
import * as _ from "lodash";

import { Button, Space } from "@ctra/components";
import { InsightValidation as ValidationValues, InsightWorkflowState, ValidationLookup } from "@ctra/api";
import { Enterprise, useTranslation } from "@ctra/i18n";

import { useInsightResolutions, useInsightValidation, testIds } from "@insights";

type ValidationButtonsProp = {
  /**
   * Validation options
   */
  options: Array<ValidationValues>;
  /**
   * Following workflow states in the same order as the options
   */
  workflowStates?: Array<InsightWorkflowState>;
};

/**
 * Validation buttons to chose from
 */
export const ValidationButtons: FC<ValidationButtonsProp> = ({ options, workflowStates = [] }) => {
  const { t } = useTranslation();

  const { validate } = useInsightValidation();
  const { unresolve } = useInsightResolutions();

  const {
    insightValidations: { validationValue }
  } = Enterprise;

  /**
   * Handle validation attempt
   * workflow state updation already handled in "validate", so no need to update in "unresolve"
   * @param value
   */
  const onValidate = _.flow(validate, _.partial(unresolve, { updatesWorkflowState: false }));

  return (
    <Space wrap>
      {_.map<ValidationValues, ReactNode>(options, (value, idx) => (
        <Button
          data-testid={testIds.entity.validation.options[value]}
          onClick={_.partial(onValidate, value, workflowStates[idx])}
          key={value}
        >
          {t<string>(validationValue[ValidationLookup[value]])}
        </Button>
      ))}
    </Space>
  );
};
