import * as React from "react";
import { Popover } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { QuestionCircleOutlined } from "@ant-design/icons";

import styles from "./WithSuperScript.module.less";

export interface WithSuperScriptProps {
  /**
   * popover title
   */
  title?: string | React.ReactNode;
  /**
   * popover content
   */
  content?: string | React.ReactNode;
  /**
   * popover placement
   */
  popoverPlacement?: TooltipPlacement | undefined;
  /**
   * popover mouseenter
   */
  onMouseEnter?: () => void;
}

/**
 * Text with question mark popover in superscript
 * @param children
 * @param title
 * @param content
 * @constructor
 */
const WithSuperScript: React.FC<WithSuperScriptProps> = ({
  children,
  title,
  content,
  popoverPlacement = "bottomLeft",
  onMouseEnter
}) => {
  return (
    <>
      {children}
      <sup>
        <Popover
          placement={popoverPlacement}
          title={title}
          content={<section className={styles.Popover}>{content}</section>}
        >
          <QuestionCircleOutlined className={styles.Icon} onMouseEnter={onMouseEnter} />
        </Popover>
      </sup>
    </>
  );
};

export default WithSuperScript;
