import { useDrag, useDrop } from "react-dnd";
import { ComponentProps, FC, useRef, Key, MouseEventHandler } from "react";

import { Col, Row, Space, Tooltip, Typography } from "antd";

import { classname } from "@ctra/utils";

import { ContentWrapper } from "../../../layout";
import { ActionKey, BrowserOutlined, DeleteOutlined, EditOutlined } from "../../../..";

import styles from "./ChartCard.module.less";

const { Text } = Typography;

const type = "CHART_CARD";

export interface ChartCardProps extends ComponentProps<typeof ContentWrapper> {
  /**
   * index
   */
  index: Key;
  /**
   * Metric title
   */
  metric: string;
  /**
   * Variant title
   */
  variant: string;
  /**
   * Source title
   */
  source?: string;
  /**
   * labels for the tooltips
   */
  labels: { remove: string; edit: string };
  /**
   * delete handler
   */
  handleDelete: MouseEventHandler<HTMLSpanElement>;
  /**
   * configuration handler
   */
  handleConfigure: MouseEventHandler<HTMLSpanElement>;
  /**
   * delete handler
   */
  handleDrag: (dragIndex: Key, hoverIndex: Key) => void;
}

/**
 * ChartCard that wraps up the chart
 */
const ChartCard: FC<ChartCardProps> = ({
  index,
  metric,
  variant,
  source,
  children,
  className,
  labels,
  handleDelete,
  handleConfigure,
  handleDrag,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isOver }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: styles.Dropping,
        className: styles.Div
      };
    },
    drop: (item: { index: Key }) => {
      handleDrag(item.index, index);
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drop(drag(ref));

  return (
    <div
      style={{
        opacity: isDragging ? 0.5 : 1
      }}
      ref={ref}
      className={isOver ? styles.Dropping : styles.Div}
    >
      <ContentWrapper className={classname(className, styles.ChartCard)} {...rest}>
        <Row justify="space-between">
          <Text className={styles.Title}>{metric}</Text>
          <Space size="middle" className={styles.Icons}>
            <Tooltip title={labels.remove}>
              <Text type="secondary">
                <DeleteOutlined onClick={handleDelete} />
              </Text>
            </Tooltip>
            <Tooltip title={labels.edit}>
              <Text type="secondary">
                <EditOutlined onClick={handleConfigure} />
              </Text>
            </Tooltip>
          </Space>
        </Row>
        <Row gutter={[24, 0]} className={styles.Metric}>
          <Col>
            <Space align="center">
              <ActionKey />
              <Text type="secondary">{variant}</Text>
            </Space>
          </Col>
          <Col>
            {source && (
              <Space align="center">
                <BrowserOutlined />
                <Text type="secondary">{source}</Text>
              </Space>
            )}
          </Col>
        </Row>
        <Row>{children}</Row>
      </ContentWrapper>
    </div>
  );
};

export default ChartCard;
