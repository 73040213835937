import { Reducer } from "@ctra/utils";
import * as _ from "lodash";

import { cleanup } from "../../utils/reducer";
import types from "./types";
import { FarmList } from "./typings";
import { NormalizedFarmList } from "./schemas";

export const initialState: FarmList = {};

/**
 * Reducer for Ida and Ida Enterprise farm lists
 * @param state
 * @param action
 * @category Reducer
 */
export const reducer: Reducer<FarmList> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_FARM_LIST.fulfilled: {
      const {
        entities: { farm }
      } = payload as NormalizedFarmList;

      return _.mapValues(_.defaultTo(farm, {}), ({ herdGroups, pens, ...rest }) => {
        const penIDList = _.map(_.filter(pens, _.matchesProperty("schema", "pen")), "id");
        const herdGroupIDList = _.map(_.filter(pens, _.matchesProperty("schema", "herdGroup")), "id");

        return {
          ...rest,
          herdGroups: _.uniq(_.concat(herdGroups, herdGroupIDList)),
          pens: penIDList
        };
      });
    }
  }

  return state;
};

export default cleanup<FarmList>(initialState)(reducer);
