import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Plot, PlotEvent, PlotRef } from "@ant-design/charts";
import * as _ from "lodash";

import {
  Charts,
  ExtendedEventEntity,
  MetricEntity,
  Breadcrumbs,
  Enterprise,
  EventSourceType
} from "@ctra/api";

import {
  Charts as ChartUI,
  CtraLayout,
  SwitchWithLabel,
  Row,
  Col,
  Typography,
  Button,
  PlusOutlined,
  CloseOutlined,
  ArrowLeftOutlined
} from "@ctra/components";

import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { Nullable } from "@ctra/utils";
import { GAEvent } from "@ctra/analytics";

import { useFarm } from "@farms";
import { CompiledRoutes, Routes } from "@routes";

import {
  Chart,
  ChartAPIContext,
  ChartFilterContext,
  EventList,
  ISODurationFilter,
  useTimePeriod
} from "@chart";

import { EventListContext } from "@events";
import { useDataDictionary } from "@base";
import { useSavedCharts, alignLegends, GACategories, formatFilters } from "@analytics";

import styles from "./BrowsePage.module.less";
import { MetricPicker } from "../MetricPicker";
import { ChartSelector } from "../ChartSelector";
import { CorrelationList } from "../CorrelationList";
import { compareChartsKey } from "../DashboardPage";
import { useDeepCompareEffect } from "use-deep-compare";

const { WidgetWrapper, ContentWrapper } = CtraLayout;
const { ChartVariant } = ChartUI;
const { Paragraph } = Typography;
const {
  components: { Breadcrumb }
} = Breadcrumbs;

const {
  analytics: {
    correlations: { pageDescription, events },
    v3: {
      config: { cta }
    },
    config: {
      metric: { displayName }
    }
  },
  layouts: {
    dashboard: { compareCharts }
  }
} = Content;

/**
 * Chart configurator page
 * @return {JSX.Element}
 * @constructor
 */
export const BrowsePage: FC = () => {
  const { t } = useTranslation();
  const { dashboard: groupID, chartID } = useParams<{ dashboard: string; chartID: string }>();
  const { farm: farmContext } = useFarm();

  const [showEvents, setShowEvents] = useState<Record<EventSourceType, boolean>>(
    _.mapValues({ ..._.invert(_.toPlainObject(EventSourceType)) }, () => false) as Record<
      EventSourceType,
      boolean
    >
  );

  const [eventList, setEventList] = useState<ExtendedEventEntity[]>([]);
  const [newMetric, setNewMetric] = useState<MetricEntity["id"]>();

  const {
    dataDescriptors,
    metrics,
    meta: { isLoading },
    groups: { projectionMetrics },
    api: { extractSource, handleMetricChanges, extractChartInfo }
  } = useDataDictionary();

  const { savedCharts: allCharts } = useSavedCharts();

  const group = _.find(allCharts, { id: groupID });
  const groupName = _.get(group, "groupName");
  const savedCards = _.get(group, "cards", []);
  const currentCard = _.isEqual(compareChartsKey, chartID) ? void 0 : _.find(savedCards, { id: chartID });
  const initialCardComposition = currentCard && extractSource(currentCard.variantId);
  const initialMetricID = initialCardComposition?.metric;

  /**
   * Handle adding new metric to the list
   */
  const addNewMetric = () => {
    if (newMetric) {
      setChartList((list) => [...list, handleMetricChanges(newMetric)]);
      setNewMetric(void 0);
    }
  };

  /**
   * Handle adding new metric from suggested correlations to the list
   * @param {MetricEntity["id"]} variantID
   */
  const addCorrelation = (variantID: MetricEntity["id"]) => {
    const source = extractSource(variantID);
    source && setChartList((list) => [...list, source]);
  };

  /**
   * State to keep the list of metrics
   */
  const [chartList, setChartList] = useState(
    initialMetricID
      ? [handleMetricChanges(initialMetricID, initialCardComposition?.source, initialCardComposition.variant)]
      : []
  );

  const isProjectionMetric = _.includes(projectionMetrics, _.first(chartList)?.metric);

  /**
   * dateRange can either b iso duration in an array e.g ["P1M"] or a custom range e.g. ["2023-05-01", "2023-06-01"]
   * Supply props to api context based on length of array
   */
  const initialIsoDuration = currentCard
    ? _.size(currentCard?.dateRange) === 1
      ? _.first(currentCard?.dateRange)
      : null
    : "P3M";

  const [startDate, endDate] =
    currentCard?.dateRange && _.size(currentCard.dateRange) === 2 ? currentCard.dateRange : [];

  const [isoDuration, setISOduration] = useState<Nullable<string>>(initialIsoDuration);
  const [initialTimePeriod] = useTimePeriod({ isoDuration }, _.defaultTo(0, 86400));
  const [timePeriod, setTimePeriod] = useState(startDate ? { startDate, endDate } : initialTimePeriod);
  const seriesFilter = _.find(currentCard?.displayFilters, { type: "series" });
  const seriesValues = _.get(seriesFilter, "values", []);
  const dataFilters = formatFilters(currentCard?.dataFilters || []);

  /**
   * If projection metric, add a month to the end date
   */
  useEffect(() => {
    if (isProjectionMetric) {
      initialTimePeriod.endDate = moment(initialTimePeriod.endDate).add(1, "months").toISOString();
      setISOduration(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectionMetric]);

  useDeepCompareEffect(() => {
    setEventList([]);
  }, [chartList]);

  useDeepCompareEffect(() => {
    if (eventList.length > 0 && !_.some(showEvents)) {
      setEventList([]);
    }
  }, [showEvents]);

  const eventPanelHidden = _.isEmpty(eventList) || _.isEmpty(chartList);

  const correlationsComponent = (
    <CorrelationList
      isoDuration={isoDuration}
      variantList={_.map(chartList, "variant")}
      variantID={_.last(chartList)?.variant as string}
      onAdd={addCorrelation}
    />
  );

  const chartInfo = initialCardComposition && extractChartInfo({ ...initialCardComposition });

  const metricName = chartInfo
    ? t(displayName(chartInfo.nameToDisplay), {
        makeDefaultValue: true
      })
    : t(compareCharts.title);

  const ref = useRef<any>();

  return (
    <EventListContext.Provider farmID={farmContext?.id}>
      {groupName && currentCard && (
        <Breadcrumb
          path={CompiledRoutes.app.analytics.dashboard.index({ dashboard: groupID })}
          title={groupName}
        />
      )}
      <Breadcrumb title={metricName} />
      <Button
        size="large"
        type="secondary"
        onClick={() =>
          Enterprise.history.push(
            CompiledRoutes.app.analytics.dashboard.index({
              dashboard: groupID
            })
          )
        }
        icon={<ArrowLeftOutlined />}
      >
        {t(cta.back)}
      </Button>
      <WidgetWrapper
        className={styles.WidgetWrapper}
        loading={isLoading}
        title={<Paragraph>{t<string>(pageDescription)}</Paragraph>}
      >
        <Row gutter={[24, 0]} wrap={false}>
          <Col flex={1}>
            <ContentWrapper padded={false} className={styles.HideOverflow}>
              <Row gutter={[16, 0]} className={styles.Options} justify="end" align="middle">
                {farmContext && (
                  <EventListContext.Consumer>
                    {({ events: eventList }) =>
                      _.size(eventList) > 0 && (
                        <>
                          <Col>
                            <SwitchWithLabel
                              data-gtm-category={GACategories.configurator}
                              data-gtm-action="Toggle aiModel annotations"
                              // inverted the showEvents because of render delay
                              data-gtm-info={JSON.stringify({ visible: !showEvents })}
                              defaultChecked={showEvents[EventSourceType.aiModel]}
                              onChange={(checked) =>
                                setShowEvents((current) => ({
                                  ...current,
                                  [EventSourceType.aiModel]: checked
                                }))
                              }
                              disabled={_.isEmpty(chartList)}
                              size="small"
                              label={t(events, {
                                variant: "ai"
                              })}
                            />
                          </Col>
                          <Col>
                            <SwitchWithLabel
                              data-gtm-category={GACategories.configurator}
                              data-gtm-action="Toggle events"
                              // inverted the showEvents because of render delay
                              data-gtm-info={JSON.stringify({ visible: !showEvents })}
                              defaultChecked={_.every(_.omit(showEvents, EventSourceType.aiModel))}
                              onChange={(checked) =>
                                setShowEvents(({ AIModel, ...rest }) => ({
                                  AIModel,
                                  ..._.mapValues(rest, () => checked)
                                }))
                              }
                              disabled={_.isEmpty(chartList)}
                              size="small"
                              label={t(events, { variant: "Events" })}
                            />
                          </Col>
                        </>
                      )
                    }
                  </EventListContext.Consumer>
                )}
                <Col>
                  <ISODurationFilter
                    data-gtm-category={GACategories.configurator}
                    data-gtm-action="Change ISO duration"
                    durations={Charts.utils.supportedDurations}
                    duration={isoDuration}
                    timePeriod={timePeriod}
                    onDurationChange={setISOduration}
                    onTimePeriodChange={setTimePeriod}
                  />
                </Col>
              </Row>
              {_.map(chartList, ({ metric, source, variant }, idx, collection) => {
                /**
                 * Value change handler for dropdowns associated with metric/source/variant changes
                 */
                const handleChange = (_metric: string, source?: string, variant?: string) =>
                  setChartList((list) =>
                    _.map(list, (chart) =>
                      _.isEqual(chart.metric, metric) ? handleMetricChanges(_metric, source, variant) : chart
                    )
                  );

                return dataDescriptors[variant] ? (
                  <ChartFilterContext.Provider
                    isoDuration={isoDuration}
                    timePeriod={timePeriod}
                    currentValues={{ isoDuration, timePeriod }}
                    series={metric === initialMetricID ? seriesValues : void 0}
                    filters={metric === initialMetricID ? dataFilters : void 0}
                  >
                    <ChartFilterContext.Consumer>
                      {({ series, filters }) =>
                        variant && (
                          <ChartAPIContext.Provider
                            dataDescriptorID={variant}
                            farmID={farmContext?.id}
                            defaultView={currentCard?.chartType}
                          >
                            <ChartAPIContext.Consumer>
                              {({ chart: { view } }) => {
                                /**
                                 * Metric name for GA
                                 */
                                const nameToDisplay = extractChartInfo({
                                  metric,
                                  source,
                                  variant
                                }).nameToDisplay;

                                const metricName = t(displayName(nameToDisplay), {
                                  makeDefaultValue: true
                                });

                                return (
                                  <>
                                    <Row wrap={false} align="middle">
                                      <Col flex={1}>
                                        <ChartSelector
                                          metric={metric}
                                          source={source}
                                          variant={variant}
                                          handleChange={handleChange}
                                        />
                                      </Col>
                                      {metric !== initialMetricID && (
                                        <Col>
                                          <CloseOutlined
                                            className={styles.Close}
                                            data-gtm-category={GACategories.configurator}
                                            data-gtm-action="Remove metric from list"
                                            data-gtm-info={JSON.stringify({ metric: metricName })}
                                            onClick={() =>
                                              setChartList((currentChartList) =>
                                                _.filter(
                                                  currentChartList,
                                                  (item) => !_.isEqual(item.metric, metric)
                                                )
                                              )
                                            }
                                          />
                                        </Col>
                                      )}
                                    </Row>
                                    <Row wrap={false} className={styles.Border}>
                                      <Col span={24} className={styles.Chart}>
                                        <Chart
                                          ref={ref}
                                          key={chartList.length}
                                          variant={ChartVariant.V3}
                                          viewOptions={{
                                            showEvents,
                                            zoomEnabled: false,
                                            showSupportedViews: true,
                                            showToolbar: false,
                                            showAnnotations: true
                                          }}
                                          config={{
                                            onReady: (plot) => {
                                              ref.current = plot;

                                              plot.on(
                                                "afterrender",
                                                _.throttle(() => {
                                                  if (collection.length > 1) {
                                                    alignLegends();
                                                  }
                                                }, 100)
                                              );

                                              plot.on("element:click", (event: PlotEvent) => {
                                                const shape = _.get(event, ["data", "shape"]);
                                                const eventList = _.get(event, ["data", "data", "events"]);

                                                GAEvent(
                                                  GACategories.configurator,
                                                  "Open event",
                                                  JSON.stringify({ eventList })
                                                );

                                                if (shape === "farm-event-point") {
                                                  setEventList(() => eventList);
                                                }
                                              });
                                            },
                                            ...(chartList.length === 1 ? { height: 450 } : {})
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                );
                              }}
                            </ChartAPIContext.Consumer>
                          </ChartAPIContext.Provider>
                        )
                      }
                    </ChartFilterContext.Consumer>
                  </ChartFilterContext.Provider>
                ) : (
                  <Redirect to={Routes.app.analytics.dashboard.index} />
                );
              })}
              <Col>{correlationsComponent}</Col>
              <Row gutter={[12, 0]} className={styles.Selector}>
                <Col flex={1}>
                  <MetricPicker
                    selection={_.map(chartList, "metric")}
                    value={newMetric}
                    onChange={setNewMetric}
                  />
                </Col>
                <Col>
                  <Button
                    size="large"
                    disabled={!newMetric}
                    type="secondary"
                    onClick={addNewMetric}
                    data-gtm-category={GACategories.configurator}
                    data-gtm-action="Add metric to comparison list"
                    data-gtm-info={JSON.stringify({
                      metric:
                        newMetric &&
                        t(displayName(_.defaultTo(_.get(metrics, [newMetric, "name"]), newMetric)), {
                          makeDefaultValue: true
                        })
                    })}
                    icon={<PlusOutlined />}
                  >
                    {t(cta.add)}
                  </Button>
                </Col>
              </Row>
            </ContentWrapper>
          </Col>
          {eventPanelHidden ? null : (
            <Col span={5}>
              <EventList
                variant={
                  _.every(eventList, (event) => event.source.type === EventSourceType.aiModel)
                    ? EventList.variant.insights
                    : EventList.variant.events
                }
                data={eventList}
                handleClose={() => setEventList([])}
              />
            </Col>
          )}
        </Row>
      </WidgetWrapper>
    </EventListContext.Provider>
  );
};
