import { FC, HTMLAttributes } from "react";

import { Col, Copilot, Icon, Row, Typography } from "@ctra/components";
import { useTranslation, Enterprise } from "@ctra/i18n";
import { testIds } from "@insights";

import styles from "./OnboardingFooter.module.less";

const { Title, Paragraph } = Typography;

const {
  insights: {
    extensions: { onboarding }
  }
} = Enterprise;

/**
 * Footer for the onboarding insight
 * @param {string | undefined} className
 * @returns {JSX.Element}
 * @constructor
 */
export const OnboardingFooter: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Row wrap={false} gutter={[16, 0]} className={className}>
      <Col>
        <Icon component={Copilot} className={styles.Icon} />
      </Col>
      <Col flex={1}>
        <Title level={5}>{t<string>(onboarding.footer.title)}</Title>
        <Paragraph>{t<string>(onboarding.footer.description)}</Paragraph>
      </Col>
    </Row>
  );
};
