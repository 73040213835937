import * as _ from "lodash";

import { memoize, TS } from "@ctra/utils";
import { Ajinomoto } from "@ctra/components";

import { GenericInsightBase } from "./Base";
import { AjinomotoFeedingScheduleInsightBody } from "../insight-body";
import { AjinomotoFeedingScheduleInsightValidation } from "../insight-validation";

interface FeedingTask {
  supplementId: string;
  willFeeding: boolean;
}

interface FeedingPlanSource {
  feeding_task_date: string;
  plan: Array<FeedingTask>;
}

type FeedingPlan = Record<FeedingPlanSource["feeding_task_date"], FeedingPlanSource["plan"]>;

/**
 * Blood test insight for Ajinomoto
 */
class AjinomotoFeedingScheduleInsight extends GenericInsightBase {
  @memoize
  getMetadata(): { feedingSchedule: FeedingPlan } {
    const { taskData, ...rest } = super.getMetadata();

    /**
     * Reorganise the feeding scheule by task date
     * @type {FeedingPlan}
     */
    const byFeedingTaskDate = _.reduce<FeedingPlanSource, FeedingPlan>(
      taskData,
      (res, item) => {
        const { feeding_task_date, plan } = item;

        res[TS.serialize(TS.asMoment(feeding_task_date)) as string] = plan;

        return res;
      },
      {}
    );

    return {
      ...rest,
      feedingSchedule: byFeedingTaskDate
    };
  }

  /**
   * Use the "blood tests to perform" widget as insight body
   */
  @memoize
  getComponents(): ReturnType<typeof GenericInsightBase.prototype.getComponents> {
    return {
      ...super.getComponents(),
      Icon: Ajinomoto,
      ListIcon: Ajinomoto,
      Body: AjinomotoFeedingScheduleInsightBody,
      Validation: AjinomotoFeedingScheduleInsightValidation
    };
  }
}

export { AjinomotoFeedingScheduleInsight };
