import { Nullable, memoize } from "@ctra/utils";
import { Enterprise, i18nTranslate } from "@ctra/i18n";
import { DairyCowEntity, Enterprise as App } from "@ctra/api";

import { GenericInsightBase } from "./Base";

/**
 * @todo probably import it from somewhere else
 */
interface Line {
  lineName: string;
  values: Array<Nullable<number>>;
}

/**
 * Lely insight base. Provides common API shared by all Lely insights
 */
class LelyInsightBase extends GenericInsightBase {
  /**
   * Add typing to the metadata
   */
  @memoize
  getMetadata(): {
    lactationNumber: number;
    reproductionStatus: string;
    daysInLactation: number;
    x: string[];
    y: Line[];
  } {
    const { lactationNumber, reproductionStatus, daysInLactation, x, y } =
      GenericInsightBase.prototype.getMetadata.apply(this);

    return { lactationNumber, reproductionStatus, daysInLactation, x, y };
  }

  @memoize
  getTitle(params?: Record<string, unknown>): ReturnType<typeof GenericInsightBase.prototype.getTitle> {
    const {
      entity: { title }
    } = Enterprise.insights;

    const { dairyCow: dairyCowID, insightType } = this.entity;

    const { cowTagNumber } = App.entities.getDairyCow(App.store.getState(), {
      id: dairyCowID
    }) as DairyCowEntity;

    return i18nTranslate(title(insightType), {
      ...this.getMetadata(),
      cowTagNumber,
      ...params
    });
  }
}

export { LelyInsightBase };
