import { FC } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as _ from "lodash";

import { Enterprise as Data, EnterpriseAppState, FarmEntity, InsightValidation } from "@ctra/api";
import { Icon, Insights as InsightsUI } from "@ctra/components";
import { defaultLocale } from "@ctra/i18n";
import { useGoogleAnalytics } from "@ctra/analytics";

import {
  GAActions,
  testIds,
  useInsight,
  ResolutionTagList,
  useInsightValidation,
  KPIInsight
} from "@insights";

import { useCurrentUser } from "@auth";

import styles from "./InsightEntry.module.less";

interface ListItemProps {
  list?: string;
}

const {
  TimePeriod,
  List: { ListItem }
} = InsightsUI;

/**
 * Insight list item
 * @todo make it work with all type of insights
 * @param list
 * @constructor
 */
export const InsightEntry: FC<ListItemProps> = ({ list }) => {
  const { pathname, search } = useLocation();
  const { trackEvent } = useGoogleAnalytics();

  const {
    user: { id: userID }
  } = useCurrentUser();

  const {
    insight: { farm: farmID, endEpoch, id },
    getTitle,
    getComponents,
    getTargetGroupName,
    isSeen
  } = useInsight<KPIInsight>();

  const {
    validation: { validation: validationValue }
  } = useInsightValidation();

  const { name } = useSelector<EnterpriseAppState, FarmEntity>((state) =>
    Data.entities.getFarm(state, { farmID })
  );

  /**
   * Open the insight dialog
   * @todo not all insights may do this
   */
  const handleClick = () => {
    const searchParams = new URLSearchParams(search);
    searchParams.append("genericInsightID", id.toString());

    /* istanbul ignore next */
    trackEvent(GAActions.openInsight, {
      label: `${getTitle({ lng: defaultLocale })} - ${name}`
    });

    if (list) {
      searchParams.append("insightList", list);
    }

    Data.history.push({
      pathname,
      search: searchParams.toString()
    });
  };

  return (
    <ListItem
      data-txestid={testIds.list.entry.wrapper}
      className={styles.ListItem}
      period={<TimePeriod from={endEpoch} />}
      group={_.isFunction(getTargetGroupName) ? getTargetGroupName() : void 0}
      unseen={!isSeen(userID)}
      feedback={validationValue && validationValue !== InsightValidation.na ? <ResolutionTagList /> : void 0}
      farm={_.defaultTo(name, "-")}
      icon={<Icon component={getComponents().ListIcon} />}
      title={getTitle()}
      onClick={handleClick}
    />
  );
};
