import { FC, useEffect, useState } from "react";
import * as _ from "lodash";

import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { Typography, Button } from "@ctra/components";
import { CookieKeys, Cookies } from "@ctra/utils";

import styles from "./CookieConsent.module.less";

const { Title, Paragraph, Link } = Typography;

/**
 * Cookie consent component
 * @return {null | JSX.Element}
 * @constructor
 */
export const CookieConsent: FC = () => {
  const { t } = useTranslation();
  const [hasConsent, setHasConsent] = useState<boolean>(false);

  const {
    ui: { cookies }
  } = Content;

  /**
   * Set cookie consent state
   */
  useEffect(() => {
    setHasConsent(JSON.parse(_.defaultTo(Cookies.get(CookieKeys.cookieConsent), "false")));
  }, []);

  return hasConsent ? null : (
    <div className={styles.Wrapper}>
      <Title level={5}>{t<string>(cookies.title)}</Title>
      <Paragraph>
        {t<string>(cookies.text)}{" "}
        <Link href="https://connecterra.ai/privacy-policy/" target="_blank">
          {t<string>(cookies.link)}
        </Link>
      </Paragraph>
      <Button
        size="large"
        type="primary"
        onClick={() => {
          Cookies.set(CookieKeys.cookieConsent, "true");
          setHasConsent(true);
        }}
      >
        {t<string>(cookies.accept)}
      </Button>
    </div>
  );
};
