import { ComponentProps, FC, useRef, forwardRef, PropsWithChildren } from "react";
import * as _ from "lodash";
import { ContainerConfig, Options } from "@ant-design/charts";
import { useDeepCompareEffect } from "use-deep-compare";

import {
  Charts,
  Empty,
  Tag,
  Alert,
  KpiUnavailable,
  KpiLocked,
  EyeInvisibleOutlined,
  Typography,
  Space
} from "@ctra/components";

import {
  ChartEntity,
  ExtendedEventList,
  ChartViewOptions,
  ChartDataOptions,
  FarmEntity,
  ChartTimePeriod
} from "@ctra/api";

import { useTranslation, Enterprise as Content, defaultLocale } from "@ctra/i18n";
import { classname, isProduction } from "@ctra/utils";
import { GAContext, useGoogleAnalytics } from "@ctra/analytics";
import { ChartContext } from "@ctra/charts";

import { useFarm } from "@farms";
import { useEventList } from "@events";

import { ChartDataContext, useChartFilters, useChartAPI, useChartData } from "../../providers";
import { Toolbar } from "../Toolbar";
import { ChartExtra } from "../ChartExtra";
import { ChartView } from "../ChartView";
import { ChartFooter } from "../ChartFooter";

import styles from "./Chart.module.less";

const { Text } = Typography;
const { Chart: ChartUI } = Charts;

export type ChartProps = PropsWithChildren<{
  variant?: Charts.ChartVariant;
  viewOptions?: ChartViewOptions;
  dataOptions?: ChartDataOptions;
  config?: Partial<Pick<ContainerConfig, "onReady" | "onEvent"> & Options>;
  handleCompare?: () => void;
  handleClose?: () => void;
}>;

const {
  kpi: { noFarmSupport, noFarmConsent, singleFarmChart },
  chart: {
    title: chartTitle,
    subtitle,
    description: chartDescription,
    beta,
    error: { title: errorTitle, description: errorDescription },
    empty: { description: emptyDescription }
  }
} = Content;

/**
 * Lose equal function
 * @param self
 * @param other
 * @returns {boolean}
 */
const loseEqual = (self: unknown, other: unknown) =>
  _.isEqualWith(self, other, (a, b) => (_.isFunction(a) || _.isFunction(b) ? true : void 0));

/**
 * Chart UI wrapper with default labels
 * @param {ChartVariant | undefined} variant
 * @param {Omit<React.PropsWithChildren<{variant?: ChartVariant} & ChartProps & {children?: React.ReactNode | undefined}>, "variant">} props
 * @returns {JSX.Element}
 * @constructor
 */
const UIWrapper: FC<
  {
    variant?: ComponentProps<typeof Chart>["variant"];
  } & ComponentProps<typeof ChartUI>
> = ({ variant = Charts.ChartVariant.V3, ...props }) => {
  const { t } = useTranslation();

  const {
    chart: {
      id,
      title,
      flags: { isBetaChart }
    }
  } = useChartAPI();

  /**
   * Make some UI props
   * @type {{subtitle: string, variant: ChartVariant | undefined, description: string, tag: any, title: any}}
   */
  const defaultProps: Partial<ComponentProps<typeof ChartUI>> = {
    title: t<string>(chartTitle(title), { makeDefaultValue: true }),
    subtitle: t<string>(subtitle(title)),
    description: isProduction()
      ? t<string>(chartDescription(title))
      : `${id} - ${t<string>(chartDescription(title))}`,
    variant,
    tag: isBetaChart ? <Tag className={styles.BetaTag}>{t<string>(beta)}</Tag> : void 0
  };

  return <ChartUI {...defaultProps} {...props} />;
};

/**
 * Graph component with error handling (empty components)
 * @param {{}} viewOptions
 * @param {boolean} hasConsent
 * @param {boolean} hasFailed
 * @param {boolean} isMultiFarmFilterSupported
 * @param {boolean} isEmpty
 * @param {boolean} isLoading
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @returns {JSX.Element}
 * @constructor
 */
const GraphWrapper: FC<{
  viewOptions: ChartProps["viewOptions"];
  flags: {
    isMultiFarmFilterSupported: boolean;
    isLoading: boolean;
    hasConsent: boolean;
    hasFailed: boolean;
    isEmpty: boolean;
  };
}> = ({
  viewOptions = {},
  flags: { hasConsent, hasFailed, isMultiFarmFilterSupported, isEmpty, isLoading },
  children
}) => {
  const { t } = useTranslation();
  const { chart } = useChartAPI();
  const { zoomed } = viewOptions;

  return (
    <div className={classname(styles.Graph, zoomed ? styles.Zoom : null)} data-testid={"graph"}>
      {!hasConsent &&
        (isMultiFarmFilterSupported ? (
          <Empty className={styles.Empty} image={<KpiLocked />} description={t<string>(noFarmConsent)} />
        ) : (
          <Empty
            data-testid="chart-error-single-farm"
            className={styles.Empty}
            image={<EyeInvisibleOutlined className={styles.Invisible} />}
            description={
              <Space className={styles.SingleFarmDescription} direction="vertical" size="middle">
                <Text>{t(singleFarmChart.title)}</Text>
                <Text type="secondary">{t(singleFarmChart.description)}</Text>
              </Space>
            }
          />
        ))}
      {hasConsent && hasFailed && (
        <Alert
          data-testid={"chart-error"}
          className={styles.Error}
          message={t<string>(errorTitle)}
          /**
           * Showing the chart id in dev env helps for debugging
           */
          description={`${t<string>(errorDescription)}${!isProduction() ? ` : ${chart?.id}` : ``}`}
          type="error"
          showIcon
        />
      )}
      {hasConsent && !hasFailed && (
        <>
          {isEmpty ? (
            <Empty
              className={styles.Empty}
              image={isLoading ? styles.HideIcon : void 0} // Get an icon from design
              description={isLoading ? "" : t<string>(emptyDescription)}
            />
          ) : (
            children
          )}
        </>
      )}
    </div>
  );
};

/**
 * Inner chart component with all the dependencies and API related to a chart
 * @param {Partial<Pick<ContainerConfig<Options, Plot<O>>, "onReady" | "onEvent"> & Options> | undefined} defaultConfig
 * @param {ChartVariant | undefined} variant
 * @param {{}} dataOptions
 * @param {{}} viewOptions
 * @param {(() => void) | undefined} handleCompare
 * @param {(() => void) | undefined} handleClose
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @returns {JSX.Element}
 * @constructor
 */
const Inner = forwardRef<
  unknown,
  PropsWithChildren<{
    eventHandlers: Pick<ChartProps, "handleCompare" | "handleClose">;
    variant: ChartProps["variant"];
    config: ChartProps["config"];
    options: Pick<ChartProps, "dataOptions" | "viewOptions">;
  }>
>(
  (
    {
      config: defaultConfig,
      variant = Charts.ChartVariant.V3,
      options: { dataOptions = {}, viewOptions = {} },
      eventHandlers: { handleCompare, handleClose },
      children
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { data, meta, flags } = useChartData();
    const { farm } = useFarm();

    const {
      series,
      api: { setSeries },
      timePeriod
    } = useChartFilters();

    const { chart, dataDescriptor } = useChartAPI();

    const {
      events,
      meta: { isLoading: isEventListLoading }
    } = useEventList<ExtendedEventList>();

    /**
     * Store the view in a ref to optimise performance
     * @type {React.MutableRefObject<ReturnType<(chart: ChartEntity, metadata: MetaType) => InstanceType<RegisteredChartViews>> | undefined>}
     */
    const viewRef = useRef<ReturnType<typeof ChartView.create>>(ChartView.createEmpty());
    const farmRef = useRef<FarmEntity["id"]>();
    const timeRef = useRef<ChartTimePeriod>();

    /**
     * Use a ref for performance reasons, otherwise the chart context props
     * will trip the equality checks in the useState hooks within the charts
     */
    const contextPropsRef = useRef<ComponentProps<typeof ChartContext.Provider>>();

    const {
      flags: { isMultiFarmFilterSupported }
    } = chart;

    const { showToolbar, showEvents } = viewOptions;
    const { isLoading: isChartDataLoading, hasFailed, hasConsent } = flags;

    /**
     * Identify the current chart view
     */
    const identity = viewRef.current.identify();

    /**
     * Update chart view if chart/series change*
     * (*series change on the same chart means a farm change)
     * please look at this code if black lines are persisting in the series
     * should chart update? should sereis update? based on scenarios?
     */
    const shouldChartUpdate =
      identity !== chart.id || farmRef.current !== farm?.id || timeRef.current !== timePeriod;

    if (meta && !_.isEmpty(meta) && shouldChartUpdate) {
      viewRef.current = ChartView.create(chart as ChartEntity, meta);
      farmRef.current = farm?.id;
      timeRef.current = timePeriod;
    } else if (identity && identity !== chart.id) {
      viewRef.current = ChartView.createEmpty();
      setSeries({ available: {}, active: [] });
    }

    /**
     * Get the graph component from the chart view
     */
    const GraphComponent = viewRef.current.getComponent();

    /**
     * Merge config from chart props with the chart view config
     * @todo remove the `getConfig` method from the chart view completely
     */
    const chartConfig = { ...defaultConfig, ...viewRef.current.getConfig() };

    /**
     * Make props for the chart context
     * @type {{data: Array<{x: number, y: string, seriesField: string}> | Array<{x: string, y: number, seriesField: string}> | Array<{x: string, y: Nullable<number>, seriesField: string, anomaly?: {prediction?: number, type?: string}}> | Array<{x: string | number, y: string, z: number}> | Array<Record<string, string | number | string[] | null | undefined>>, meta: (DataPointsMetaSource & TranslatedMeta) | TableMeta, viewOptions: {}, config: {slider?: unknown, xAxis?: unknown, defaultInteractions?: unknown, data?: unknown, legend?: unknown, yField?: unknown, tooltip?: unknown, annotations?: unknown, type?: unknown, reflect?: unknown, heatmapStyle?: unknown, autoFit?: unknown, state?: unknown, height?: unknown, coordinate?: unknown, shape?: unknown, errorTemplate?: unknown, localRefresh?: unknown, loading?: unknown, colorField?: unknown, animation?: unknown, xField?: unknown, meta?: unknown, pixelRatio?: unknown, style?: unknown, syncViewPadding?: unknown, onReady?: unknown, useDeferredLabel?: unknown, scrollbar?: unknown, renderer?: unknown, color?: unknown, pattern?: unknown, sizeLegend?: unknown, className?: unknown, chartRef?: unknown, locale?: unknown, sizeField?: unknown, interactions?: unknown, sizeRatio?: unknown, supportCSSTransform?: unknown, theme?: unknown, padding?: unknown, loadingTemplate?: unknown, label?: unknown, yAxis?: unknown, limitInPlot?: unknown, onEvent?: unknown, width?: unknown, appendPadding?: unknown}, events: ExtendedEventList, entity: ChartEntity}}
     */
    const chartContextProps: ComponentProps<typeof ChartContext.Provider> = {
      data: _.defaultTo(data, []),
      series: _.pick(series.available, series.active),
      events: _.pickBy(events, ({ context }) => {
        if (context.chartResourceType === "variantId") {
          const variantId = context.chartResourceID;

          return variantId === dataDescriptor.id;
        }

        return true;
      }),
      viewOptions,
      dataOptions: { ...dataOptions, timePeriod },
      entity: chart,
      config: chartConfig,
      meta: viewRef.current.getMetadata()
    };

    /**
     * Tell if any of the dependencies are loading
     * @type {boolean}
     */
    const isDataLoading = _.every(
      showEvents ? [isChartDataLoading, isEventListLoading] : [isChartDataLoading]
    );

    /**
     * Get the series (in other words, legend values)
     * @type {string[]}
     */
    const legendValues = _.get(meta, ["series", "keys"], {});

    useDeepCompareEffect(() => {
      /**
       * Update the series ref, this will be used as a data source for the
       * universal filtering aka display filters.
       */
      if (!loseEqual(legendValues, series)) {
        setSeries(({ available, active }) => ({
          available: legendValues,
          active
        }));
      }
    }, [legendValues]);

    /**
     * Use a ref for performance reasons, otherwise the chart context props
     * will trip the equality checks in the useState hooks within the charts.
     * Ignore functions as they are not serializable.
     */
    if (!loseEqual(contextPropsRef.current, chartContextProps)) {
      contextPropsRef.current = chartContextProps;
    }

    return (
      <ChartContext.Provider
        {...(contextPropsRef.current as ComponentProps<typeof ChartContext.Provider>)}
        ref={ref}
      >
        <UIWrapper
          variant={variant}
          loading={isDataLoading}
          tip={t<string>(Content.chart.loading)}
          extension={children}
          filters={showToolbar && <Toolbar />}
          extra={
            <ChartExtra
              viewOptions={viewOptions}
              handleCompare={handleCompare}
              handleClose={handleClose}
              variant={variant}
              config={chartConfig}
            />
          }
          footer={variant !== Charts.ChartVariant.V3 ? <ChartFooter /> : null}
        >
          <GraphWrapper
            viewOptions={viewOptions}
            flags={{
              isLoading: isChartDataLoading,
              hasConsent,
              hasFailed,
              isMultiFarmFilterSupported,
              isEmpty: _.isEmpty(data)
            }}
          >
            <GraphComponent />
          </GraphWrapper>
        </UIWrapper>
      </ChartContext.Provider>
    );
  }
);

Inner.displayName = "Inner";

/**
 * Chart component
 * @param {ChartVariant | undefined} variant
 * @param {{}} viewOptions
 * @param {{}} dataOptions
 * @param {{}} defaultConfig
 * @param {(() => void) | undefined} handleCompare
 * @param {(() => void) | undefined} handleClose
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @returns {JSX.Element}
 * @constructor
 */
const Chart = forwardRef<unknown, ChartProps>(
  (
    {
      variant = Charts.ChartVariant.V3,
      viewOptions = {},
      dataOptions = {},
      config = {},
      handleCompare,
      handleClose,
      children
    },
    ref
  ) => {
    const { category } = useGoogleAnalytics();
    const { t } = useTranslation();

    const {
      chart: { title },
      meta: {
        farms: { hasSupport }
      }
    } = useChartAPI();

    return (
      <GAContext.Provider value={{ category, label: t<string>(chartTitle(title), { lng: defaultLocale }) }}>
        {_.isEmpty(hasSupport) ? (
          <UIWrapper extra={<ChartExtra viewOptions={viewOptions} handleClose={handleClose} />}>
            <Empty
              className={styles.Empty}
              image={<KpiUnavailable />}
              description={t<string>(noFarmSupport)}
            />
          </UIWrapper>
        ) : (
          <ChartDataContext.Provider>
            <Inner
              ref={ref}
              config={config}
              variant={variant}
              eventHandlers={{ handleCompare, handleClose }}
              options={{ dataOptions, viewOptions }}
            >
              {children}
            </Inner>
          </ChartDataContext.Provider>
        )}
      </GAContext.Provider>
    );
  }
);

Chart.displayName = "Chart";

export { Chart };
