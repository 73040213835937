import { FC } from "react";

import { Row, Skeleton, Col, LoadingOutlined } from "@ctra/components";
import { classname } from "@ctra/utils";

import { useInsight, InsightValidation, LelyInsightBase, CowDetailsBanner } from "@insights";
import { useFarmList } from "@farms";

import baseStyles from "../InsightBody/InsightBody.module.less";

/**
 * Base Insight body for Lely Insight
 * @constructor
 */
export const LelyInsightBaseBody: FC = ({ children }) => {
  const { isLoading: isFarmListLoading } = useFarmList();

  const {
    insight: { dairyCow },
    meta: { isLoading }
  } = useInsight<LelyInsightBase>();

  return (
    <Skeleton active loading={isLoading}>
      {dairyCow && (
        <Row>
          <Col span={24}>
            {isFarmListLoading ? <LoadingOutlined /> : <CowDetailsBanner dairyCowID={dairyCow} />}
          </Col>
        </Row>
      )}
      <Row className={classname(baseStyles.Row, baseStyles.Padded)}>
        <Col flex={1}>
          <InsightValidation />
        </Col>
      </Row>
      {children}
    </Skeleton>
  );
};
