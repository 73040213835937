import { normalize } from "normalizr";

import { createAsyncActions, Nullable } from "@ctra/utils";

import { FarmEntity } from "../farms";
import schemas from "./schemas";
import types from "./types";
import { SectionEntity, SectionFilter } from "./typings";

/**
 * Fetch farm layout
 * @note
 * farmID is the primary key in FETCH_FARM_LAYOUT,
 * thus it needs to be present in every payload
 * @category Action
 */
const fetchFarmLayout = createAsyncActions(
  types.FETCH_FARM_LAYOUT,
  (farmID: number) => ({ farmID }),
  (farmID: number, layoutResponse) => ({
    farmID,
    ...normalize(layoutResponse, schemas.layout)
  }),
  (farmID, error: string) => ({ farmID, error })
);

/**
 * Fetch dashboard layout
 * @category Action
 */
const fetchDashboardLayout = createAsyncActions(
  types.FETCH_DASHBOARD_LAYOUT,
  () => ({}),
  (layoutResponse) => normalize(layoutResponse, schemas.layout),
  (error: string) => ({ error })
);

/**
 * Set filters for the given section
 * @param {SectionEntity["id"]} sectionID
 * @param {<FarmEntity["id"]>} farmID
 * @param {SectionFilter} filters
 * @return {{payload: {timePeriod?: ChartTimePeriod, dimGroupKey?: Array<DIMGroupEntity["id"]>, sectionID: string, farmID: <FarmEntity["id"]>, herdGroupID?: Array<HerdGroupEntity["id"]>, isoDuration?: ISODuration}, type: string}}
 */
const setSectionFilters = (
  sectionID: SectionEntity["id"],
  farmID: Nullable<FarmEntity["id"]>,
  filters: SectionFilter
) => ({
  type: types.SET_SECTION_FILTER,
  payload: { sectionID, farmID, ...filters }
});

export default {
  fetchFarmLayout,
  fetchDashboardLayout,
  setSectionFilters
};
