import { FC, useState } from "react";
import * as _ from "lodash";
import { Link } from "react-router-dom";

import {
  Button,
  CtraLayout,
  Grid,
  Insights as InsightUI,
  List,
  RightOutlined,
  PlusOutlined,
  Row,
  Skeleton
} from "@ctra/components";

import { Nullable } from "@ctra/utils";
import { useGoogleAnalytics } from "@ctra/analytics";
import { Enterprise, useTranslation } from "@ctra/i18n";
import { Enterprise as App, GenericInsightEntity, Insights, InsightWorkflowState } from "@ctra/api";

import {
  GAActions,
  InsightContext,
  InsightEntry,
  InsightListContext,
  InsightResolutionContext,
  InsightValidationContext,
  TabNavigation
} from "@insights";

import { useFarmList, useFarm } from "@farms";
import { CompiledRoutes, Routes } from "@routes";
import { DataDictionaryProvider } from "@base";

import styles from "./InsightList.module.less";

const { WidgetWrapper, ContentWrapper } = CtraLayout;

const {
  List: { ListHeader, Empty }
} = InsightUI;

interface InsightListProps {
  /**
   * query to load into the insight list
   */
  query: Nullable<Record<string, unknown>>; // @todo use odata-query
  /**
   * a named list for convenience and to be used for translations
   */
  list: string;
  /**
   * wide version of the list on a seperate page
   */
  wide?: boolean;
}

const {
  layouts: {
    insights: { loading }
  },
  insights: {
    enable,
    list: {
      headers,
      viewAll,
      empty: { title: emptyTitle, description: emptyDescription }
    }
  }
} = Enterprise;

/**
 * Insight list wrapper
 * @param query
 * @param list
 * @param wide
 * @constructor
 */
export const InsightList: FC<InsightListProps> = ({ query, list, wide }) => {
  const { t } = useTranslation();
  const { farm } = useFarm();
  const { md } = Grid.useBreakpoint();
  const { trackEvent } = useGoogleAnalytics();
  const { farmList, isLoading: isFarmListLoading } = useFarmList();

  const defaultPageSize = wide ? 10 : 3;
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  /**
   * Don't fetch anything without the farm list, hence the void 0
   */
  const mergedQuery =
    !query || _.isEmpty(farmList)
      ? void 0
      : {
          ...query,
          filter: {
            and: _.compact([query.filter, Insights.queries.onFarm(farm?.id || _.map(farmList, "id"))])
          }
        };
  /**
   * Farm nome
   * @type {string|null}
   */
  const farmName = farm ? _.get(farmList, `${farm.id}.name`) : null;

  return (
    <DataDictionaryProvider>
      {(dataDescriptors, { isLoading: isDataDictionaryLoading }) => (
        <InsightListContext.Provider query={mergedQuery} list={list}>
          <InsightListContext.Consumer>
            {({ insights, insightStateFilter, meta: { isLoading: isInsightListLoading } }) => {
              const items = _.values(insights);
              const length = items.length;
              const isPaginationHidden = !(md || wide) && length > defaultPageSize;

              /**
               * Tell if any of the dependencies are loading
               * @type {boolean}
               */
              const isDependencyTreeLoading =
                isDataDictionaryLoading || isFarmListLoading || isInsightListLoading;

              /**
               * Render the insight list entry
               * @param {GenericInsightEntity} insight
               * @return {JSX.Element}
               */
              const renderInsight = (insight: GenericInsightEntity) => (
                <InsightContext.Provider genericInsightID={insight.id} key={insight.id}>
                  <InsightValidationContext.Provider genericInsightID={insight.id}>
                    <InsightResolutionContext.Provider>
                      <InsightEntry list={list} />
                    </InsightResolutionContext.Provider>
                  </InsightValidationContext.Provider>
                </InsightContext.Provider>
              );

              return (
                <WidgetWrapper
                  loading={isDependencyTreeLoading || !query}
                  tip={t<string>(loading)}
                  toolbar={
                    <Button
                      icon={<PlusOutlined />}
                      type="secondary"
                      onClick={() => {
                        App.history.push(
                          _.get(
                            CompiledRoutes,
                            _.compact(
                              _.flatten([
                                "app",
                                "settings",
                                "insights",
                                farm?.id ? ["farm", "enable"] : "index"
                              ])
                            )
                          )({ farmID: farm?.id || void 0 })
                        );
                      }}
                    >
                      {t<string>(enable)}
                    </Button>
                  }
                >
                  <TabNavigation showDoneState={!!wide} />
                  <ContentWrapper padded={false} className={styles.ListWrapper}>
                    <Skeleton active loading={isDependencyTreeLoading}>
                      {_.isEmpty(insights) ? (
                        <Empty
                          variant="cliff"
                          title={t<string>(emptyTitle, {
                            farmName,
                            workflowState: insightStateFilter,
                            status: "disabled"
                          })}
                          description={t<string>(emptyDescription, {
                            farmName,
                            workflowState: insightStateFilter,
                            status: "disabled"
                          })}
                        />
                      ) : (
                        <>
                          <ListHeader
                            labels={{
                              insight: t<string>(headers.insight),
                              feedback:
                                insightStateFilter === InsightWorkflowState.toCheck
                                  ? void 0
                                  : t<string>(headers.feedback),
                              farm: t<string>(headers.farm),
                              period: t<string>(headers.period)
                            }}
                          />
                          <List
                            className={isPaginationHidden ? styles.Hide : styles.Pagination}
                            dataSource={items}
                            renderItem={renderInsight}
                            pagination={{
                              size: "small",
                              pageSize,
                              defaultPageSize,
                              total: length,
                              disabled: length <= defaultPageSize,
                              showSizeChanger: length > defaultPageSize,
                              pageSizeOptions: _.times(3, (i) => (defaultPageSize * (i + 1)).toString()),
                              onShowSizeChange:
                                // istanbul ignore next - ant design internal
                                (current, pageSize) => setPageSize(pageSize),
                              onChange:
                                // istanbul ignore next - ant design internal
                                (pageNumber) =>
                                  trackEvent(GAActions.selectPage, { label: pageNumber.toString() })
                            }}
                          />
                        </>
                      )}
                      {isPaginationHidden && (
                        <Row justify="center" className={styles.All}>
                          <Link to={Routes.app.insights.index}>
                            <Row align="middle">
                              {t<string>(viewAll)}&nbsp;
                              <RightOutlined />
                            </Row>
                          </Link>
                        </Row>
                      )}
                    </Skeleton>
                  </ContentWrapper>
                </WidgetWrapper>
              );
            }}
          </InsightListContext.Consumer>
        </InsightListContext.Provider>
      )}
    </DataDictionaryProvider>
  );
};
