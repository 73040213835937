import { FC } from "react";

import { classname } from "@ctra/utils";
import { Col, Row, Skeleton } from "@ctra/components";

import { AjinomotoBloodTestInsight, InsightValidation, useInsight } from "@insights";

import baseStyles from "../InsightBody/InsightBody.module.less";

/**
 * Aj nutrition recommendation body
 * @return {JSX.Element}
 * @constructor
 */
export const AjinomotoNutritionRecommendationInsightBody: FC = () => {
  const {
    meta: { isLoading }
  } = useInsight<AjinomotoBloodTestInsight>();

  return (
    <Skeleton active loading={isLoading}>
      <Row className={classname(baseStyles.Row, baseStyles.Padded, baseStyles.LastRow)}>
        <Col flex={1}>
          <InsightValidation />
        </Col>
      </Row>
    </Skeleton>
  );
};
