import { useMemo } from "react";
import * as _ from "lodash";

import { LibraryConfigType } from "../typings";
import { useChartContext } from "../providers";

/**
 * These configs are common to all charts
 * @todo rename `commonConfig` to `config`
 */
export function useCommonConfig<T extends LibraryConfigType = LibraryConfigType>(): { commonConfig: T } {
  const { meta } = useChartContext();

  return useMemo<{ commonConfig: any }>(
    () => ({
      commonConfig: {
        animation: false,
        interactions: [{ type: "element-active" }],
        /**
         * Basic legend settings
         */
        legend: {
          layout: "horizontal",
          position: "top-left",
          flipPage: true,
          maxItemWidth: 120,
          maxRow: 2,
          offsetX: 24,
          itemName: {
            formatter: (text: string) => _.get(meta, ["series", "keys", text], text)
          }
        },
        /**
         * Basic tooltip settings
         */
        tooltip: {
          shared: false,
          position: "top",
          domStyles: {
            "g2-tooltip": {},
            "g2-tooltip-title": {
              color: "#666",
              margin: "10px 0 10px 16px"
            },
            "g2-tooltip-list": {},
            "g2-tooltip-list-item": {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "14px",
              whiteSpace: "nowrap"
            },
            "g2-tooltip-marker": {
              borderRadius: "50%",
              display: "inline-block",
              height: "8px",
              marginRight: "8px",
              width: "8px"
            },
            "g2-tooltip-value": {
              fontWeight: "bold"
            },
            "g2-tooltip-name": {}
          },
          containerTpl: `
        <div class="g2-tooltip">
          <ul class="g2-tooltip-list"></ul>
          <div class="g2-tooltip-title"></div>
        </div>
      `,
          itemTpl: `
        <li class="g2-tooltip-list-item" data-index={index}>
          <span class="g2-tooltip-marker" style="background-color: {color};"></span>
          <span class="g2-tooltip-name">{name}</span>
          <span class="g2-tooltip-value">{value}</span>
        </li>
      `
        }
      }
    }),
    []
  );
}
