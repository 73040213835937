import * as _ from "lodash";

import { FarmEntity, Insights, InsightType } from "@ctra/api";
import { MONTH } from "@ctra/utils";

import { useCurrentUser } from "@auth";
import { useFarm } from "@farms";

type LatestInsights = {
  insightListQuery: Record<string, unknown> | null;
  insightListName: string;
};

/**
 * List query and list name for latest insights
 */
export const useLatestInsights = (farmID?: FarmEntity["id"]): LatestInsights => {
  const { farm } = useFarm();

  const {
    user: { id: userID }
  } = useCurrentUser();

  /**
   * Query preset for KPI insights
   * @type {{and: ({visibleToUserID: string | undefined} | PlainObject)[]}}
   */
  const kpiInsightPreset = {
    and: [
      {
        visibleToUserID: userID
      },
      Insights.queries.ofType(InsightType.farmKPI)
    ]
  };

  /**
   * Query preset for Lely insights
   * @type {PlainObject}
   */
  const lelyInsightPreset = Insights.queries.ofType([
    InsightType.lelyMilkDrop,
    InsightType.lelyNoMilkDrop,
    InsightType.lelyKetosis
  ]);

  /**
   * Ouery params for Ajinomoto
   * @type {PlainObject}
   */
  const ajinomotoInsightPreset = Insights.queries.ofType([
    InsightType.ajinomotoBloodTest,
    InsightType.ajinomotoFeedingSchedule,
    InsightType.ajinomotoNutritionRecommendation,
    InsightType.ajinomotoHighRiskCows
  ]);

  /**
   * Onboarding insights
   * @type {PlainObject}
   */
  const onboardingInsightPreset = Insights.queries.ofType(InsightType.onboarding);

  /**
   * Make a query for the insight list widget
   * @todo don't add farm info here, that goes one level deeper
   */
  const insightListQuery = userID
    ? {
        orderBy: "endEpoch desc",
        expand: Insights.presets.expandDefaults(),
        filter: {
          ...Insights.queries.from(5, MONTH),
          insightState: { in: Insights.presets.insightStates() },
          or: [kpiInsightPreset, lelyInsightPreset, ajinomotoInsightPreset, onboardingInsightPreset]
        }
      }
    : null;

  /**
   * Set an insight list name, append the farm name or id if available.
   */
  const farmId = farm ? _.kebabCase(farm.name as string) || farm.id : null;
  const insightListName = `latestInsights${farmId ? `-${farmId}` : ""}`;

  return { insightListQuery, insightListName };
};
