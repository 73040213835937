import { ComponentProps, FC } from "react";
import * as _ from "lodash";

import { ChartFilterType, SectionEntity } from "@ctra/api";

import { Col, Row } from "@ctra/components";
import { classname } from "@ctra/utils";

import {
  DIMGroupFilter,
  DateMethod,
  HerdGroupFilter,
  ISODurationFilter,
  useChartFilters,
  PenFilter
} from "@chart";

import { testIds } from "../../../../testing";
import styles from "./SectionToolbar.module.less";

interface SectionToolbarProps extends ComponentProps<typeof Row> {
  /**
   * section ID for the toolbar
   */
  sectionID?: SectionEntity["id"];
  /**
   * disabled state
   */
  disabled?: boolean;
}

/**
 * Section filtering toolbar
 * @param {string} sectionID
 * @param {boolean | undefined} disabled
 * @param {string | undefined} className
 * @param {Omit<React.PropsWithChildren<SectionToolbarProps>, "disabled" | "className" | "sectionID">} rest
 * @returns {JSX.Element | null}
 * @constructor
 */
export const SectionToolbar: FC<SectionToolbarProps> = ({ sectionID, disabled, className, ...rest }) => {
  const { filters, isoDuration, timePeriod, api, metadata } = useChartFilters();
  const { herdGroupID, dimGroupKey, penName } = filters;
  const { setFilter, setISODuration, setTimePeriod, setCurrentDateMethod } = api;
  const { filterDefinitions, supportedISODurations, showFilters, supportedfarmIDs } = metadata;

  /**
   * Handle herd group changes
   * @param herdGroupIDList
   */
  const onHerdGroupChange: ComponentProps<typeof HerdGroupFilter>["onHerdGroupChange"] = (herdGroupIDList) =>
    setFilter(ChartFilterType.herdGroup, herdGroupIDList);

  /**
   * Handle pen changes
   * @param {Array<PenEntity["id"]>} penNameList
   */
  const onPenChange: ComponentProps<typeof PenFilter>["onPenChange"] = (penNameList) =>
    setFilter(ChartFilterType.penName, penNameList);

  /**
   * Handle DIM group changes
   * @param {Array<DIMGroupEntity["id"]>} dimGroupIDList
   */
  const onDIMGroupChange: ComponentProps<typeof DIMGroupFilter>["onDIMGroupChange"] = (dimGroupIDList) =>
    setFilter(ChartFilterType.dimGroup, dimGroupIDList);

  /**
   * Handle ISO duration changes
   * @param {<ISODuration>} duration
   */
  const onDurationChange: ComponentProps<typeof ISODurationFilter>["onDurationChange"] = (duration) => {
    setCurrentDateMethod(DateMethod.iso);
    setISODuration(duration);
  };

  /**
   * Handle custom date range changes
   * @param {ChartTimePeriod} timePeriod
   */
  const onTimePeriodChange: ComponentProps<typeof ISODurationFilter>["onTimePeriodChange"] = (timePeriod) => {
    setCurrentDateMethod(DateMethod.custom);
    setTimePeriod(timePeriod);
  };

  /**
   * Tell if a filter is multi-selectable
   * @param {ChartFilterType} filterType
   * @param {boolean} legacyCondition
   * @return {boolean}
   * @todo deprecate `legacyCondition` after v4.9.0
   */
  const isMultiSelectable = (filterType: ChartFilterType, legacyCondition: boolean) => {
    const definition = _.find(filterDefinitions, ["name", filterType]);

    return definition ? definition.isMultiSelectable : legacyCondition;
  };

  return showFilters ? (
    <Row gutter={[16, 0]} align="middle" className={classname(styles.Wrapper, className)} {...rest}>
      {!!dimGroupKey && (
        <Col data-testid={testIds.sectionToolbar.dimGroupFilter}>
          <DIMGroupFilter
            disabled={disabled}
            multiple={isMultiSelectable(ChartFilterType.dimGroup, true)}
            farmIDList={supportedfarmIDs}
            selection={dimGroupKey}
            onDIMGroupChange={onDIMGroupChange}
          />
        </Col>
      )}
      {!!herdGroupID && (
        <Col data-testid={testIds.sectionToolbar.herdGroupFilter}>
          <HerdGroupFilter
            disabled={disabled}
            farmIDList={supportedfarmIDs}
            selection={herdGroupID}
            multiple={isMultiSelectable(ChartFilterType.herdGroup, true)}
            onHerdGroupChange={onHerdGroupChange}
          />
        </Col>
      )}
      {!!penName && (
        <Col data-testid={testIds.sectionToolbar.penFilter}>
          <PenFilter
            disabled={disabled}
            farmIDList={supportedfarmIDs}
            selection={penName}
            multiple={isMultiSelectable(ChartFilterType.penName, true)}
            onPenChange={onPenChange}
          />
        </Col>
      )}
      <Col data-testid={testIds.sectionToolbar.isoDurationFilter}>
        <ISODurationFilter
          disabled={disabled}
          durations={supportedISODurations}
          duration={isoDuration}
          timePeriod={timePeriod}
          onDurationChange={onDurationChange}
          onTimePeriodChange={onTimePeriodChange}
        />
      </Col>
    </Row>
  ) : null;
};
