import { useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import * as _ from "lodash";

import {
  Charts,
  Correlations,
  DataDescriptorEntity,
  DataDescriptorList,
  Enterprise,
  EnterpriseAppState
} from "@ctra/api";

import { Analytics, Skeleton, Button, Row, Col, Tag, WithSuperScript, Typography } from "@ctra/components";
import { useGoogleAnalytics } from "@ctra/analytics";
import { useTranslation, Enterprise as Content, parseLocoTemplate } from "@ctra/i18n";
import { Optional, isPending } from "@ctra/utils";

import { useFarm } from "@farms";
import { useChartFilters } from "@chart";
import { useDataDictionary } from "@base";

import { GAActions } from "../../../analytics";
import { closestSupportedInterval, correlationDelta, correlationStrength } from "./utils";
import styles from "./CorrelationList.module.less";

const { CorrelationMetric, NoCorrelations } = Analytics;
const { Text } = Typography;

interface CorrelationListProps {
  /**
   * List of data descriptors
   */
  dataDescriptorList: DataDescriptorList;
  /**
   * chart to show correlations for
   */
  dataDescriptorID: DataDescriptorEntity["id"];
  /**
   * handle adding this to the chart list
   * @param {DataDescriptorEntity["id"]} dataDescriptorID
   */
  onAdd: (dataDescriptorID: DataDescriptorEntity["id"]) => void;
}

/**
 * Correlation list header
 * @return {JSX.Element}
 * @constructor
 */
const TableHeader: FC = () => {
  const { t } = useTranslation();

  const {
    gamePlan: {
      list: {
        headers: { kpi }
      }
    },
    analytics: {
      correlations: { headers }
    }
  } = Content;

  return (
    <Row className={styles.Header} wrap={false}>
      <Col xs={2} lg={1} />
      <Col xs={6} lg={10}>
        {t(kpi)}
      </Col>
      <Col xs={4} lg={3}>
        {t(headers.trend)}
      </Col>
      <Col xs={6} lg={4}>
        {t(headers.correlation)}
      </Col>
      <Col flex={1} />
    </Row>
  );
};

/**
 * Correlation list for the selected data descriptor
 * @param {DataDescriptorList} dataDescriptorList
 * @param {string} dataDescriptorID
 * @param {(dataDescriptorID: DataDescriptorEntity["id"]) => void} onAdd
 * @return {JSX.Element}
 * @constructor
 */
export const CorrelationList: FC<CorrelationListProps> = ({
  dataDescriptorList,
  dataDescriptorID,
  onAdd
}) => {
  const { farm } = useFarm();
  const { isoDuration } = useChartFilters();
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const { dataDescriptors } = useDataDictionary();
  const [activeDescriptor, setActiveDescriptor] = useState<DataDescriptorEntity["id"]>();

  const {
    chart: { title: chartTitle },
    analytics: {
      correlations: { add, chart, trend, correlation, tooltip }
    },
    layouts: {
      section: { markedBeta }
    },
    kpi: descriptor
  } = Content;

  const [description, note] = _.defaultTo(parseLocoTemplate(t(tooltip)), []);

  /**
   * get combined descriptor id's if they are available
   * e.g for a descriptor called eating and ruminating time, the combined descriptors will have
   * an array of eating time descriptor id and ruminating time descriptor id
   */
  const combinedDescriptors = dataDescriptorList[dataDescriptorID].dataProperties?.combinedDescriptors;

  /**
   * If there are combined descriptors, the active descriptor should be from the list
   * otherwise use the chart descriptor id
   */
  useEffect(() => {
    setActiveDescriptor(_.isEmpty(combinedDescriptors) ? dataDescriptorID : _.first(combinedDescriptors));
  }, [combinedDescriptors, dataDescriptorID]);

  /**
   * Is fetching correlated charts
   */
  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    farm?.id ? isPending(state, Charts.types.FETCH_FARM_CORRELATIONS, { primaryValue: farm.id }) : true
  );

  /**
   * Fetch the correlated charts
   */
  const supportedDescriptors = useSelector<EnterpriseAppState, Optional<Array<DataDescriptorEntity["id"]>>>(
    (state) =>
      Enterprise.entities.getSupportedDescriptorsForInterval(state, {
        farmID: farm?.id,
        interval: closestSupportedInterval(isoDuration)
      })
  );

  /**
   * Check if the data descriptor has suggestions enabled
   */
  const suggestionsEnabled = _.includes(supportedDescriptors, activeDescriptor);

  /**
   * Fetch the correlated charts
   */
  const correlatedCharts = useSelector<
    EnterpriseAppState,
    Record<DataDescriptorEntity["id"], Optional<Correlations>>
  >((state) =>
    suggestionsEnabled
      ? _.reduce(
          [dataDescriptorID, ..._.defaultTo(combinedDescriptors, [])],
          (result, id) => {
            const correlatedCharts = Enterprise.entities.getCorrelatedCharts(state, {
              dataDescriptorID: id,
              farmID: farm?.id,
              interval: closestSupportedInterval(isoDuration)
            });

            if (correlatedCharts) {
              result[id] = correlatedCharts;
            }

            return result;
          },
          {} as Record<DataDescriptorEntity["id"], Optional<Correlations>>
        )
      : {}
  );

  return (
    <Skeleton active loading={isLoading} className={styles.Skeleton}>
      {_.isEmpty(correlatedCharts) ? (
        <Row>
          <Col flex={1} className={styles.Wrapper}>
            <NoCorrelations
              className={styles.NoCorrelations}
              description={t(chart.error.noCorrelations, { suggestionsEnabled })}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col flex={1} className={styles.Wrapper}>
            <Row gutter={16} wrap={false}>
              <Col>
                <WithSuperScript
                  content={
                    <>
                      {t(description)}
                      <br />
                      <br />
                      <i>{t(note)}</i>
                    </>
                  }
                >
                  {t(chart.correlationHeader)}
                </WithSuperScript>
              </Col>
              <Col>
                <Tag color="#595959">{t(markedBeta)}</Tag>
              </Col>
            </Row>
            {_.map(correlatedCharts, (charts, id) => (
              <Row gutter={16} key={id} className={styles.Table}>
                <Col span={24} className={styles.RelatedTitle}>
                  <Text>Available correlations for your selected chart: </Text>
                  <Text strong>
                    {t<string>(descriptor.displayName(dataDescriptorList[id].dataProperties.typeName), {
                      case: null,
                      variant: null,
                      makeDefaultValue: true
                    })}
                  </Text>
                </Col>
                <Col span={24} className={styles.Metrics}>
                  <TableHeader />
                  {_.map(
                    _.take(_.toArray(correlatedCharts[id]), 3),
                    ({ dataDescriptorID, correlationValue, pctChange }) => {
                      const correlationType = CorrelationMetric.CorrelationType[correlationDelta(pctChange)];

                      const {
                        dataProperties: { typeName }
                      } = dataDescriptors[dataDescriptorID];

                      const title = t<string>(chartTitle(typeName), { makeDefaultValue: true });
                      const absoluteValue = Math.abs(correlationValue);
                      const strength = correlationStrength(absoluteValue);

                      return (
                        <CorrelationMetric
                          key={dataDescriptorID}
                          className={styles.Entry}
                          onClick={() => {
                            /* istanbul ignore next */
                            trackEvent(GAActions.addCorrelationToComparison);

                            onAdd(dataDescriptorID);
                          }}
                          correlationType={correlationType}
                          trend={t(trend, { correlationType })}
                          correlation={t(correlation, { strength })}
                          correlationPercent={strength}
                          title={title}
                          extra={
                            <Button className={styles.AddChartLink} type="link">
                              {t<string>(add)}
                            </Button>
                          }
                        />
                      );
                    }
                  )}
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      )}
    </Skeleton>
  );
};
