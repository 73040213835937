import { FC } from "react";
import * as _ from "lodash/fp";
import { Column as AntColumn } from "@ant-design/charts";

import { withChartWrapper, withSeriesColor } from "../../../hocs";
import { useColumnConfig } from "./useColumnConfig";

/**
 * Column Component
 * @returns
 */
const ColumnComponent: FC = () => {
  const { config } = useColumnConfig();
  return <AntColumn {...config} />;
};

/**
 * Full Column chart
 */
export const Column: FC = _.compose(withChartWrapper, withSeriesColor)(ColumnComponent);
