export const settings = {
  _root: "/settings",
  insights: {
    _root: "/insights",
    farm: {
      _root: "/:farmID",
      enable: "/enable",
      insight: {
        _root: "/:dataDescriptorID",
        edit: { _root: "/edit", group: "/:group" }
      }
    }
  },
  notifications: "/notifications",
  preferences: "/preferences",
  details: "/details",
  password: "/password",
  logout: "/logout"
};
