import { memoize } from "@ctra/utils";
import { Ajinomoto } from "@ctra/components";

import { GenericInsightBase } from "./Base";

/**
 * Individual blood test insight for Ajinomoto
 */
class AjinomotoIndividualBloodTestInsight extends GenericInsightBase {
  @memoize
  getMetadata(): {
    tagNumber: number;
    bloodTest: string;
    dueDate: string;
    bloodDate: string;
  } {
    const { tagNumber, bloodTest, dueDate, bloodDate } = super.getMetadata();

    return {
      tagNumber,
      bloodTest,
      bloodDate,
      dueDate
    };
  }

  @memoize
  getComponents(): ReturnType<typeof GenericInsightBase.prototype.getComponents> {
    return {
      ...super.getComponents(),
      Icon: Ajinomoto,
      ListIcon: Ajinomoto
    };
  }
}

export { AjinomotoIndividualBloodTestInsight };
