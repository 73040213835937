import { FC, useState, createContext, useContext, useEffect } from "react";
import * as _ from "lodash";

import { UnitSystem, Optional, Nullable } from "@ctra/utils";

import {
  SupportedLocaleKeys,
  defaultLocale,
  storeAppLocale,
  loadAppLocale,
  useTranslation,
  getStorageKey
} from "@ctra/i18n";

import { useUserPreferences } from "../UserPreferencesContext";

/**
 * key for local storage of unit system
 */
const unitSystemKey = `${getStorageKey({ persist: true })}.localization`;

/**
 * Load unit system from local storage or returns a default
 * @returns unitSystem
 */
export const loadUnitSystem = (): UnitSystem =>
  (localStorage.getItem(unitSystemKey) as UnitSystem) || UnitSystem.imperial;

/**
 * Localization date format defaults per locale
 * @type {{"nl-NL": any, "en-US": string, "es-MX": any}}
 */
const formats: Record<SupportedLocaleKeys, Optional<string>> = {
  "en-US": "MM/DD/YYYY",
  "es-MX": void 0,
  "nl-NL": void 0
};

/**
 * set unit system within local storage
 * @param unitSystem
 */
const storeUnitSystem = (unitSystem: UnitSystem): void => localStorage.setItem(unitSystemKey, unitSystem);

interface ContextType {
  unitSystem: UnitSystem;
  locale: SupportedLocaleKeys;
  dateFormat: Optional<string>;
  updateUnitSystem: (unit: UnitSystem) => void;
  updateLocale: (locale: SupportedLocaleKeys) => void;
  meta: {
    isLoading: boolean;
  };
}

/**
 * Make default context for unit system
 */
const DefaultContext = createContext<ContextType>({
  unitSystem: loadUnitSystem(),
  locale: loadAppLocale(),
  updateUnitSystem: _.noop,
  updateLocale: _.noop,
  dateFormat: formats[defaultLocale],
  meta: {
    isLoading: true
  }
});

/**
 * Provide the localization context to the translation system and the app
 * @param children
 * @private
 */
const _LocalizationContextProvider: FC = ({ children }) => {
  const [unitSystem, setUnitSystem] = useState<UnitSystem>(loadUnitSystem());
  const [locale, setLocale] = useState<SupportedLocaleKeys>(loadAppLocale());
  const [tz, setTz] = useState<Nullable<string>>(null);
  const { setTranslationContext } = useTranslation();

  const {
    api: { setUserPreferences },
    meta: { isLoading, isUpdating },
    preferences
  } = useUserPreferences();

  /**
   * update according to user preferences
   */
  useEffect(() => {
    if (!_.isEmpty(preferences)) {
      const { unitSystem, locale, timezone } = preferences;

      setUnitSystem(unitSystem);
      storeUnitSystem(unitSystem);
      setLocale(loadAppLocale(locale));
      setTz(timezone);
    }
  }, [preferences]);

  /**
   * Update translation context whenever unit system changes
   */
  useEffect(() => {
    setTranslationContext({ unitSystem });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitSystem]);

  /**
   * Set unit system in the app:
   * set local state, local storage and user preferences
   * @param {UnitSystem} unitSystem
   */
  const updateUnitSystem = (unitSystem: UnitSystem): void => {
    setUnitSystem(unitSystem);
    storeUnitSystem(unitSystem);
    setUserPreferences({ unitSystem, locale, timezone: tz });
  };

  /**
   * Set locale in the app:
   * set local state, local storage and user preferences
   * @param {SupportedLocaleKeys} locale
   */
  const updateLocale = (locale: SupportedLocaleKeys) => {
    setLocale(loadAppLocale(locale));
    storeAppLocale(locale);
    setUserPreferences({ locale, unitSystem, timezone: tz });
  };

  return (
    <DefaultContext.Provider
      value={{
        unitSystem,
        dateFormat: formats[locale],
        locale,
        updateUnitSystem,
        updateLocale,
        meta: {
          isLoading: isLoading || isUpdating
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const LocalizationContext = {
  Provider: _LocalizationContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook to get the unit system and setter from within the context
 */
export const useLocalization = (): ContextType => useContext(DefaultContext);
