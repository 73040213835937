import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as _ from "lodash";
import { PlotEvent } from "@ant-design/charts";
import Sticky from "react-sticky-el";

import {
  CorrelationList as CorrelationsList,
  DataDescriptorEntity,
  Enterprise,
  EnterpriseAppState,
  Charts,
  ExtendedEventEntity,
  EventSourceType
} from "@ctra/api";

import {
  Charts as ChartUI,
  CtraLayout,
  SwitchWithLabel,
  Row,
  Col,
  notification,
  Button,
  RollbackOutlined,
  Typography
} from "@ctra/components";

import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { useGoogleAnalytics } from "@ctra/analytics";

import { useFarm } from "@farms";
import { DataDictionaryProvider } from "@base";
import { Chart, ChartAPIContext, ChartFilterContext } from "@chart";
import { alignLegends, resetLegends, GAActions } from "@analytics";
import { EventListContext } from "@events";
import { Routes } from "@routes";

import { EventList } from "../../../../chart/components/EventList";
import { LayoutProvider } from "../../../providers";
import { SectionToolbar } from "../../";
import { ChartPicker } from "../ChartPicker";
import { FarmDropdown } from "../FarmDropdown";
import { CorrelationList } from "../CorrelationList";
import styles from "./CorrelationReportPage.module.less";

const { WidgetWrapper, ContentWrapper } = CtraLayout;
const { ChartVariant } = ChartUI;
const { Title, Paragraph } = Typography;

/**
 * Correlation report page
 * @return {JSX.Element}
 * @constructor
 */
export const CorrelationReportPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { trackEvent } = useGoogleAnalytics();
  const { farm: farmContext, setFarmContext } = useFarm();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const isoDuration = searchParams.get("isoDuration");
  const initialDataDescriptorID = searchParams.get("dataDescriptorID");

  const [chartList, setChartList] = useState<Array<DataDescriptorEntity["id"]>>(
    _.compact([initialDataDescriptorID])
  );

  const [showEvents, setShowEvents] = useState<Record<EventSourceType, boolean>>(
    _.mapValues({ ..._.invert(_.toPlainObject(EventSourceType)) }, () => false) as Record<
      EventSourceType,
      boolean
    >
  );

  const [eventList, setEventList] = useState<ExtendedEventEntity[]>([]);

  const {
    analytics: {
      correlations: { pageTitle, pageDescription, chart, events, goBack }
    }
  } = Content;

  /**
   * Correlations on different farms
   */
  const correlations = useSelector<EnterpriseAppState, CorrelationsList>((state) =>
    Enterprise.entities.getCorrelations(state)
  );

  /**
   * Fetch correlations
   */
  useEffect(() => {
    if (farmContext) {
      if (!_.get(correlations, [farmContext.id, "index"])) {
        dispatch(Charts.actions.fetchFarmCorrelations.start(farmContext.id));
      }

      if (chartList.length > 0) {
        const [excluded, current] =
          chartList.length === 1 ? [void 0, _.first(chartList)] : [_.initial(chartList), _.last(chartList)];

        if (current) {
          dispatch(Charts.actions.fetchChartCorrelations.start(farmContext.id, current, excluded));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartList, farmContext]);

  /**
   * Add data descriptor to the chartlist
   * @param id
   */
  const addDataDescriptor = (id: DataDescriptorEntity["id"]) => {
    if (chartList.includes(id)) {
      notification.error({
        message: t(chart.error.alreadyUsed)
      });
    } else {
      setChartList([...chartList, id]);
    }
  };

  return farmContext ? (
    <DataDictionaryProvider>
      {(dataDescriptors, { isLoading: dataDictionaryLoading }) => (
        <EventListContext.Provider farmID={farmContext.id}>
          <LayoutProvider farmID={farmContext.id}>
            {(_layout, { isLoading: layoutLoading }) => (
              <ChartFilterContext.Provider
                isoDuration={isoDuration || "P3M"}
                durationOptions={["P1W", "P1M", "P3M", "P6M", "P1Y"]}
              >
                <WidgetWrapper
                  color="neutral"
                  loading={layoutLoading || dataDictionaryLoading}
                  title={
                    <div>
                      <Title level={5}>{t<string>(pageTitle)}</Title>
                      <Paragraph>{t<string>(pageDescription)}</Paragraph>
                    </div>
                  }
                  toolbar={
                    <Row gutter={[16, 0]} align="middle">
                      <Col>
                        <Button
                          type="secondary"
                          icon={<RollbackOutlined />}
                          onClick={() => Enterprise.history.push(Routes.app.analytics.index)}
                        >
                          {t(goBack)}
                        </Button>
                      </Col>
                    </Row>
                  }
                >
                  <Sticky stickyClassName={styles.Sticky} topOffset={-20}>
                    <ContentWrapper className={styles.ChartPicker}>
                      <ChartPicker
                        defaultValue={_.defaultTo(initialDataDescriptorID, void 0)}
                        onChange={(dataDescriptorID) => {
                          /* istanbul ignore next */
                          trackEvent(GAActions.addChartToList);

                          addDataDescriptor(dataDescriptorID);
                        }}
                      />
                    </ContentWrapper>
                  </Sticky>
                </WidgetWrapper>
                {!_.isEmpty(chartList) && (
                  <WidgetWrapper
                    omitHeader
                    loading={layoutLoading || dataDictionaryLoading}
                    className={styles.ChartWidget}
                  >
                    <ContentWrapper padded={false} className={styles.HideOverflow}>
                      <Row className={styles.Options} justify="end" align="middle">
                        <EventListContext.Consumer>
                          {({ events: eventList }) =>
                            _.size(eventList) > 0 && (
                              <Col>
                                <SwitchWithLabel
                                  defaultChecked={_.every(showEvents)}
                                  onChange={(checked) =>
                                    setShowEvents((current) => _.mapValues(current, () => checked))
                                  }
                                  disabled={!chartList.length}
                                  size="small"
                                  label={t(events)}
                                />
                              </Col>
                            )
                          }
                        </EventListContext.Consumer>
                        <Col>
                          <SectionToolbar />
                        </Col>
                      </Row>
                      <Row wrap={false}>
                        <Col span={_.isEmpty(eventList) ? 24 : 20}>
                          <Row className={styles.Chart}>
                            {_.map(chartList, (dataDescriptorID, idx, collection) => (
                              <ChartAPIContext.Provider
                                dataDescriptorID={dataDescriptorID}
                                farmID={farmContext?.id}
                              >
                                <Col span={24}>
                                  <Chart
                                    variant={ChartVariant.V2}
                                    viewOptions={{
                                      showEvents,
                                      zoomEnabled: false,
                                      showSupportedViews: true,
                                      showToolbar: false
                                    }}
                                    handleClose={() => {
                                      setChartList(_.without(chartList, dataDescriptorID));
                                      resetLegends();
                                    }}
                                    config={{
                                      onReady: (plot) => {
                                        plot.on(
                                          "afterrender",
                                          _.throttle(() => {
                                            if (collection.length > 1) {
                                              alignLegends();
                                            }
                                          }, 100)
                                        );

                                        plot.on("element:click", (event: PlotEvent) => {
                                          const shape = _.get(event, ["data", "shape"]);
                                          const eventList = _.get(event, ["data", "data", "events"]);

                                          if (shape === "farm-event-point") {
                                            setEventList(() => eventList);
                                          }
                                        });
                                      }
                                    }}
                                  />
                                </Col>
                              </ChartAPIContext.Provider>
                            ))}
                          </Row>
                        </Col>
                        <Col span={4} className={styles.EventList}>
                          {_.isEmpty(eventList) ? null : (
                            <EventList data={eventList} handleClose={() => setEventList([])} />
                          )}
                        </Col>
                      </Row>
                      <CorrelationList
                        dataDescriptorList={dataDescriptors}
                        onAdd={(dataDescriptorID) => addDataDescriptor(dataDescriptorID)}
                        dataDescriptorID={_.last(chartList) as string}
                      />
                    </ContentWrapper>
                  </WidgetWrapper>
                )}
              </ChartFilterContext.Provider>
            )}
          </LayoutProvider>
        </EventListContext.Provider>
      )}
    </DataDictionaryProvider>
  ) : (
    <ContentWrapper className={styles.HideOverflow}>
      <FarmDropdown onChange={(id) => setFarmContext(id)} />
    </ContentWrapper>
  );
};
