import { FC } from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Menu,
  BulbOutlined,
  RightOutlined,
  NotificationOutlined,
  MessageOutlined,
  ExportOutlined,
  Grid,
  InfoCircleOutlined,
  MailOutlined
} from "@ctra/components";

import { Session } from "@ctra/api";
import { Enterprise as Content, Enterprise, useTranslation } from "@ctra/i18n";

import { Routes } from "@routes";
import {
  UserPreferences,
  UserPreferencesVariant,
  intercomLauncherClassName,
  GACategories,
  useUserPreferences
} from "@base";

import styles from "./SettingsMenu.module.less";

/**
 * Sidebar menu for the setting page
 * @constructor
 */
export const SettingsMenu: FC = () => {
  const routes = Routes.app.settings;
  const insights = useRouteMatch(`${routes.insights.index}*`);
  const farmSummaries = useRouteMatch(`${Routes.app.farmSummaries.index}*`);

  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { md } = Grid.useBreakpoint();

  const {
    preferences: {
      sandbox: { isEnabled: isSandboxEnabled }
    }
  } = useUserPreferences();

  const {
    settings: { navigation, logout, type }
  } = Enterprise;

  return (
    <Menu mode="inline" selectedKeys={[pathname]} className={styles.Menu}>
      <Menu.ItemGroup>
        {!md && (
          <Menu.Item icon={<MailOutlined />} key={farmSummaries?.url || "farm-summaries"}>
            <Link
              to={Routes.app.farmSummaries.index}
              data-gtm-category={GACategories.sidebarMenu}
              data-gtm-action="Copilot"
            >
              {t<string>(Enterprise.navigation.main.farmSummaries)}
            </Link>
          </Menu.Item>
        )}
      </Menu.ItemGroup>
      <Menu.ItemGroup title={t<string>(type.insight)}>
        <Menu.Item icon={<BulbOutlined />} key={insights?.url}>
          <Link
            to={routes.insights.index}
            data-gtm-category={GACategories.sidebarMenu}
            data-gtm-action="Insight settings"
          >
            {t<string>(navigation.insights)}
          </Link>
          {!md && <RightOutlined />}
        </Menu.Item>
        <Menu.Item icon={<NotificationOutlined />} key={routes.notifications}>
          <Link
            to={routes.notifications}
            data-gtm-category={GACategories.sidebarMenu}
            data-gtm-action="Notification settings"
          >
            {t<string>(navigation.notifications)}
          </Link>
          {!md && <RightOutlined />}
        </Menu.Item>
      </Menu.ItemGroup>
      {!md && (
        <Menu.ItemGroup title={t<string>(type.help)}>
          <Menu.Item
            icon={<MessageOutlined />}
            key={"support"}
            className={intercomLauncherClassName}
            data-gtm-category={GACategories.sidebarMenu}
            data-gtm-action="Intercom"
            onClick={() => {
              /* istanbul ignore next */
              window.Intercom("show");
            }}
          >
            {t<string>(Enterprise.navigation.main.support)}
            <RightOutlined />
          </Menu.Item>
          <Menu.Item icon={<InfoCircleOutlined />} key={Routes.app.helpCenter.index}>
            <a
              data-gtm-category={GACategories.sidebarMenu}
              data-gtm-action="Help Center"
              href="https://intercom.help/connecterra"
              rel="noopener noreferrer"
              onClick={() => {
                window.open("https://intercom.help/connecterra", "_blank");
              }}
              target={"_blank"}
            >
              {t<string>(Content.navigation.main.helpCenter)}
            </a>
          </Menu.Item>
        </Menu.ItemGroup>
      )}
      <Menu.ItemGroup title={t<string>(navigation.account)}>
        {!md && (
          <Link
            to={routes.preferences}
            data-gtm-category={GACategories.sidebarMenu}
            data-gtm-action="User preferences"
          >
            <UserPreferences variant={UserPreferencesVariant.settings} />
          </Link>
        )}
        <Menu.Item
          icon={<ExportOutlined />}
          key={routes.logout}
          onClick={() => {
            dispatch(Session.actions.logout());
          }}
        >
          {t<string>(logout)}
          {!md && <RightOutlined />}
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
};
