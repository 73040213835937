import { FC } from "react";

/**
 * High risk cow insight body
 * @return {React.ReactNode}
 * @constructor
 */
export const AjinomotoHighRiskCowInsightBody: FC = () => {
  return null;
};
