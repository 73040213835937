import { Nullable } from "@ctra/utils";

import { FarmEntity } from "../farms";
import { DataDescriptorEntity } from "../data-dictionary";
import { ChartFilters, ChartTimePeriod, ISODuration } from "../chart";

export enum LayoutType {
  farm = "farmPage",
  dashboard = "mainDashboard",
  chartContainer = "chartContainer",
  section = "section"
}

export interface ChartContainerReference {
  id: string;
  schema: LayoutType.chartContainer;
}

export interface ChartContainerEntity extends ChartContainerReference {
  dataDescriptorID: DataDescriptorEntity["id"];
}

export interface SectionReference {
  id: string;
  schema: LayoutType.section;
}

export interface SectionEntity extends SectionReference {
  children: Array<ChartContainerReference | SectionReference>;
  flags: {
    isNew?: boolean;
  };
  title: string;
  slug: string;
  defaultDisplayInterval: ISODuration;
}

export type ExtendedSectionEntity = SectionEntity & {
  children: Array<ChartContainerEntity | ExtendedSectionEntity>;
};

export type ExtendedFlatSectionEntity = SectionEntity & {
  children: Array<ChartContainerEntity>;
};

export interface LayoutEntity {
  id: string;
  flags: {
    isDashboard: boolean; // added via FE to mark dashboards
    isDefault: boolean;
  };
  subscriptions: {
    farm: Nullable<Array<FarmEntity["id"]>>;
    userGroup: Array<number>;
  };
  children: Array<ChartContainerReference | SectionReference>;
}

export type ExtendedLayoutEntity = LayoutEntity & {
  children: Array<ChartContainerEntity | ExtendedSectionEntity>;
};

export type LayoutList = Record<FarmEntity["id"] | string, LayoutEntity>;
export type ExtendedLayoutList = Record<FarmEntity["id"] | string, ExtendedLayoutEntity>;
export type SectionList = Record<SectionEntity["id"], SectionEntity>;
export type ExtendedSectionList = Record<SectionEntity["id"], ExtendedSectionEntity>;
export type ExtendedFlatSectionList = Record<SectionEntity["id"], ExtendedFlatSectionEntity>;
export type ChartContainerList = Record<ChartContainerEntity["id"], ChartContainerEntity>;

export type SectionFilterSource = {
  timePeriod?: ChartTimePeriod;
  isoDuration?: Nullable<ISODuration>;
} & Record<FarmEntity["id"], ChartFilters>;

export type SectionFilter = ChartFilters & {
  timePeriod?: ChartTimePeriod;
  isoDuration?: Nullable<ISODuration>;
};

export type SectionFilterList = Record<SectionEntity["id"], SectionFilterSource>;

export interface LayoutState {
  entities: LayoutList;
  sections: SectionList;
  filters: SectionFilterList;
  chartContainers: ChartContainerList;
}
