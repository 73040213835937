import { FC, useMemo, useState } from "react";
import * as _ from "lodash";

import { Nullable, TS } from "@ctra/utils";
import type { ColumnsType } from "@ctra/components";
import { Row, Col, Button, Table, Typography, Skeleton } from "@ctra/components";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { GenericInsightEntity } from "@ctra/api";

import { GenericInsight, useInsightlist, AjinomotoIndividualBloodTestInsight, testIds } from "@insights";
import styles from "./BloodTestTable.module.less";

const { Text } = Typography;

/**
 * @todo move to either to the api or to processing
 */
enum BloodScreeningResult {
  low = "low",
  medium = "medium",
  high = "high"
}

/**
 * Map string-based results to a proper structure
 * @type {{"High risk": BloodScreeningResult, "Middle risk": BloodScreeningResult, "Low risk": BloodScreeningResult, "No result yet": null}}
 */
const riskLevelMap: Record<string, string | null> = {
  "No result yet": null,
  "Low risk": BloodScreeningResult.low,
  "Middle risk": BloodScreeningResult.medium,
  "High risk": BloodScreeningResult.high
};

/**
 * @todo move to either to the api or to processing
 */
interface DataType {
  key: number;
  cowTagNumber: number;
  calvingDate: string;
  bloodScreeningResult: Nullable<{
    riskLevel: BloodScreeningResult;
    testDate: string;
  }>;
}

/**
 * Table of cows to perform blood test for
 * @return {React.ReactNode}
 * @constructor
 */
export const BloodTestTable: FC = () => {
  const [showingAll, setShowingAll] = useState<boolean>(false);
  const showAllThreshold = 10;

  const {
    insights: {
      extensions: {
        bloodScreeningSchedule: {
          table: {
            showMore,
            columns: columnHeaders,
            testResults: { risk, untested }
          }
        }
      }
    }
  } = Content;

  const { t } = useTranslation();

  const {
    insights,
    meta: { isLoading }
  } = useInsightlist();

  /**
   * Set up the column structure which matches the data
   * @type {({dataIndex: string, title: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined | ((props: ColumnTitleProps<DataType>) => React.ReactNode), render: (text) => JSX.Element, key: string} | {dataIndex: string, title: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined | ((props: ColumnTitleProps<DataType>) => React.ReactNode), key: string} | {dataIndex: string, title: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined | ((props: ColumnTitleProps<DataType>) => React.ReactNode), key: string})[]}
   */
  const columns: ColumnsType<DataType> = [
    {
      title: t<string>(columnHeaders.cowTagNumber),
      dataIndex: "cowTagNumber",
      key: "cowTagNumber",
      render: (text) => <Text className={styles.CowTagNumber}>{text}</Text>
    },
    {
      title: t<string>(columnHeaders.calvingDate),
      dataIndex: "calvingDate",
      key: "calvingDate",
      render: (date) => <Text>{date ? TS.asMoment(date).format("LL") : "-"}</Text>
    },
    {
      align: "right",
      title: t<string>(columnHeaders.screeningResult),
      dataIndex: "bloodScreeningResult",
      key: "bloodScreeningResult",
      render: (bloodTestDetails) => {
        const { riskLevel, testDate } = _.defaultTo(bloodTestDetails, {});

        return riskLevel && testDate ? (
          <>
            <Text className={styles[`${_.upperFirst(riskLevel)}Risk`]}>
              {t<string>(risk[riskLevel as BloodScreeningResult])}
            </Text>
            <br />
            <Text type="secondary" className={styles.Small}>
              {TS.asMoment(testDate).format("LLL")}
            </Text>
          </>
        ) : (
          <Text type="secondary">{t<string>(untested)}</Text>
        );
      }
    }
  ];

  /**
   * Map the insight list to table data
   * @type {DataType[]}
   */
  const data = useMemo(() => {
    return _.orderBy(
      _.map<GenericInsightEntity, DataType>(insights, (insight) => {
        const { id } = insight;
        const api = GenericInsight.create<typeof AjinomotoIndividualBloodTestInsight>(insight);
        const { tagNumber, bloodTest, dueDate, bloodDate } = api.getMetadata();

        return {
          key: id,
          cowTagNumber: tagNumber,
          calvingDate: dueDate,
          bloodScreeningResult: riskLevelMap[bloodTest]
            ? {
                riskLevel: riskLevelMap[bloodTest] as BloodScreeningResult,
                testDate: bloodDate
              }
            : null
        };
      }),
      ({ cowTagNumber }) => {
        return _.isString(cowTagNumber)
          ? isNaN(parseFloat(cowTagNumber))
            ? cowTagNumber
            : parseFloat(cowTagNumber)
          : cowTagNumber;
      }
    );
  }, [insights]);

  return (
    <Skeleton active loading={isLoading}>
      <Row gutter={[16, 16]} justify="center" align="middle">
        <Col span={24}>
          <Table
            pagination={false}
            size="small"
            columns={columns}
            dataSource={showingAll ? data : _.slice(data, 0, showAllThreshold)}
            className={styles.Table}
            data-testid={testIds.extensions.bloodScreeningSchedule.table}
          />
        </Col>
        {!showingAll && data.length > showAllThreshold && (
          <Col span={24} style={{ textAlign: "center" }}>
            <Button onClick={() => setShowingAll(true)}>{t<string>(showMore)}</Button>
          </Col>
        )}
      </Row>
    </Skeleton>
  );
};
