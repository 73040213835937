import { useLocation, useParams } from "react-router-dom";
import React, { FC, useState } from "react";
import * as _ from "lodash";

import {
  CtraLayout,
  Typography,
  Collapse,
  List,
  Row,
  Col,
  Button,
  Divider,
  PlusOutlined,
  Analytics,
  BarChartOutlined,
  Tabs,
  UnorderedListOutlined
} from "@ctra/components";

import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { Breadcrumbs, Enterprise, MetricEntity } from "@ctra/api";
import { GAEvent } from "@ctra/analytics";

import { useFarm } from "@farms";
import { useDataDictionary } from "@base";
import { CompiledRoutes } from "@routes";
import { GACategories, useSavedCharts } from "@analytics";

import { MetricPicker } from "../MetricPicker";
import { ListItem } from "../ListItem";

import styles from "./MetricSelectorPage.module.less";
import { MiniChart } from "../MiniChart";

const { WidgetWrapper, ContentWrapper } = CtraLayout;
const { Paragraph } = Typography;
const {
  components: { Breadcrumb }
} = Breadcrumbs;

const {
  V3: { CategoryIcon }
} = Analytics;

const {
  analytics: {
    config: {
      search: { pageDescription, cta, title }
    },
    v3: { metricSelector }
  },
  layouts: {
    section: { title: layoutTitle }
  }
} = Content;

/**
 * Metric selector page
 * @return {JSX.Element}
 * @constructor
 */
export const MetricSelectorPage: FC = () => {
  const { t } = useTranslation();
  const [view, setView] = useState("chart");
  const { state } = useLocation();
  const { farm } = useFarm();

  const [currentMetric, setCurrentMetric] = useState<MetricEntity["id"]>();
  const { dashboard } = useParams<{ dashboard: string }>();

  const {
    dataDescriptors: dataDictionary,
    groups: { byFamilyCategory: groupedMetrics },
    meta: { isLoading: dataDictionaryLoading, enums }
  } = useDataDictionary();

  const { savedCharts } = useSavedCharts();
  const groupName = _.find(savedCharts, { id: dashboard })?.groupName;

  const handleClick = (metricID: string, name?: string) => {
    GAEvent(GACategories.configurator, "Select metric and route to config", JSON.stringify({ name }));

    Enterprise.history.push({
      pathname: CompiledRoutes.app.analytics.dashboard.addChart.metric({ dashboard, metricID }),
      state
    });
  };

  /**
   * Get BE enums for category which contains the order in which they should be mapped
   */
  const categoryOrder = enums["MetricCategory"];

  const sortedGroupedMetrics = _.keys(groupedMetrics).sort((a, b) => {
    const indexA = categoryOrder?.indexOf(a);
    const indexB = categoryOrder?.indexOf(b);
    return indexA - indexB;
  });

  return (
    <WidgetWrapper
      color="neutral"
      loading={dataDictionaryLoading}
      title={<Paragraph>{t<string>(pageDescription)}</Paragraph>}
    >
      {groupName && (
        <Breadcrumb path={CompiledRoutes.app.analytics.dashboard.index({ dashboard })} title={groupName} />
      )}
      <Tabs onChange={setView}>
        <Tabs.TabPane
          tab={
            <>
              <BarChartOutlined />
              {t(metricSelector.view, { variant: "chart" })}
            </>
          }
          key="chart"
        />
        <Tabs.TabPane
          tab={
            <>
              <UnorderedListOutlined />
              {t(metricSelector.view, { variant: "list" })}
            </>
          }
          key="list"
        />
      </Tabs>
      <ContentWrapper className={styles.ChartPicker} padded={false}>
        <Paragraph>{t(title)}</Paragraph>
        <Divider />
        <Row gutter={[16, 0]} className={styles.MetricPicker}>
          <Col flex={1}>
            <MetricPicker value={currentMetric} onChange={setCurrentMetric} />
          </Col>
          <Col>
            <Button
              size="large"
              disabled={!currentMetric}
              type="primary"
              onClick={() => currentMetric && handleClick(currentMetric)}
              icon={<PlusOutlined />}
            >
              {t(cta)}
            </Button>
          </Col>
        </Row>
      </ContentWrapper>
      <Collapse className={styles.Collapse} expandIconPosition="right">
        {_.map(sortedGroupedMetrics, (category, idx) => {
          const perCategory = _.get(groupedMetrics, category);

          /**
           * Set the structure of the data with id and name, use family name if belongs to a metric family
           */
          const dataSource = _.map(_.keys(perCategory), (key) => {
            const lookup = [key, 0];
            const familyName = _.get(perCategory, [...lookup, "family"]);
            return {
              id: _.defaultTo(familyName, _.get(perCategory, [...lookup, "id"])),
              name: _.defaultTo(familyName, _.get(perCategory, [...lookup, "name"]))
            };
          });

          /**
           * Order the sources by family index
           */
          const orderedSources = _.orderBy(_.values(perCategory), "familyIndex", "asc");

          /**
           * Organise metric/source/variant combination
           */
          const chartDataSource = _.map(orderedSources, (dataSource) => {
            const firstSource = _.first(dataSource);

            const metric = firstSource?.family;
            const source = firstSource?.id;
            const variants = firstSource?.variants;

            const farmVariant = _.find(variants, (variant) =>
              _.isEqual(_.get(dataDictionary, [variant, "dataProperties", "type"]), "farm")
            );

            const variant = !farm ? farmVariant : _.first(variants);

            /**
             * If family is found that becomes metric, otherwise the source is the metric
             */
            if (metric) {
              return {
                metric,
                source,
                variant
              };
            } else {
              return { metric: source, variant };
            }
          });

          return (
            <Collapse.Panel
              data-gtm-category={GACategories.configurator}
              data-gtm-action="Expand category"
              data-gtm-info={JSON.stringify({ category })}
              header={
                <Row className={styles.Header} gutter={[24, 0]} align="middle">
                  <CategoryIcon category={category} />
                  <Col>{t(layoutTitle(category), { makeDefaultValue: true })}</Col>
                </Row>
              }
              key={idx}
            >
              {_.isEqual("list", view) ? (
                <List
                  dataSource={dataSource}
                  renderItem={(item) => (
                    <ListItem item={item} onClick={() => handleClick(item.id, item.name)} />
                  )}
                />
              ) : (
                <Row gutter={[20, 20]} className={styles.MiniContainer}>
                  {_.map(chartDataSource, ({ metric, source, variant }) => (
                    <Col span={6}>
                      {metric && variant && <MiniChart variant={variant} source={source} metric={metric} />}
                    </Col>
                  ))}
                </Row>
              )}
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </WidgetWrapper>
  );
};
