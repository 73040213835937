import { FC } from "react";
import * as _ from "lodash";

import { Col, List, PlusCircleOutlined, Popover, Row, Space, Tag } from "@ctra/components";
import { Enterprise, useTranslation } from "@ctra/i18n";
import { isProduction } from "@ctra/utils";

import { useDataDictionary } from "@base";

import styles from "./ListItem.module.less";

/**
 * List item for the category list displayed on config search page
 * @param item
 * @returns
 */
export const ListItem: FC<{ item: { id: string; name: string }; onClick: () => void }> = ({
  item,
  onClick
}) => {
  const { t } = useTranslation();
  const {
    groups: { projectionMetrics }
  } = useDataDictionary();

  const {
    analytics: {
      config: {
        metric: { displayName },
        projections
      }
    }
  } = Enterprise;

  return (
    <List.Item onClick={onClick}>
      <Row justify="space-between" align="middle" className={styles.Item}>
        <Col>
          <Space wrap={false} size={"middle"}>
            <Col>
              {isProduction() ? (
                <PlusCircleOutlined className={styles.Icon} />
              ) : (
                <Popover content={item.id} trigger="hover">
                  <PlusCircleOutlined className={styles.Icon} />
                </Popover>
              )}
            </Col>
            <Col>{t(displayName(item.name), { makeDefaultValue: true })}</Col>
          </Space>
        </Col>
        <Col>
          {_.includes(projectionMetrics, item.id) && <Tag className={styles.Tag}>{t(projections)}</Tag>}
        </Col>
      </Row>
    </List.Item>
  );
};
