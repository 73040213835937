/* istanbul ignore file */

import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Switch, Route, Redirect } from "react-router-dom";
import * as Sentry from "@sentry/react";
import TagManager from "react-gtm-module-custom-domain";

/**
 * Make sure this stays high so the CSS actually gets generated for everything.
 */
import "./index.less";

import { init as i18nInit, Enterprise as EnterpriseI18N, TranslationContext } from "@ctra/i18n";
import { Enterprise, PersistGate, purgeStaleData as purgeStaleAPIData } from "@ctra/api";
import { isProduction, purgeStaleData as purgeStaleUtilsData } from "@ctra/utils";
import { GA, GAContext } from "@ctra/analytics";
import { Row, Spin, SpinnerContext, LoadingOutlined } from "@ctra/components";

import { Routes } from "@routes";
import { AuthenticateWithToken, ProtectedRoute, GACategories, UserContext, SessionContext } from "@auth";
import { FarmContext, FarmListContext } from "@farms";

import {
  InvitationContext,
  LocalizationContext,
  UserPreferencesContext,
  CookieConsent,
  UserListContext,
  AlertContext
} from "@base";

import { initSentry } from "./utils/sentry";
import { redirectURL } from "./utils/url";
import { purgeStaleData as purgeStaleAppData } from "./utils/versioning";
import * as serviceWorker from "./serviceWorker";
import "./testing";

const ForgotPasswordPage = lazy(() => import("./modules/auth/components/ForgotPasswordPage"));
const ResetPasswordPage = lazy(() => import("./modules/auth/components/ResetPasswordPage"));
const AcceptInvitePage = lazy(() => import("./modules/auth/components/AcceptInvitePage"));
const LoginPage = lazy(() => import("./modules/auth/components/LoginPage"));
const App = lazy(() => import("./modules/base/components/App"));

const SentryRoute = Sentry.withSentryRouting(Route);

/**
 * Generic spinner (loading nothing)
 */
const spinner = (
  <Row justify="center" align="middle" style={{ height: "100vh", width: "100vw" }}>
    <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
  </Row>
);

/**
 * Purge any stale data from the local storage and session storage
 */
purgeStaleAPIData();
purgeStaleAppData();
purgeStaleUtilsData();

/**
 * Google Tag Manager args
 * @type {{gtmId: string}}
 */
const tagManagerArgs = {
  customURL: "https://ua.connecterra.ai/gtm.js",
  gtmId: isProduction() ? "GTM-WQTMJKLB" : "GTM-NGLVQPB2"
};

TagManager.initialize(tagManagerArgs);

/**
 * Show a spinner while the loco is loading
 */
ReactDOM.render(spinner, document.getElementById("root"));

/**
 * Init Sentry and Loco
 */
initSentry()
  .then(() => i18nInit(EnterpriseI18N.endpoints))
  .then(() => {
    GA.initialize(isProduction(process.env.REACT_APP_ANALYTICS_ENV) ? "G-5J6RQPTH6D" : "G-BML74PB84C");

    ReactDOM.render(
      <Provider store={Enterprise.store}>
        <PersistGate persistor={Enterprise.persistor}>
          <ConnectedRouter history={Enterprise.history}>
            <AuthenticateWithToken />
            <Suspense fallback={spinner}>
              <Sentry.ErrorBoundary showDialog>
                <TranslationContext.Provider>
                  <CookieConsent />
                  <UserPreferencesContext.Provider>
                    <SpinnerContext.Provider>
                      <LocalizationContext.Provider>
                        <Switch>
                          <SentryRoute path={Routes.auth.login}>
                            <GAContext.Provider value={{ category: GACategories.login }}>
                              <UserContext.Provider>
                                <LoginPage />
                              </UserContext.Provider>
                            </GAContext.Provider>
                          </SentryRoute>
                          <SentryRoute path={Routes.auth.forgotPassword}>
                            <GAContext.Provider value={{ category: GACategories.forgotPassword }}>
                              <ForgotPasswordPage />
                            </GAContext.Provider>
                          </SentryRoute>
                          <SentryRoute path={Routes.auth.resetPassword}>
                            <GAContext.Provider value={{ category: GACategories.resetPassword }}>
                              <ResetPasswordPage />
                            </GAContext.Provider>
                          </SentryRoute>
                          <SentryRoute path={Routes.auth.acceptInvite}>
                            <GAContext.Provider value={{ category: GACategories.acceptInvite }}>
                              <AcceptInvitePage />
                            </GAContext.Provider>
                          </SentryRoute>
                          <SentryRoute
                            path={Routes.auth.signup}
                            render={({ location: { search, hash } }) => {
                              if (window) {
                                window.open(`${redirectURL}/signup${search}${hash}`, "_self");
                              }

                              return null;
                            }}
                          />
                          <SentryRoute
                            path={Routes.auth.websiteSignup}
                            render={({ location: { search, hash } }) => {
                              if (window) {
                                window.open(
                                  isProduction()
                                    ? "https://www.connecterra.ai/signup"
                                    : "https://connecterra-1-0.webflow.io/signup",
                                  "_self"
                                );
                              }

                              return null;
                            }}
                          />
                          <ProtectedRoute
                            path={Routes.app.index}
                            redirect={Routes.auth.login}
                            render={() => (
                              <SessionContext.Provider>
                                <AlertContext.Provider>
                                  <FarmListContext.Provider>
                                    <FarmContext.Provider>
                                      <UserListContext.Provider>
                                        <UserContext.Provider>
                                          <InvitationContext.Provider>
                                            <App />
                                          </InvitationContext.Provider>
                                        </UserContext.Provider>
                                      </UserListContext.Provider>
                                    </FarmContext.Provider>
                                  </FarmListContext.Provider>
                                </AlertContext.Provider>
                              </SessionContext.Provider>
                            )}
                          />
                          <SentryRoute>
                            <Redirect to={Routes.app.overview.index} />
                          </SentryRoute>
                        </Switch>
                      </LocalizationContext.Provider>
                    </SpinnerContext.Provider>
                  </UserPreferencesContext.Provider>
                </TranslationContext.Provider>
              </Sentry.ErrorBoundary>
            </Suspense>
          </ConnectedRouter>
        </PersistGate>
      </Provider>,
      document.getElementById("root")
    );
  })
  .catch((error) => {
    throw error;
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
