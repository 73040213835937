import { FC, ReactElement } from "react";
import * as _ from "lodash";
import { Button, Col, Row } from "antd";
import { FacebookFilled, GoogleCircleFilled, AppstoreFilled } from "@ant-design/icons";

export interface SSOListProps {
  /**
   * click handler
   */
  onClick: (provider: string) => void;
  /**
   * get label for provider
   * @param {string} provider
   * @return {string}
   */
  getLabel: (provider: string) => string;
}

/**
 * SSO List component
 * @param {((event: React.MouseEvent<HTMLElement>) => void) | undefined} onClick
 * @param {(provider: string) => string} getLabel
 * @return {JSX.Element}
 * @constructor
 */
const SSOList: FC<SSOListProps> = ({ onClick, getLabel }) => {
  return (
    <Row gutter={[16, 16]}>
      {_.map<[string, typeof FacebookFilled], ReactElement>(
        [
          ["facebook", FacebookFilled],
          ["google", GoogleCircleFilled],
          ["microsoft", AppstoreFilled]
        ],
        ([provider, Icon]) => (
          <Col span={24} key={provider}>
            <Button
              data-gtm-action={`Use ${_.upperFirst(provider)}`}
              size="large"
              icon={<Icon />}
              block
              onClick={_.partial(onClick, provider)}
            >
              {getLabel(provider)}
            </Button>
          </Col>
        )
      )}
    </Row>
  );
};

export default SSOList;
