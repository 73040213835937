import { AsyncType, createAsyncTypes } from "@ctra/utils";

const ns = "app/layouts";

/**
 * Fetch dashboard layout
 * @category Type
 */
const FETCH_DASHBOARD_LAYOUT: AsyncType = createAsyncTypes(ns, "FETCH_DASHBOARD_LAYOUT");

/**
 * Fetch farm layout
 * @category Type
 */
const FETCH_FARM_LAYOUT: AsyncType = createAsyncTypes(ns, "FETCH_FARM_LAYOUT", {
  primary: "farmID"
});

/**
 * Set the filters for a given section
 * @category Type
 */
const SET_SECTION_FILTER = `${ns}/SET_SECTION_FILTER`;

export default {
  FETCH_DASHBOARD_LAYOUT,
  FETCH_FARM_LAYOUT,
  SET_SECTION_FILTER
};
