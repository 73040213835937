import { ComponentProps, FC, ReactNode, useEffect, useRef } from "react";
import { Timeline, Row, Col, Space } from "antd";
import * as _ from "lodash";
import Markdown from "react-markdown";

import { EventOrigin } from "@ctra/api";
import { classname } from "@ctra/utils";

import { Typography } from "../../../elements";
import EventOriginIcon from "../EventOriginIcon";
import styles from "./TimelineItem.module.less";

const { Text } = Typography;

export interface TimelineItemProps extends ComponentProps<typeof Timeline.Item> {
  /**
   * title
   */
  title: string;
  /**
   * description
   */
  description: string;
  /**
   * date
   */
  date: string;
  /**
   * highlighted
   */
  highlighted?: boolean;
  /**
   * extra
   */
  extra?: React.ReactNode;
  /**
   * click handler
   */
  onClick?: () => void;
  /**
   * event type
   */
  eventOrigin: EventOrigin;
}

/**
 * Farm timeline item
 * @param {string} title
 * @param {string} description
 * @param {string} date
 * @param {boolean | undefined} highlighted
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} extra
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined | (React.ReactElement<any, string | React.JSXElementConstructor<any>> & undefined) | (Iterable<React.ReactNode> & undefined) | (React.ReactPortal & undefined)} children
 * @param {string | undefined} className
 * @param {(() => void) | undefined} onClick
 * @param {EventOrigin} eventOrigin
 * @param {Pick<TimelineItemProps & {children?: React.ReactNode | undefined}, "prefixCls" | "color" | "dot" | "pending" | "position" | "style" | "label">} rest
 * @return {JSX.Element}
 * @constructor
 */
const TimelineItem: FC<TimelineItemProps> = ({
  title,
  description,
  date,
  highlighted,
  extra,
  children,
  className,
  onClick,
  eventOrigin,
  ...rest
}) => {
  const highlightedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (highlighted && highlightedRef.current) {
      highlightedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest"
      });
    }
  }, [highlighted]);

  return (
    <Timeline.Item
      className={classname(
        "ctra-impactTracking-timelineItem",
        styles.TimelineItem,
        highlighted ? styles.Highlighted : null,
        className
      )}
      {...rest}
    >
      <div
        ref={highlightedRef}
        onClick={onClick}
        className={_.isFunction(onClick) ? styles.Clickable : void 0}
      >
        <Row wrap={!!extra} gutter={[8, 8]} className="ctra-impactTracking-timelineItem-header">
          <Col flex={1} className="ctra-impactTracking-timelineItem-title">
            <Text size="md" strong>
              {title}
            </Text>
          </Col>
          {extra && <Col>{extra}</Col>}
        </Row>
        <Row gutter={[0, 0]} className={classname("ctra-impactTracking-timelineItem-content")}>
          {_.trim(description) && (
            <Col span={24} className="ctra-impactTracking-timelineItem-description">
              <Text size="md">
                <Markdown>{description}</Markdown>
              </Text>
            </Col>
          )}
          <Space direction="horizontal">
            <EventOriginIcon type={eventOrigin} />
            <Col span={24} className={classname("ctra-impactTracking-timelineItem-date", styles.Date)}>
              <Text>{date}</Text>
            </Col>
          </Space>
        </Row>
      </div>
    </Timeline.Item>
  );
};

export default TimelineItem;
