import { createModule, from, makeAsset } from "../../utils";

export const chart = createModule("chart", {
  title: makeAsset,
  subtitle: makeAsset,
  description: makeAsset,
  ...from("loading", "beta", "compare"),
  error: from("title", "description"),
  footer: from("dataQuality"),
  empty: from("description"),
  timePeriod: (copyKey, iso8601Duration) => `${copyKey}-${iso8601Duration}`,
  unit: {
    custom: makeAsset,
    default: "default"
  },
  view: (copyKey, view) => `${copyKey}-${view}`,
  seriesTitle: makeAsset,
  axisLabel: from("x"),
  filters: {
    eventToggle: "eventToggle",
    dimGroup: {
      label: "label",
      single: "single",
      all: "all",
      placeholder: "placeholder"
    },
    herdGroup: {
      label: "label",
      single: "single",
      all: "all",
      placeholder: "placeholder"
    },
    pen: {
      label: "label",
      single: "single",
      all: "all",
      placeholder: "placeholder"
    }
  },
  series: makeAsset,
  tooltipTitle: "tooltipTitle",
  bar: {
    axisTitle: from("x")
  },
  line: {
    axisTitle: from("x"),
    tooltip: {
      annotations: from("title")
    }
  },
  heatmap: {
    seriesTitle: "seriesTitle"
  },
  table: {
    columns: {
      header: makeAsset
    }
  }
});

export const graph = createModule("graph", {
  axisLabel: {
    x: makeAsset,
    y: makeAsset
  }
});
