/* eslint-disable react-hooks/exhaustive-deps */

import { FC, createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import * as _ from "lodash";

import {
  ChartFilterDefinition,
  ChartFilters,
  ChartFilterType,
  ChartList,
  Charts,
  Enterprise,
  EnterpriseAppState,
  ExtendedSectionEntity,
  FarmEntity,
  Layouts,
  SectionEntity,
  SectionFilter
} from "@ctra/api";

import { Debug, Nullable, Optional } from "@ctra/utils";

import { useChartFilters } from "@chart";
import { useFarm } from "@farms";
import { useDataDictionary } from "@base";

type ProviderProps = {
  sectionID?: SectionEntity["id"];
  rootSectionID?: SectionEntity["id"];
};

type ContextType = {
  section: Nullable<SectionEntity>;
  rootSection: Nullable<SectionEntity>;
};

/**
 * Default Section Context
 */
const DefaultContext = createContext<ContextType>({
  section: null,
  rootSection: null
});

/**
 * Section context provider, setting metadata used to initialize filter context values
 * @param {string | undefined} sectionID
 * @param {string | undefined} rootSectionID
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 */
const _SectionProvider: FC<ProviderProps> = ({ sectionID, rootSectionID, children }) => {
  const dispatch = useDispatch();
  const { dataDescriptors: dataDictionary } = useDataDictionary();
  const { farm } = useFarm();

  const {
    metadata,
    filters,
    isoDuration,
    timePeriod,
    api: { setISODuration, setTimePeriod, setMetadata, setFilter }
  } = useChartFilters();

  /**
   * Find the section in the store
   */
  const section = useSelector<EnterpriseAppState, Optional<ExtendedSectionEntity>>((state) => {
    const list = Enterprise.entities.getSectionsByID(state, {
      sectionIDs: [sectionID as SectionEntity["id"]],
      extended: true
    });

    return _.find(list, ["id", sectionID]);
  });

  if (_.isUndefined(section)) {
    throw new Error(`Section ${sectionID} not found.`);
  }

  /**
   * Find the root section in the store
   * @type {<<ExtendedSectionEntity>>}
   */
  const rootSection = useSelector<EnterpriseAppState, Optional<Nullable<ExtendedSectionEntity>>>((state) => {
    if (rootSectionID) {
      const list = Enterprise.entities.getSectionsByID(state, {
        sectionIDs: [rootSectionID as SectionEntity["id"]],
        extended: true
      });

      return _.find(list, ["id", rootSectionID]);
    } else {
      return null;
    }
  });

  /**
   * Pick the chart containers from the section
   * @type {ChartContainerEntity[]}
   */
  const chartContainers = _.map(Layouts.utils.mapChartContainers(section), (child) => child);

  /**
   * Try to pick the filters already stored for the given section
   * @type {Optional<SectionFilterList[keyof SectionFilterList]>}
   */
  const filtersInStore = useSelector<EnterpriseAppState, Optional<SectionFilter>>((state) =>
    Enterprise.entities.getSectionFilters(state, { sectionID, farmID: farm?.id })
  );

  /**
   * Get the charts
   * @type {ChartList}
   */
  /**
   * Get all the farm ids which are associated with the app
   */
  const farmIDList = useSelector<EnterpriseAppState, Array<FarmEntity["id"]>>((state) =>
    _.map(Enterprise.entities.getFarmList(state), "id")
  );

  /**
   * Pick the data descriptor ids
   * @type {string[]}
   */
  const dataDescriptorIDs = _.map(chartContainers, "dataDescriptorID");

  /**
   * Pick the data descriptors
   * @type {Pick<DataDescriptorList, keyof DataDescriptorList>}
   */
  const dataDescriptors = _.pick(dataDictionary, dataDescriptorIDs);

  /**
   * Pick the chart ids
   * @type {string[]}
   */
  const chartIDs = _.flatMap(dataDescriptors, "supportedCharts");

  const charts = useSelector<EnterpriseAppState, ChartList>((state) => {
    return Enterprise.entities.getChart(state, { id: chartIDs }) as ChartList;
  });

  /**
   * Get all charts filters
   */
  const chartFilters = _.map(charts, (item) => item.filters);

  /**
   * Find the intersection for all chart filters
   */
  const filtersIntersection = _.intersectionWith<ChartFilterDefinition>(
    ...(chartFilters as Array<Array<ChartFilterDefinition>>),
    _.isEqual
  );

  const offset = _.first(_.map(charts, (item) => item.dataProperties.offset));

  /**
   * Show the filter only if the section has chart containers as children
   */
  const showFilters = section.children.some(Layouts.utils.isChartContainer);

  /**
   * Return true if one of the filter is backend aggregated
   */
  const isBackendAggregated = _.some(
    chartFilters as Array<Array<ChartFilterDefinition>>,
    (filters: Array<ChartFilterDefinition>) => {
      return _.some(filters, (filter) => {
        return filter?.isBackendAggregated;
      });
    }
  );

  /**
   * Make a state for telling the below side effect if store updates are allowed.
   * Otherwise it will make an unnecessary round with the default values.
   * This is not an ideal situation, but the sections are children of the chart filter context
   * and that context initialises with default values triggering the useDeepCompareEffect.
   */
  const [storeUpdatesUnlocked, setStoreUpdatesUnlocked] = useState<boolean>(false);

  /**
   * Make a list of farm ids which the charts support
   * @type {number[]}
   */
  const supportedFarmIDs = _.intersection(
    farm ? [farm.id] : farmIDList,
    _.uniq(_.flatMap(dataDescriptors, "supportedFarms"))
  );

  /**
   * Set the filter context based on the current section config
   * @note at the moment of this commit this one runs later than the setMetadata
   *   calls in the chart api context, so anything set by the individual chart will not apply
   *   to the section itself
   */
  useEffect(() => {
    const { isoDuration: storeIsoDuration, timePeriod, ...filters } = _.defaultTo(filtersInStore, {});

    const isoDuration =
      _.isNull(storeIsoDuration) || storeIsoDuration ? storeIsoDuration : section.defaultDisplayInterval;

    const updatedMetadata = {
      ...metadata,
      supportedfarmIDs: supportedFarmIDs,
      showFilters,
      offset,
      supportedISODurations: Charts.utils.supportedDurations,
      filterDefinitions: filtersIntersection,
      flags: { isBackendAggregated }
    };

    Debug.analytics.info(
      `Setting up ${timePeriod ? "time period" : "ISO duration"}, filters and metadata for section`,
      `"${_.startCase(section.title)}" (${sectionID})`,
      isoDuration,
      timePeriod,
      updatedMetadata
    );

    _.forEach(filters, (value, filterName) => {
      if (Object.values(ChartFilterType).includes(filterName as unknown as ChartFilterType)) {
        setFilter(filterName as keyof ChartFilters, value);
      }
    });

    if (timePeriod) {
      setTimePeriod(timePeriod);
    }

    if (isoDuration) {
      setISODuration(isoDuration);
    }

    setMetadata(updatedMetadata);
    setStoreUpdatesUnlocked(true);
  }, [section.id, farm?.id]);

  /**
   * Update the store with the new filter values if any is set
   */
  useDeepCompareEffect(
    () => {
      if (storeUpdatesUnlocked) {
        const updated = { ...filters, isoDuration, timePeriod };

        if (!_.isEqual(updated, filtersInStore)) {
          Debug.analytics.info(
            "Updating filters for section",
            `"${_.startCase(section.title)}" (${sectionID})`,
            "from",
            filtersInStore,
            "to",
            updated
          );

          dispatch(
            Layouts.actions.setSectionFilters(section.id, farm?.id, {
              ...filters,
              isoDuration,
              timePeriod
            })
          );
        }
      }
    },
    // leave the iso duration out as the time period is already a side effect of that
    [filters, timePeriod]
  );

  return (
    <DefaultContext.Provider
      value={{
        section,
        rootSection
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const SectionContext = {
  Provider: _SectionProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook for Section context
 * @returns
 */
export const useSection = (): ContextType => useContext<ContextType>(DefaultContext);
