import { useState } from "react";
import * as _ from "lodash";
import { ColumnConfig, Datum } from "@ant-design/charts";
import { useDeepCompareEffect } from "use-deep-compare";

import { BarData, ChartType, ColumnData } from "@ctra/api";
import { hslToHex } from "@ctra/utils";

import { useChartContext, useSeriesColor } from "../../../providers";
import { useCommonConfig } from "../../../hooks";
import { filterColumnBySeries, getStackedMax } from "../../../utils";

/**
 * Hook that merges column config with the common config
 * The common config coming from the main app overrides the preset config
 * @returns
 */
export const useColumnConfig = (): { config: ColumnConfig } => {
  const { getColor } = useSeriesColor();
  const { commonConfig } = useCommonConfig();

  const { series, data = [], meta, config: contextConfig } = useChartContext<ColumnData>();
  const [filteredData, setFilteredData] = useState(data);
  const { type: chartType, seriesType, axis, series: metaSeries } = _.defaultTo(meta, {});

  /**
   * Get the series keys from the metadata
   */
  const seriesKeys = _.get(meta, ["series", "keys"], {});

  /**
   * Count the number of items in the legend
   * @type {number}
   */
  const legendSize = _.size(series) || _.size(seriesKeys);

  useDeepCompareEffect(() => {
    setFilteredData(filterColumnBySeries(data, meta, series));
  }, [data, meta, series]);

  const isStack =
    chartType === ChartType.stackedColumn ||
    chartType === ChartType.stackedPercentColumn ||
    chartType === ChartType.basicColumn;

  /**
   * Column chart config
   * @type {{yAxis: {min: number | undefined, max: number | undefined, line: {style: {stroke: string, lineWidth: number}}, label: {formatter: (value) => number}, tickLine: {length: number, style: {stroke: string, lineWidth: number}}}, xAxis: {line: {style: {stroke: string, lineWidth: number}}, label: {formatter: (value) => string | undefined, offset: number}, nice: boolean, tickLine: {length: number, style: {stroke: string, lineWidth: number}}}, xField: string, color: ({seriesField}: {seriesField: any}) => string, data: any[], yField: string, tooltip: {shared: boolean, customContent: (_title, content) => HTMLElement, showTitle: boolean}, seriesField: string, intervalPadding: number, isStack: boolean}}
   */
  const config: ColumnConfig = {
    color: ({ seriesField }) => hslToHex(getColor(seriesType as string, seriesField)),
    /**
     * turn off the legends if there is only one series
     */
    legend: legendSize > 1 ? commonConfig.legend : false,
    data: filteredData,
    intervalPadding: 5,
    isStack,
    seriesField: "seriesField",
    tooltip: {
      showTitle: false,
      formatter: (datum: Datum) => ({
        name: _.get(metaSeries, ["keys", datum.seriesField], datum.seriesField),
        value: datum.y
      })
    },
    xAxis: {
      label: {
        formatter: (value) => {
          return axis?.x.labels?.short[value];
        },
        offset: 10,
        autoRotate: true,
        autoHide: false,
        autoEllipsis: true
      },
      line: {
        style: {
          lineWidth: 0.5,
          stroke: "#000000"
        }
      },
      nice: true,
      tickLine: {
        length: 5,
        style: {
          lineWidth: 1,
          stroke: "#000000"
        }
      }
    },
    yAxis: {
      label: {
        formatter: (value) => {
          return _.round(parseFloat(value), 2);
        }
      },
      line: {
        style: {
          lineWidth: 0.5,
          stroke: "#000000"
        }
      },
      max: getStackedMax(filteredData, "x", "y"),
      min: 0,
      tickLine: {
        style: {
          lineWidth: 1,
          stroke: "#000000"
        },
        length: 5
      }
    },
    xField: "x",
    yField: "y"
  };

  return { config: _.merge({}, contextConfig, commonConfig, config) };
};
