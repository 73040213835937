import { FC } from "react";
import * as _ from "lodash";

import {
  CloseOutlined,
  Button,
  Col,
  DeleteOutlined,
  Popconfirm,
  Row,
  Table,
  TableProps,
  Typography,
  Popover,
  CheckOutlined,
  Tag
} from "@ctra/components";

import { ReferralEntity, ReferralStatus, ReferralType } from "@ctra/api";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";

import { useInvitation, useUserPreferences } from "@base";
import { useCurrentUser } from "@auth";

const { Text } = Typography;
const { Column } = Table;

const {
  ui: { na, self },
  network: {
    referrals: { actions: referralActions }
  },
  settings: {
    users: {
      actions: { confirmDelete },
      form: { role: roleCopy },
      table: { values: tableValues, columns: columnHeaderCopy }
    }
  }
} = Content;

/**
 * Table view of referrals
 * @param {React.PropsWithChildren<TableProps<ReferralEntity | UserEntity>>} props
 * @returns {JSX.Element}
 * @constructor
 */
export const UserReferralTable: FC<TableProps<ReferralEntity>> = (props) => {
  const { t } = useTranslation();
  const { user: currentUser } = useCurrentUser();
  const isFarmer = !!currentUser.farmAccess?.farmId;

  const {
    preferences: { sandbox }
  } = useUserPreferences();

  const {
    api: { handleDelete, handleAccept, handleDecline },
    meta: { isLoading }
  } = useInvitation();

  const getPointerName = (entity: ReferralEntity) => {
    return isFarmer && entity.type === ReferralType.farmerToUser ? "targetUser" : "sourceUser";
  };

  return (
    <Table<ReferralEntity> {...props}>
      <Column<ReferralEntity>
        title={t<string>(columnHeaderCopy.name)}
        key="name"
        sorter={(a, b) => {
          return _.defaultTo(a[getPointerName(a)].firstName, "").localeCompare(
            _.defaultTo(b[getPointerName(b)].firstName, "")
          );
        }}
        render={(value, entity) => {
          const user = entity[getPointerName(entity)];

          return (
            <>
              {_.join(_.compact([user.firstName, user.lastName]), " ") || "-"}{" "}
              {user.email === currentUser.email ? <Tag>{t<string>(self)}</Tag> : null}
            </>
          );
        }}
      />
      <Column<ReferralEntity>
        title={t<string>(columnHeaderCopy.email)}
        key="email"
        sorter={(a, b) =>
          _.defaultTo(a[getPointerName(a)].email, "").localeCompare(
            _.defaultTo(b[getPointerName(b)].email, "")
          )
        }
        render={(value, entity) => {
          const user = entity[getPointerName(entity)];

          return _.trim(user.email);
        }}
      />
      <Column<ReferralEntity>
        title={t<string>(columnHeaderCopy.org)}
        key="org"
        sorter={(a, b) => _.defaultTo(a.targetUser.org, "").localeCompare(_.defaultTo(b.targetUser.org, ""))}
        render={(value, entity) => _.trim(entity.targetUser.org) || t<string>(na)}
      />
      <Column<ReferralEntity>
        title={t<string>(columnHeaderCopy.role)}
        key="role"
        render={(value, entity) => t<string>(roleCopy(entity.targetUser.role))}
        sorter={(a, b) => a.targetUser.role.localeCompare(b.targetUser.role)}
      />
      <Column<ReferralEntity>
        title={t<string>(columnHeaderCopy.invitation)}
        dataIndex="status"
        key="status"
        render={(value, { id, type, code, targetUser: { email } }) => {
          const isFarmer = !!currentUser.farmAccess?.farmId;
          const received = email === currentUser.email || (isFarmer && type === ReferralType.userToFarmer);

          return (
            <Row justify="space-between">
              <Col>
                <Text>{t<string>(tableValues.status(value), { received })}</Text>
              </Col>
              {received ? (
                <Col>
                  <Popover content={t<string>(referralActions.accept, { type })}>
                    <Button
                      disabled={!!sandbox.isEnabled || isLoading}
                      type="link"
                      icon={<CheckOutlined />}
                      // GA log in context
                      onClick={_.partial(handleAccept, code)}
                    />
                  </Popover>
                  {/*<Popover content={t<string>(referralActions.decline, { type })}>*/}
                  {/*  <Button*/}
                  {/*    disabled={!!sandbox.isEnabled || isLoading}*/}
                  {/*    type="link"*/}
                  {/*    icon={<CloseOutlined />}*/}
                  {/*    onClick={_.partial(handleDecline, code)}*/}
                  {/*  />*/}
                  {/*</Popover>*/}
                </Col>
              ) : (
                <Col>
                  {/*<Popover content={t<string>(referralActions.revoke, { type })}>*/}
                  {/*  <Popconfirm*/}
                  {/*    placement="left"*/}
                  {/*    title={t<string>(confirmDelete.text)}*/}
                  {/*    okButtonProps={{ loading: isLoading }}*/}
                  {/*    onConfirm={() => {*/}
                  {/*      // GA log in context*/}
                  {/*      handleDelete(id);*/}
                  {/*    }}*/}
                  {/*    okText={t<string>(confirmDelete.ok)}*/}
                  {/*    cancelText={t<string>(confirmDelete.cancel)}*/}
                  {/*  >*/}
                  {/*    <Button*/}
                  {/*      disabled={!!sandbox.isEnabled || isLoading}*/}
                  {/*      type="link"*/}
                  {/*      icon={<DeleteOutlined />}*/}
                  {/*    />*/}
                  {/*  </Popconfirm>*/}
                  {/*</Popover>*/}
                </Col>
              )}
            </Row>
          );
        }}
      />
    </Table>
  );
};
