import { memoize } from "@ctra/utils";
import { Ajinomoto } from "@ctra/components";

import { GenericInsightBase } from "./Base";
import { AjinomotoHighRiskCowInsightBody } from "../insight-body";
import { AjinomotoHighRiskCowInsightValidation } from "../insight-validation";

/**
 * High risk cow insight
 */
class AjinomotoHighRiskCowInsight extends GenericInsightBase {
  @memoize
  getComponents(): ReturnType<typeof GenericInsightBase.prototype.getComponents> {
    return {
      ...super.getComponents(),
      Icon: Ajinomoto,
      ListIcon: Ajinomoto,
      Body: AjinomotoHighRiskCowInsightBody,
      Validation: AjinomotoHighRiskCowInsightValidation
    };
  }
}

export { AjinomotoHighRiskCowInsight };
