import { LelyNoMilkDropDetail, LelyNoMilkDropList } from "@ctra/components";
import { memoize } from "@ctra/utils";
import { LelyNoMilkDropInsightValidation, LelyMilkDropInsightBody } from "@insights";

import { LelyInsightBase } from "./LelyInsightBase";

/**
 * Lely no milk drop insight
 */
class LelyNoMilkDropInsight extends LelyInsightBase {
  /**
   * Set it's own icons, validation and body
   */
  @memoize
  getComponents(): ReturnType<typeof LelyInsightBase.prototype.getComponents> {
    return {
      Icon: LelyNoMilkDropDetail,
      ListIcon: LelyNoMilkDropList,
      Validation: LelyNoMilkDropInsightValidation,
      Body: LelyMilkDropInsightBody
    };
  }
}

export { LelyNoMilkDropInsight };
