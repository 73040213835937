import { memoize } from "@ctra/utils";
import { Ajinomoto } from "@ctra/components";

import {
  AjinomotoNutritionRecommendationInsightValidation,
  AjinomotoNutritionRecommendationInsightBody
} from "@insights";

import { GenericInsightBase } from "./Base";

/**
 * Nutrition recommendation insight validation
 */
class AjinomotoNutritionRecommendationInsight extends GenericInsightBase {
  @memoize
  getComponents(): ReturnType<typeof GenericInsightBase.prototype.getComponents> {
    return {
      ...super.getComponents(),
      Icon: Ajinomoto,
      ListIcon: Ajinomoto,
      Body: AjinomotoNutritionRecommendationInsightBody,
      Validation: AjinomotoNutritionRecommendationInsightValidation
    };
  }
}

export { AjinomotoNutritionRecommendationInsight };
