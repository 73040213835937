import * as _ from "lodash";
import { NormalizedSchema, schema } from "normalizr";
import { ChartContainerEntity, SectionEntity, LayoutList, LayoutType } from "./typings";

/**
 * Chart container
 */
const chartContainer = new schema.Entity(
  "chartContainer",
  {},
  {
    processStrategy: ({ type, ...entity }) => ({
      schema: _.camelCase(type),
      ...entity
    })
  }
);

/**
 * Layout section with other sections or chart containers
 */
const section = new schema.Entity(
  "section",
  {},
  {
    processStrategy: ({ flags, type, title, ...entity }) => ({
      flags: flags || {},
      slug: _.kebabCase(title),
      title: title,
      schema: _.camelCase(type),
      ...entity
    })
  }
);

/**
 * Recursive children schema which
 * allows mixing sections with chart containers
 */
const recursiveStructure = {
  children: new schema.Array(
    {
      chartContainer,
      section
    },
    ({ type }) => _.camelCase(type)
  )
};

/**
 * Add definition separately so the recursive definition
 * does not cause a "variable used before declaration" error.
 */
section.define(recursiveStructure);

/**
 * Layout with sections or components
 * in a recursive structure
 */
const layout = new schema.Entity("layout", recursiveStructure, {
  processStrategy: ({ layoutType, isDefault, farmIDSubscriptions, userGroupIDSubscriptions, ...entity }) => ({
    flags: {
      isDashboard: _.camelCase(layoutType) === LayoutType.dashboard,
      isDefault
    },
    subscriptions: {
      farm: farmIDSubscriptions,
      userGroup: userGroupIDSubscriptions
    },
    ...entity
  })
});

export type NormalizedLayout = NormalizedSchema<
  {
    chartContainer: Record<ChartContainerEntity["id"], ChartContainerEntity>;
    section: Record<SectionEntity["id"], SectionEntity>;
    layout: LayoutList;
  },
  string | number
>;

export default {
  chartContainer,
  section,
  layout
};
