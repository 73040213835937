import { GA } from "..";

/**
 * Util function for Google Analytics event tracking
 * @param category
 * @param action
 * @param info
 * @returns
 */
export const GAEvent = (category: string, action: string, info?: string) => {
  return GA.gtag("event", action, {
    category,
    action,
    info
  });
};
