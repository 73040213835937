import { LelyKetosisDetail, LelyKetosisList } from "@ctra/components";
import { memoize } from "@ctra/utils";
import { RegularInsightValidation, LelyInsightBaseBody } from "@insights";

import { LelyInsightBase } from "./LelyInsightBase";

/**
 * Lely ketosis insight
 */
class LelyKetosisInsight extends LelyInsightBase {
  /**
   * Set it's own icons, validation and body
   */
  @memoize
  getComponents(): ReturnType<typeof LelyInsightBase.prototype.getComponents> {
    return {
      Icon: LelyKetosisDetail,
      ListIcon: LelyKetosisList,
      Validation: RegularInsightValidation,
      Body: LelyInsightBaseBody
    };
  }
}

export { LelyKetosisInsight };
