import { FC } from "react";

import { Row, Col } from "@ctra/components";
import { classname } from "@ctra/utils";

import { LelyMilkDropChart, LelyInsightBaseBody } from "@insights";

import baseStyles from "../InsightBody/InsightBody.module.less";

/**
 * Insight body for Lely Milk insights
 * @constructor
 */
export const LelyMilkDropInsightBody: FC = () => {
  return (
    <LelyInsightBaseBody>
      <Row className={classname(baseStyles.Row, baseStyles.PaddedChart)}>
        <Col flex={1}>
          <LelyMilkDropChart />
        </Col>
      </Row>
    </LelyInsightBaseBody>
  );
};
