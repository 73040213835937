import * as _ from "lodash";
import * as Sentry from "@sentry/react";

import {
  ChartContainerEntity,
  ChartContainerReference,
  ExtendedLayoutEntity,
  ExtendedSectionEntity,
  LayoutType,
  SectionEntity,
  SectionReference
} from "./typings";
import { DataDescriptorEntity } from "../data-dictionary/typings";
import { DataDescriptorList, FarmEntity } from "..";

import { Optional } from "@ctra/utils";

/**
 * Tell if the given entity is a chart container
 * @param entity
 */
export const isChartContainer = (
  entity: SectionReference | ChartContainerReference
): entity is ChartContainerReference => !_.isEmpty(entity) && entity.schema === LayoutType.chartContainer;

/**
 * Tell if the given entity is a section
 * @param {SectionReference | ChartContainerReference} entity
 * @return {entity is SectionReference}
 */
export const isSection = (entity: SectionReference | ChartContainerReference): entity is SectionReference =>
  !_.isEmpty(entity) && entity.schema === LayoutType.section;

type ResultType = Record<ExtendedSectionEntity["id"], Array<DataDescriptorEntity["id"]>>;

/**
 * Recursively reduce layout to get all the data descriptors present in all its sub-sections
 * @param layout
 * @param _result final results
 * @param _pointer keep track of where we are in the current structure
 * @returns map of section id to array of data descriptors id's
 */
const reduceLayout = (
  layout: ExtendedLayoutEntity | ExtendedSectionEntity,
  _result: ResultType = {},
  _pointer: Array<SectionEntity["id"]> = []
): ResultType => {
  if (!layout.children) return {};
  const { children, id } = layout;

  _pointer.push(id);

  /**
   * Create an entry in the results if we don't have one already
   */
  /* istanbul ignore next */ if (!_result[id]) {
    _result[id] = [];
  }

  _.forEach(children, (entity) => {
    if (entity) {
      if (isChartContainer(entity)) {
        const { dataDescriptorID } = entity;

        _.forEach(_pointer, (id) => _result[id].push(dataDescriptorID));
      } else {
        reduceLayout(entity, _result, _pointer);
      }
    } else {
      Sentry.captureException("One or more child of this layout/container seems to be undefined.", {
        tags: {
          diagnostics: "layoutError"
        },
        extra: {
          layout
        }
      });
    }
  });

  _pointer.pop();

  return _result;
};

/**
 * export a function that gets a single argument for layout, returns a map of section id to array of
 * all data descriptor ids found in all the chart containers of that layout
 */
export const getDataDescriptiorsOfLayout = _.unary(reduceLayout);

/**
 * Filter the data descriptors (of each section) which support the given farm
 * @param {DataDescriptorList} dataDictionary
 * @param {ReturnType<typeof reduceLayout>} sections
 * @param {number | Array<FarmEntity["id"]>} farmIDs
 * @param {boolean | undefined} invert
 * @return {ReturnType<typeof reduceLayout>}
 */
export const getSupportedDataDescriptorsOfLayout = (
  dataDictionary: DataDescriptorList,
  sections: ReturnType<typeof reduceLayout>,
  farmIDs: FarmEntity["id"] | Array<FarmEntity["id"]>,
  { invert }: { invert: Optional<boolean> } = { invert: false }
): ReturnType<typeof reduceLayout> =>
  _.mapValues(sections, (dataDescriptorIDList) =>
    _.filter(dataDescriptorIDList, (dataDescriptorID) => {
      const supportedFarms = dataDictionary[dataDescriptorID]?.supportedFarms;

      /**
       * If a farm list is passed, check if an intersection exists
       */
      if (_.isArray(farmIDs)) {
        return invert
          ? !_.intersection(supportedFarms, farmIDs).length
          : !!_.intersection(supportedFarms, farmIDs).length;
      }

      return invert ? !_.includes(supportedFarms, farmIDs) : _.includes(supportedFarms, farmIDs);
    })
  );

/**
 * Make a map function which picks the chart container entities recursively
 * @param children
 */
export const mapChartContainers = ({ children }: ExtendedSectionEntity): Array<ChartContainerEntity> =>
  _.flatMapDeep(children, (child) =>
    child.schema === LayoutType.chartContainer ? child : mapChartContainers(child)
  );
