import * as _ from "lodash";
import { NormalizedSchema, schema } from "normalizr";

import { ScorecardValueEntity, ScorecardValueSource } from "./typings";

const scorecardValue = new schema.Entity(
  "scorecardValue",
  {},
  {
    idAttribute: "id",
    /**
     * Convert the response object to a existing format.
     */
    processStrategy: (response: ScorecardValueSource): ScorecardValueEntity => {
      const {
        data,
        meta: { scoringMethod, title }
      } = response;

      //@ts-ignore
      const [key, value] = _.first(_.entries(data));

      //@ts-ignore - there is no id in the response to add to the return object
      return {
        farmID: key,
        fieldName: title,
        value: value.value,
        scoringMethod
      };
    }
  }
);

export type NormalizedScorecardValue = NormalizedSchema<
  {
    scorecardValue: Record<ScorecardValueEntity["id"], ScorecardValueEntity>;
  },
  string | number
>;

export default {
  scorecardValue
};
